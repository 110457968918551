import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "../slices/authSlice"
import  accessManagementSlice  from "../slices/AccessManagementSlice";
import  roleSlice  from "../slices/roleSlice";
const rootReducer=combineReducers({
    auth:authSlice,
    access:accessManagementSlice,
    role: roleSlice,
})

export default rootReducer
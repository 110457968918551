import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { HiX, HiDotsVertical } from "react-icons/hi";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import EditAssetModal from "../Modal/asset/EditAssetsModal";

const AssetsDataTable = ({ assetsData }) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [userId, setUserId] = useState("");

  const handleclose = () => {
    setOpenEditModal(!openEditModal);
  };
  const handleclick = (id) => {
    setUserId(id);
    setOpenEditModal(true);
  };
  // Actions Dropdown Menu
  const actionColumn = {
    name: "Actions",
    cell: (row) => (
      <Menu as="div" className="relative inline-block">
        <Menu.Button>
          <HiDotsVertical className="w-5 h-5 text-gray-500 cursor-pointer" />
        </Menu.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items className="absolute top-[-1vw] right-4 w-36 bg-white shadow-md rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-700"
                  } group flex items-center w-full px-4 py-2 text-sm`}
                  onClick={() => {
                    handleclick(row.user_id);
                  }}
                >
                  Unassign
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  };

  // Columns configuration
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "5.21vw", // Converted from 100px
      center: true,
    },
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "5.21vw", // Converted from 100px
    },
    { name: "Asset", selector: (row) => row.item_name, sortable: true },
    { name: "Employee", selector: (row) => row.user_name, sortable: true },
    { name: "Quantity", selector: (row) => row.assets_qty, sortable: true },
    { name: "Date", selector: (row) => row.date, sortable: true },
    actionColumn, // Actions column
  ];

  // Filter data based on input
  const filteredData = assetsData.filter(
    (item) =>
      item.item_name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.user_name.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText("");
  };

  // Sub-header search bar
  const subHeaderComponent = (
    <div className="flex justify-between items-center bg-white py-2 px-4">
      <div className="relative">
        <input
          className="border p-2 pr-8 bg-white rounded-md focus:outline-none"
          type="text"
          placeholder="Search by Asset/Employee"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        {filterText && (
          <HiX
            className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer h-5 w-5 text-gray-500"
            onClick={handleClear}
          />
        )}
      </div>
    </div>
  );

  return (
    <div>
      <div className="font-poppins">
        <div className="overflow-hidden bg-white rounded-md shadow-lg">
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
            persistTableHead
            customStyles={{
              rows: {
                style: {
                  minHeight: "50px",
                  fontSize: "0.9rem",
                  "&:hover": { backgroundColor: "#f9f9f9" },
                },
              },
              headCells: {
                style: {
                  fontWeight: 600,
                  textAlign: "center",
                  fontSize: "1rem",
                  color: "#10131e",
                },
              },
            }}
          />
        </div>
      </div>
      <EditAssetModal
        open={openEditModal}
        handleClose={handleclose}
        userId={userId}
      />
    </div>
  );
};

export default AssetsDataTable;

import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
});

// PDF Component
const EmployeeReportPDF = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Employee Report</Text>
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Employee ID</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Employee Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Employee Type</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Start Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>End Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Total Working Days</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Total Overtime Hours</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Day Salary</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Overtime Salary</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Total Salary</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Total Current Balance</Text>
            </View>
          </View>

          {/* Table Rows */}
          {data.map((row, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{row.employee_id}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{row.employee_name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{row.employee_type}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{row.date_from}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{row.date_to}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{row.total_working_days}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{row.total_overtime_hours}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{row.day_salary}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{row.overtime_salary}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{row.total_salary}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{row.total_current_balance}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default EmployeeReportPDF;
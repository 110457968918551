import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import Loader from "../../components/loader/Loading";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getReq } from "../../apis/api";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv"; // Import CSVLink for exporting data
import Purchaseperformancetable from "../../components/DataTable/Purchaseperformancetable";
import { useSelector } from "react-redux";

const Purchaseperformance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const { permissions } = useOutletContext();
  const { roleName } = useSelector((state) => state.role);
  const reportingpermission =
    permissions?.[roleName]?.modules?.["Reporting"]?.pages?.["Reporting"]
      ?.sections?.["Purchase Performance"];

  const fetchProductReport = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/purchasePerformanceReport");

      if (response.status === 200) {
        setOrders(response.data);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in Fetching Product report");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProductReport();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "Item Name",
      selector: (row) => row.item_name,
      sortable: true,
    },
    {
      name: "Item Code",
      selector: (row) => row.item_code || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Item Category",
      selector: (row) => row.item_category,
      sortable: true,
    },
    {
      name: "Suppliers",
      selector: (row) => row.suppliers?.join(", ") || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Total Quantity",
      selector: (row) => row.total_quantity || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Total Weight",
      selector: (row) => row.total_weight || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Total Price",
      selector: (row) => row.total_price || "N/A",
      sortable: true,
      wrap: true,
    },
  ];

  // Updated CSV Headers
  const csvHeaders = [
    { label: "#", key: "index" },
    { label: "Item Name", key: "item_name" },
    { label: "Item Code", key: "item_code" },
    { label: "Item Category", key: "item_category" },
    { label: "Suppliers", key: "suppliers" },
    { label: "Total Quantity", key: "total_quantity" },
    { label: "Total Weight", key: "total_weight" },
    { label: "Total Price", key: "total_price" },
  ];

  // Prepare data for CSV export
  const csvData = orders.map((order, index) => ({
    index: index + 1,
    item_name: order.item_name || "N/A",
    item_code: order.item_code || "N/A",
    item_category: order.item_category || "N/A",
    suppliers: order.suppliers?.join(", ") || "N/A",
    total_quantity: order.total_quantity || "N/A",
    total_weight: order.total_weight || "N/A",
    total_price: order.total_price || "N/A",
  }));

  return (
    <div className="w-full h-full">
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs
          title={"Home  /  Dashboard / Reporting / Purchase Performance"}
        />
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename="purchase_performance_report.csv"
          className="text-white bg-blue-500 px-4 py-2 rounded hover:bg-blue-600"
        >
          Export
        </CSVLink>
      </div>
      <main>
        {isLoading ? (
          <Loader />
        ) : reportingpermission?.read ? (
          <Purchaseperformancetable columns={columns} data={orders} />
        ) : (
          <p className="text-gray-500 md:py-20px-vw md:text-[1.2vw] text-center font-bold">
            You do not have permission to view this data.
          </p>
        )}
      </main>
    </div>
  );
};

export default Purchaseperformance;

import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { HiDotsVertical, HiX } from "react-icons/hi";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loading";

const PurchaseOrderTable = ({
  isLoading,
  data,
  onStatusChange,
  updatePurchasepermission,
  onPayment,
  onCancel,
}) => {
  const [filterText, setFilterText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("Purchase Order Number");
  const [globalSearchText, setGlobalSearchText] = useState("");
  const navigate = useNavigate();

  console.log("updatpermission", updatePurchasepermission);
  // Define allowed filter columns
  const allowedFilterColumns = [
    { name: "Purchase Order Number", selector: "purchase_order_number" },
    { name: "Invoice Number", selector: "invoice_number" },
    { name: "Supplier Name", selector: "supplier_name" },
    { name: "Invoice Date", selector: "invoice_date" },
    { name: "EWay Number", selector: "eway_number" },
  ];

  const columns = [
    {
      name: "S_No.",
      selector: (row, index) => index + 1,
      sortable: false, // Typically, S.No. isn't sortable
      width: "90px", // Optional: Adjust the width of the S.No. column
    },
    {
      name: "Purchase Order Number",
      selector: (row) => row.purchase_order_number,
      sortable: true,
    },
    {
      name: "Invoice Number",
      selector: (row) => row.invoice_number,
      sortable: true,
    },
    {
      name: "Supplier Name",
      selector: (row) => row.supplier_name,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoice_date,
      sortable: true,
    },
    { name: "EWay Number", selector: (row) => row.eway_number, sortable: true },
    {
      name: "Logistics",
      selector: (row) => row.logistics_amount,
      sortable: true,
    },
    {
      name: "Carriage",
      selector: (row) => row.carriage_amount,
      sortable: true,
    },
    { name: "Order Date", selector: (row) => row.order_date, sortable: true },
    {
      name: "Expected Delivery Date",
      selector: (row) => row.expected_delivery_date,
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => (row.status === "0" ? "Due" : "Received"),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <Menu as="div" className="relative">
          <Menu.Button>
            <HiDotsVertical className="w-6 h-6 text-gray-500 cursor-pointer" />
          </Menu.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="absolute right-0 mt-[-50px] w-48 bg-white shadow-lg rounded-md">
              {updatePurchasepermission && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-blue-500 text-white" : "text-gray-900"
                      } w-full px-4 py-2`}
                      onClick={() =>
                        navigate(
                          `/purchases/edit-purchase-order/${row.purchase_order_number}`
                        )
                      }
                    >
                      View/Edit
                    </button>
                  )}
                </Menu.Item>
              )}

              {/* <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${active ? "bg-blue-500 text-white" : "text-gray-900"} w-full px-4 py-2`}
                    onClick={() => onStatusChange(row)}
                  >
                    Status Change
                  </button>
                )}
              </Menu.Item> */}
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-blue-500 text-white" : "text-gray-900"
                    } w-full px-4 py-2`}
                    onClick={() => onPayment(row)}
                  >
                    Payment
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-red-500 text-white" : "text-gray-900"
                    } w-full px-4 py-2`}
                    onClick={() => onCancel(row)}
                  >
                    Cancel Order
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // Function to get column value
  const getColumnValue = (row, selector) => {
    return row[selector] || "";
  };

  // Filter data based on the selected column
  const filteredData = data.filter((item) => {
    const column = allowedFilterColumns.find(
      (col) => col.name === selectedColumn
    );
    if (!column) return true;
    const valueToFilter = getColumnValue(item, column.selector)
      .toString()
      .toLowerCase();
    return valueToFilter.includes(filterText.toLowerCase());
  });

  // Global search filter
  const globallyFilteredData = filteredData.filter((item) =>
    Object.values(item).some((value) =>
      value?.toString().toLowerCase().includes(globalSearchText.toLowerCase())
    )
  );

  return (
    <div className="font-poppins">
      <div className="flex flex-wrap justify-end items-center mb-4">
        <div className="relative flex items-center">
          <input
            type="text"
            placeholder="Global Search..."
            value={globalSearchText}
            onChange={(e) => setGlobalSearchText(e.target.value)}
            className="border p-2 rounded-md mr-2"
          />
          {globalSearchText && (
            <HiX
              className="absolute right-3 text-gray-500 cursor-pointer"
              onClick={() => setGlobalSearchText("")}
            />
          )}
        </div>
        <select
          className="border p-2 rounded-md mr-2"
          value={selectedColumn}
          onChange={(e) => {
            setSelectedColumn(e.target.value);
            setFilterText("");
          }}
        >
          {allowedFilterColumns.map((col) => (
            <option key={col.selector} value={col.name}>
              {col.name}
            </option>
          ))}
        </select>
        <div className="relative">
          <input
            type="text"
            placeholder={`Filter by ${selectedColumn}`}
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="border p-2 rounded-md"
          />
          {filterText && (
            <HiX
              className="absolute right-3 text-gray-500 cursor-pointer"
              onClick={() => setFilterText("")}
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <DataTable
          columns={columns}
          data={globallyFilteredData}
          pagination
          customStyles={{
            rows: { style: { minHeight: "60px", fontSize: "14px" } },
            headCells: {
              style: {
                fontWeight: "bold",
                fontSize: "16px",
                textAlign: "center",
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default PurchaseOrderTable;

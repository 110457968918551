import React, { useRef } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const SearchBar = ({
  onSearch,
  searchTerm,
  setSearchTerm,
  dropdownData,
  loading,
}) => {
  const navigate = useNavigate();
  const searchModalRef = useRef(null);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <div className="relative group w-10 transition-all duration-300 ease-in-out md:w-full max-w-sm">
      {/* Search Icon */}
      <div className="flex items-center justify-center bg-white border rounded-full shadow-md cursor-pointer w-[2.3vw] h-[2.3vw]">
        <FaSearch className="text-gray-500 md:text-[1.2vw] text-[6vw]" />
      </div>

      {/* Search Modal (Visible on Hover) */}
      <div
        ref={searchModalRef}
        className="absolute top-[3vh]  flex-col items-center md:w-[15vw] w-[80vw] right-0 z-20 rounded-[1.04vw] shadow-lg mt-1 p-2 bg-white transition-transform duration-300 ease-in-out hidden group-hover:block"
      >
        {/* Search Input */}
        <div className="relative flex-grow mb-2">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleInputChange}
            className="w-full pl-12 pr-4 py-2 text-gray-700 border border-gray-300 focus:outline-none rounded-[1.04vw]"
          />
          <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-4.35-4.35M10.5 17a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
              />
            </svg>
          </span>
        </div>

        {/* Dropdown Results */}
        {loading && <p className="text-gray-500 mt-2">Loading...</p>}
        {dropdownData.length > 0 && (
          <ul className="w-full mt-2 border border-gray-300 rounded-[1.04vw] md:max-h-[20vw] max-h-[10vw] overflow-y-auto scroll-smooth custom-scrollbar bg-white">
            {dropdownData.map((item, index) => (
              <li
                key={index}
                onClick={() => {
                  if (item.unique_id.includes("SGEWU")) {
                    navigate("/users");
                  } else if (item.unique_id.includes("SINV")) {
                    navigate("/sales-service/manage-warranty");
                  } else {
                    navigate("/purchases");
                  }
                }}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              >
                {item.unique_id}
              </li>
            ))}
          </ul>
        )}
        {!loading && dropdownData.length === 0 && searchTerm && (
          <p className="text-gray-500 mt-2">No results found</p>
        )}
      </div>
    </div>
  );
};

export default SearchBar;

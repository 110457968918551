import React from "react";
import "./Loading.css";
const Loader = () => {
  return (
    <div className="loader-container ">
      <div class="loader"></div>
      <p className="loader-text ">Loading...</p>
    </div>
  );
};

export default Loader;

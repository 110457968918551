import React, { useState, useEffect } from "react";
import { getReq, postReq } from "../../../apis/api";
import { toast, ToastContainer } from "react-toastify";
import {
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/system";
import Button from "../../Button";
const CustomBox = styled(Box)(({ theme }) => ({
  fontFamily: "Poppins, sans-serif",
  fontSize: "12px",
  padding: "16px",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0)",
  borderRadius: "2px",
  backgroundColor: "#fff",
}));
// Custom theme with Poppins font
const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 12,
  },
});

export const EditUser = ({ open, handleClose, data, fetchUsers }) => {
  const [formData, setFormData] = useState({
    id: "",
    user_name: "",
    father_name: "",
    phone: "",
    mobile: "",
    email: "",
    address_line_1: "",
    address_line_2: "",
    village: "",
    district: "",
    state: "",
    country: "",
    bank_account_number: "",
    bank_acc_holder_name: "",
    IFSC: "",
    gstin_number: "",
    dealer_price_discount: "",
    employee_monthly_salary: "",
    password: "",
    confirm_password: "",
    role_id: "",
    start_login_time: "",
    end_login_time: "",
    payment_sms_reminder: "",
    status: "",
    role_name: "",
    overtime_per_hour_salary: "",
  });

  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Set initial form data from props
    setFormData(data);
  }, [data]);

  // Fetch roles from the API
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getReq("/fetchrole");
        setRoles(response.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setErrMsg("");

    // Check if passwords match
    if (formData.password !== formData.confirm_password) {
      setErrMsg("Passwords don't match");
      return;
    }

    // Create a copy of formData without confirm_password
    const { confirm_password, ...payload } = formData;

    try {
      setIsLoading(true);
      const response = await postReq(payload, "/updateUser");
      if (response.status === 200) {
        handleClose();
        toast.success("User updated successfully!");
        fetchUsers();
      } // Send the formData without confirm_password
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
      setErrMsg("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        maxWidth="lg"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") handleClose();
        }}
        PaperProps={{
          sx: {
            boxShadow: "none", // Removes the shadow
          },
        }}
      >
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <Typography color="error" variant="body2" gutterBottom>
            {errMsg}
          </Typography>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            disableBackdropClick
            disableEscapeKeyDown
          >
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent>
              <Typography color="error" variant="body2" gutterBottom>
                {errMsg}
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {/* Role Selection */}
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth required>
                      <InputLabel id="role-select-label" shrink>
                        Role
                      </InputLabel>
                      <Select
                        labelId="role-select-label"
                        name="role_id"
                        value={formData.role_id}
                        onChange={handleChange}
                        size="small"
                      >
                        {roles.map((role) => (
                          <MenuItem key={role.id} value={role.id}>
                            {role.role_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* User Info */}
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="User Name"
                      name="user_name"
                      value={formData.user_name}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Father's Name"
                      name="father_name"
                      value={formData.father_name}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  {/* Add other fields similarly as per formData */}
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Mobile Number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      type="email"
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  {/* Address Information */}
                  <Grid item xs={12} sm={6} md={4.5}>
                    <TextField
                      fullWidth
                      label="Address Line 1"
                      name="address_line_1"
                      value={formData.address_line_1}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4.5}>
                    <TextField
                      fullWidth
                      label="Address Line 2"
                      name="address_line_2"
                      value={formData.address_line_2}
                      onChange={handleChange}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  {/* Address Information */}

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Country"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="State"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="District"
                      name="district"
                      value={formData.district}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Village"
                      name="village"
                      value={formData.village}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  {/* Bank Details */}
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      fullWidth
                      label="Bank Account Number"
                      name="bank_account_number"
                      value={formData.bank_account_number}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Account Holder Name"
                      name="bank_acc_holder_name"
                      value={formData.bank_acc_holder_name}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="IFSC"
                      name="IFSC"
                      value={formData.IFSC}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  {(formData.role_id == 7 ||
                    formData.role_id == 8 ||
                    formData.role_id == 9) && (
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        fullWidth
                        label="GSTIN Number"
                        name="gstin_number"
                        value={formData.gstin_number}
                        onChange={handleChange}
                        required
                        size="small"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}

                  {formData.role_id == 8 && (
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        fullWidth
                        label="Dealer Price Discount (0-100)"
                        name="dealer_price_discount"
                        type="number"
                        value={formData.dealer_price_discount}
                        onChange={handleChange}
                        size="small"
                        required
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}

                  {/* Password Fields */}
                  {formData.role_id == 1 && (
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        fullWidth
                        label="Password"
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        size="small"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}
                  {formData.role_id == 1 && (
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        fullWidth
                        label="Confirm Password"
                        name="confirm_password"
                        type="password"
                        value={formData.confirm_password}
                        onChange={handleChange}
                        required
                        size="small"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth required>
                      <InputLabel id="payment-sms-select-label">
                        Payment SMS Reminder
                      </InputLabel>
                      <Select
                        labelId="payment-sms-select-label"
                        name="payment_sms_reminder"
                        value={formData.payment_sms_reminder}
                        onChange={handleChange}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth required>
                      <InputLabel id="employee-type-select-label">
                        Employee Type
                      </InputLabel>
                      <Select
                        labelId="employee-type-select-label"
                        name="employee_type"
                        value={formData.role_name}
                        onChange={handleChange}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem value="Permanent">Permanent</MenuItem>
                        <MenuItem value="Vendor">Vendor</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {formData.employee_type === "Permanent" && (
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        fullWidth
                        label="Employee Monthly Salary"
                        name="employee_monthly_salary"
                        value={formData.employee_monthly_salary}
                        onChange={handleChange}
                        required
                        size="small"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Overtime Per-hour Salary"
                      name="overtime_per_hour_salary"
                      value={formData.overtime_per_hour_salary}
                      onChange={handleChange}
                      required
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth required>
                      <InputLabel id="status-select-label">Status</InputLabel>
                      <Select
                        labelId="status-select-label"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                      >
                        <MenuItem value="Enable">Enable</MenuItem>
                        <MenuItem value="Disable">Disable</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Login Hours */}
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Start Login Time"
                      name="start_login_time"
                      value={formData.start_login_time}
                      onChange={handleChange}
                      required
                      size="small"
                      type="time"
                      InputLabelProps={{
                        shrink: true, // Ensures the label stays above the input when the time is selected
                      }}
                      inputProps={{
                        step: 300, // 5-minute intervals (300 seconds)
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="End Login Time"
                      name="end_login_time"
                      value={formData.end_login_time}
                      onChange={handleChange}
                      required
                      size="small"
                      type="time"
                      InputLabelProps={{
                        shrink: true, // Ensures the label stays above the input when the time is selected
                      }}
                      inputProps={{
                        step: 300, // 5-minute intervals
                      }}
                    />
                  </Grid>
                  {/* Add similar fields for other details like Village, State, Bank Details, etc. */}

                  {/* Submit Button */}
                  <div className="flex w-full items-center justify-end">
                    <DialogActions style={{ justifyContent: "flex-end" }}>
                      <Button
                        func={handleClose}
                        title={"Cancel"}
                        customcls="bg-[#d0412e33]  text-[#D0412E] border border-[#d0412e33]"
                      />

                      <Button
                        type="submit"
                        title={"Save"}
                        isLoading={isLoading}
                      />
                    </DialogActions>
                  </div>
                </Grid>
              </form>
            </DialogContent>
          </Dialog>
        </DialogContent>
      </Dialog>

      <ToastContainer />
    </ThemeProvider>
  );
};

export default EditUser;

import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { HiX, HiDotsVertical } from "react-icons/hi";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import ViewItemModal from "../Modal/additemmodal/ViewItemModal";
import ViewCategoryModal from "../Modal/categorymodal/ViewCategoryModal";
import ViewCategory from "../Modal/productmodal/ViewCategory";
import { toast } from "react-toastify";
import { jsonpostReq, patchReq, postReq } from "../../apis/api";
import ConfirmationModal from "../Modal/confirmationmodal/ConfirmationModal";
import AddStock from "../Modal/productmodal/AddStock";
import Reschedule from "../Modal/ordermodal/Reschedule";
import Invoice from "../Modal/ordermodal/Invoice";
import RescheduleService from "../Modal/ordermodal/RescheduleService";
import ServiceInvoice from "../Modal/ordermodal/ServiceInvoice";

const ServiceTable = ({ columns, data, onItemAdded, updatePemission }) => {
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [opendownloadModal, setDownloadModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [globalSearchText, setGlobalSearchText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const navigate = useNavigate();
  const [selectedColumn, setSelectedColumn] = useState("Claim ID");

  const allowedFilterColumns = [
    { name: "ID", selector: "claim_id" },
    { name: "Service Invoice No", selector: "service_invoice_number" },
    { name: "Date", selector: "date" },
    // { name: "Company", selector: "customer.billing.company" }, // Modify this
    // { name: "Address", selector: "customer.billing.address1" }, // Modify this
    { name: "Status", selector: "status" },
  ];

  const handleStatus = async (row, newStatus) => {
    try {
      setConfirmationModal(null);
      const response = await postReq(
        { status: newStatus },
        `/service/update/status/${row.id}`
      );
      if (response.status === 200) {
        toast.success(`Status changed to ${newStatus} for ${row.claim_id}`);
        setTimeout(() => {
          onItemAdded();
        }, 1000);
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Failed to update status");
    }
  };

  // Declare actionColumn first
  const actionColumn = {
    name: "Actions",
    cell: (row) => (
      <Menu as="div" className="relative">
        <Menu.Button className="sticky top-0">
          <HiDotsVertical className="w-6 h-6 text-gray-500 cursor-pointer" />
        </Menu.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items className="absolute top-[-4vw] right-5 w-48 bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
            {updatePemission && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-blue-500 text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-4 py-2 text-[1vw]`}
                    onClick={() =>
                      navigate(`/sales-service/edit-service/${row.id}`)
                    }
                  >
                    View/Edit
                  </button>
                )}
              </Menu.Item>
            )}

            {/* <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-[1vw]`}
                  onClick={() =>
                    navigate(`/sales-service/create-quotation/${row.id}`)
                  }
                >
                  Create Quotation
                </button>
              )}
            </Menu.Item> */}

            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-[1vw]`}
                  onClick={() => {
                    setSelectedItemId(row.id);
                    setOpenModal(true);
                  }}
                >
                  Service Reschedule
                </button>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => {
                return (
                  <button
                    className={`${
                      active ? "bg-blue-500 text-white" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-4 py-2 text-[1vw]`}
                    onClick={() =>
                      setConfirmationModal({
                        text1: "Are you sure?",
                        text2: `You want to Cancel the Service?`,
                        btn1Text: "Cancel Service",
                        btn2Text: "Cancel",
                        btn1Handler: () => handleStatus(row, "Cancelled"),
                        btn2Handler: () => setConfirmationModal(null),
                      })
                    }
                  >
                    Cancel Service
                  </button>
                );
              }}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-[1vw]`}
                  onClick={() => {
                    setSelectedItemId(row.id);
                    setDownloadModal(true);
                  }}
                >
                  Download Invoice
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    right: true,
    style: {
      position: "sticky",
      right: "0px",
      zIndex: 1,
    },
  };

  // Now include actionColumn inside enhancedColumns
  const enhancedColumns = [...columns, actionColumn];

  // Function to get column value by either string key or selector function
  const getColumnValue = (row, selector) => {
    if (typeof selector === "function") {
      return selector(row);
    }

    // Support nested properties
    return selector.split(".").reduce((acc, key) => (acc ? acc[key] : ""), row);
  };

  // Filter data by the selected column and filter text
  const filteredData = data.filter((item) => {
    const column = allowedFilterColumns.find(
      (col) => col.name === selectedColumn
    );

    if (!column) {
      return true; // No filter if no column is selected
    }

    const valueToFilter = getColumnValue(item, column.selector)
      .toString()
      .toLowerCase();
    return valueToFilter.includes(filterText.toLowerCase());
  });

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // Global filtering logic for the search bar
  const globallyFilteredData = filteredData.filter((item) => {
    const ItemsName = item.claim_id?.toLowerCase() || "";
    return ItemsName.includes(globalSearchText.toLowerCase());
  });

  // Sub-header component with search and filter by column functionality
  const subHeaderComponent = (
    <div className="flex justify-between bg-white text-[#666666] items-center my-8px-vw relative">
      <div className="relative">
        <input
          className="border p-8px-vw pr-40px-vw bg-white rounded-[8px] focus:outline-none placeholder:text-16px-vw mr-16px-vw"
          type="text"
          placeholder="Search Claim ID..."
          value={globalSearchText}
          onChange={(e) => setGlobalSearchText(e.target.value)}
        />

        {globalSearchText && (
          <HiX
            className="absolute right-20px-vw top-1/2 bg-transparent transform -translate-y-1/2 cursor-pointer h-6 w-6 text-gray-500"
            onClick={() => setGlobalSearchText("")}
          />
        )}
      </div>

      <select
        className="border bg-white focus:outline-none p-12px-vw rounded-[8px] text-16px-vw mr-8px-vw"
        value={selectedColumn}
        onChange={(e) => {
          setSelectedColumn(e.target.value);
          setFilterText(""); // Clear filter text when changing column
        }}
      >
        {allowedFilterColumns.map((col) => (
          <option key={col.selector} value={col.name}>
            {col.name}
          </option>
        ))}
      </select>

      <div className="relative w-full">
        <input
          className="border bg-white p-8px-vw pr-40px-vw focus:outline-none rounded-[8px] placeholder:text-16px-vw w-full"
          type="text"
          placeholder={`Filter by ${selectedColumn}`}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />

        {filterText && (
          <HiX
            className="absolute right-2 bg-transparent top-1/2 transform -translate-y-1/2 cursor-pointer h-6 w-6 text-gray-500"
            onClick={handleClear}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="font-poppins">
      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
      <div className="overflow-hidden !bg-white rounded-[0.42vw]">
        <DataTable
          columns={enhancedColumns}
          data={globallyFilteredData}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponent}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
          customStyles={{
            rows: {
              style: {
                minHeight: "60px",
                fontSize: "0.9vw",
              },
            },
            headCells: {
              style: {
                fontWeight: 600,
                textAlign: "center",
                whiteSpace: "normal",
                fontSize: "1vw",
                color: "#10131e",
              },
            },
          }}
        />
      </div>
      {openModal && (
        <RescheduleService
          open={openModal}
          handleClose={() => setOpenModal(false)}
          ItemId={selectedItemId}
          onItemAdded={onItemAdded}
        />
      )}
      {opendownloadModal && (
        <ServiceInvoice
          open={opendownloadModal}
          handleClose={() => setDownloadModal(false)}
          ItemId={selectedItemId}
        />
      )}
    </div>
  );
};

export default ServiceTable;

import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import { getReq } from "../../apis/api";
import Loader from "../../components/loader/Loading";
import AddCategory from "../../components/Modal/productmodal/AddCategory";
import ProductCatTable from "../../components/DataTable/ProductCatTable";

const Category = () => {
  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };



  const fetchProductCategories = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/product-categories");

      if (response.status === 200) { 
        const sortedDataDescending = response.data.sort((a, b) => b.id - a.id);
        setCategories(sortedDataDescending);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in Fetching Product Categories");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProductCategories();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "100px",
    },
   
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },

    // {
    //   name: "Description",
    //   selector: (row) => row.description || "N/A",
    //   sortable: true,
    // }, 
     {
      name: "Created By",
      selector: (row) => row.created_by || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Status",
      selector: (row) => row.status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.status === "Enable"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.status === "Disable"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.status || "N/A"}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Manufacturing / Categories"} />
        <Button title="Add Category" func={handleButtonClick} />
      </div>

      <main>
        {isLoading ? (
          <Loader />
        ) : (
          <ProductCatTable columns={columns} data={categories}   onItemAdded={fetchProductCategories}/>
        )}
      </main>

      {openModal && (
        <AddCategory
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={"Add Category"}
        />
      )}
    </div>
  );
};

export default Category;

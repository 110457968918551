/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Footer = () => { 
  const currentYear = new Date().getFullYear();

  return (
    <footer className="text-[#343E53]">
      <div className="mx-auto px-[3vw]">
        {/* Footer Links */}
        {/* Footer Bottom Border */}
        <div className="border-t border-gray-300 py-[1.5vw] text-[1vw] text-center">
          <p className="leading-tight">
            <span className="text-[1.2vw] text-center font-medium">
              © Copyright {currentYear} Shree Gopal Engineering Works. All
              Rights Reserved.
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

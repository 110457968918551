import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import FormButton from "../../components/Button";
import Loader from "../../components/loader/Loading";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getReq } from "../../apis/api";
import { toast } from "react-toastify";
import Productperformancetable from "../../components/DataTable/Productperformancetable";
import { CSVLink } from "react-csv"; // Import CSVLink for exporting data
import { useSelector } from "react-redux";

const Productperformance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const { permissions } = useOutletContext();
  const { roleName } = useSelector((state) => state.role);
  const reportingpermission =
    permissions?.[roleName]?.modules?.["Reporting"]?.pages?.["Reporting"]
      ?.sections?.["Product Performance"];

  console.log("reportingpermission", reportingpermission);

  const fetchProductReport = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/productPerformanceReport");

      if (response.status === 200) {
        setOrders(response.product_summary);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in Fetching Product report");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProductReport();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "ID",
      selector: (row) => row.product_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Product Name",
      selector: (row) => row.product_name || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Product Sku",
      selector: (row) => row.product_sku,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.total_amount || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Total Order",
      selector: (row) => row.number_of_orders || "N/A",
      sortable: true,
      wrap: true,
    },
  ];

  // CSV Headers
  const csvHeaders = [
    { label: "#", key: "index" },
    { label: "ID", key: "product_id" },
    { label: "Product Name", key: "product_name" },
    { label: "Product Sku", key: "product_sku" },
    { label: "Amount", key: "total_amount" },
    { label: "Total Order", key: "number_of_orders" },
  ];

  // Prepare data for CSV export
  const csvData = orders.map((order, index) => ({
    index: index + 1,
    product_id: order.product_id,
    product_name: order.product_name || "N/A",
    product_sku: order.product_sku,
    total_amount: order.total_amount || "N/A",
    number_of_orders: order.number_of_orders || "N/A",
  }));

  return (
    <div className="w-full h-full">
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs
          title={"Home  /  Dashboard / Reporting / Product Performance"}
        />
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename="product_performance_report.csv"
          className="text-white bg-blue-500 px-4 py-2 rounded hover:bg-blue-600"
        >
          Export
        </CSVLink>
      </div>
      <main>
        {isLoading ? (
          <Loader />
        ) : reportingpermission?.read ? (
          <Productperformancetable
            columns={columns}
            data={orders}
            onItemAdded={fetchProductReport}
          />
        ) : (
          <p className="text-gray-500 md:py-20px-vw md:text-[1.2vw] text-center font-bold">
            You do not have permission to view this data.
          </p>
        )}
      </main>
    </div>
  );
};

export default Productperformance;

import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import Button from "../../Button";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import CategoryIcon from "@mui/icons-material/Category";
import HttpsIcon from "@mui/icons-material/Https";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getReq, postReq } from "../../../apis/api"; // Assuming you have getReq for fetching data
import { toast } from "react-toastify";
import Loader from "../../loader/Loading";

const AddStock = ({
    open,
    handleClose,
    ItemId,
    categories,
    onItemAdded,
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [employess, setEmployess] = useState([]);
  
    const {
      control,
      reset,
      handleSubmit,
      formState: { errors },
    } = useForm({
      defaultValues: {
        product_serial_number: "",
        created_by: "",
        stock: "",
      },
    });
  
    const fetchEmployess = async () => {
      try {
        const res = await getReq("/fetchEmployee");
        setEmployess(res.data || []);
      } catch (e) {
        console.error("Error fetching employess:", e);
        toast.error("Error in fetching employess");
      }
    };
  
    // Fetch the item details when the modal opens
    useEffect(() => {
      if (open) {
        fetchEmployess();
      }
    }, [open]);
  
    const onSubmit = async (data) => {
      // Prepare data for the API request
      const payload = {
        id: ItemId.id, 
        product_serial_number: data.product_serial_number.split(",").map((serial) => serial.trim()).join(","), // Ensure proper formatting
        stock: data.stock,
        created_by: data.created_by,
      };
  
      console.log("Payload to be sent:", payload);
  
      try {
        setIsLoading(true);
        const response = await postReq(payload, `/addStock`);
  
        if (response.status === 201) {
          toast.success("Stock added successfully!", {
            position: "top-right",
            autoClose: 5000,
          });
  
          reset();
          handleClose();
          setTimeout(() => {
            onItemAdded();
          }, 1000);
        } else {
          toast.error(response.data.message || "Failed to add stock.", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } catch (error) {
        if (error.response?.data?.messages) {
          const errorMsg = error.response.data.messages;
          Object.values(errorMsg).forEach((msg) =>
            toast.error(msg, { position: "top-right", autoClose: 5000 })
          );
        } else {
          toast.error("Something went wrong. Please try again.", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    const theme = createTheme({
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              "& .MuiInputBase-input": {
                fontSize: "1vw",
              },
              "& .MuiInputLabel-root": {
                fontSize: "1vw",
              },
            },
          },
        },
      },
    });
  
    return (
      <ThemeProvider theme={theme}>
        {isLoading && <Loader />}
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth="lg"
          sx={{
            "& .MuiDialogContent-root": {
              padding: "28px !important",
            },
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle
              className="border-b border-gray-300 flex items-center justify-between"
              sx={{ position: "relative" }}
            >
              <Typography variant="h6" className="mr-auto">
                Add Stock
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
  
            <DialogContent>
              <Grid container spacing={4}>
                {/* Display Product Name but send Product ID */}
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    label="Product Name"
                    value={ItemId.product_name} // Display name
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HttpsIcon />
                        </InputAdornment>
                      ),
                      readOnly: true, // Make field read-only
                    }}
                  />
                </Grid>
  
                {/* Stock */}
                <Grid item xs={12} sm={6} md={2}>
                  <Controller
                    name="stock"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Stock"
                        placeholder="Enter Stock"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HttpsIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
  
                {/* Product Serial Number */}
                <Grid item xs={12} sm={6} md={5}>
                  <Controller
                    name="product_serial_number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Product Serial Number"
                        placeholder="Enter serial numbers, separated by commas"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HttpsIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
  
                {/* Employee Selection */}
                <Grid item xs={12} sm={6} md={2} className="flex items-center">
                  <Controller
                    name="created_by"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Select Employee"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CategoryIcon />
                            </InputAdornment>
                          ),
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                maxHeight: 250,
                                "& .MuiMenuItem-root": {
                                  typography: "body2",
                                  fontSize: "1vw",
                                },
                              },
                            },
                          },
                        }}
                      >
                        {employess.map((emp) => (
                          <MenuItem key={emp.id} value={emp.id}>
                            {emp.user_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
  
            <DialogActions>
              <Button
                func={handleClose}
                title={"Cancel"}
                customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
              />
              <Button
                type="submit"
                func={handleSubmit(onSubmit)}
                title={"Add Stock"} 
                isLoading={isLoading}
              />
            </DialogActions>
          </form>
        </Dialog>
      </ThemeProvider>
    );
  };
  
  export default AddStock;
  


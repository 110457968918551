import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Dialog,
  DialogTitle,
  IconButton,
  FormControl,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
// MUI Icons
import {
  Close as CloseIcon,
  AccountCircle,
  Code,
  Category,
  Warning,
  Straighten,
  Scale,
  PriceChange,
  AttachMoney,
} from "@mui/icons-material";
import { postReq, putReq } from "../../../apis/api"; // Make sure to change this to use the PUT request for updates
import Button from "../../Button";
import CustomButton from "../../button/CustomButton";

// Validation schema using Yup
const validationSchema = yup.object().shape({
  subitem_name: yup.string().required("Subitem name is required"),
  subitem_code: yup.string().required("Subitem code is required"),
  hsn_code: yup.string().required("HSN code is required"),
  item_category_id: yup.number().required("Item category is required"),
  min_stock_alert: yup.number().required("Min stock alert is required"),
  default_length_mm: yup.number().required("Length is required"),
  default_width_mm: yup.number().required("Width is required"),
  default_thickness_mm: yup.number().required("Thickness is required"),
  default_weight_kg: yup.number().required("Weight is required"),
  sgew_price: yup.number().required("Sgew price is required"),
  processing_fees: yup.number().required("Processing fees are required"),
  total_weight_kg: yup.number().required("Total weight is required"),
  scrap_weight_kg: yup.number().required("Scrap weight is required"),
  sell_price: yup.number().required("Sell price is required"),
});

const EditSubItemModal = ({
  updateSubItemRecordPermissions,
  open,
  handleClose,
  categories,
  subItemData,
  onItemAdded,
}) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      subitem_name: subItemData?.subitem_name || "",
      subitem_code: subItemData?.subitem_code || "",
      hsn_code: subItemData?.hsn_code || "",
      item_category_id: subItemData?.item_category_id || "",
      min_stock_alert: subItemData?.min_stock_alert || "",
      default_length_mm: subItemData?.default_length_mm || "",
      default_width_mm: subItemData?.default_width_mm || "",
      default_thickness_mm: subItemData?.default_thickness_mm || "",
      default_weight_kg: subItemData?.default_weight_kg || "",
      sgew_price: subItemData?.sgew_price || "",
      processing_fees: subItemData?.processing_fees || "",
      total_weight_kg: subItemData?.total_weight_kg || "",
      scrap_weight_kg: subItemData?.scrap_weight_kg || "",
      sell_price: subItemData?.sell_price || "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const handleEditClick = () => {
    setIsEditable(!isEditable); // Toggle edit mode
  };
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await putReq(data, `/subitems/${subItemData.id}`); // Update API with subitem ID
      console.log("Response:", response.data);

      // Close the modal on success
      if (response.status === 200) {
        toast.success("SubItem Updated successfully!");
        reset();

        handleClose();
        setTimeout(() => {
          onItemAdded();
        }, 1000);
      } else {
        toast.error(response.data.message || "Update Failed", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.", {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              fontSize: "1vw",
            },
            "& .MuiInputLabel-root": {
              fontSize: "1vw",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={(_, reason) => reason !== "backdropClick" && handleClose()}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="border-b border-gray-300">
          <div className="flex justify-between items-center w-full">
            <div>Edit SubItem</div>
            {updateSubItemRecordPermissions && (
              <CustomButton
                variant="outlined"
                onClick={handleEditClick} // Keep the same handler function
                isEditing={isEditable} // Assuming 'isEditable' is your state flag
                text={isEditable ? "Cancel" : "Edit"}
                bgColor={isEditable ? "#0074ff1a" : "#0074ff1a"}
                textColor={isEditable ? "#0074ff" : "#0074ff"}
                fontWeight={600}
                borderRadius="8px"
                border="none"
                padding="8px 20px"
                margin="0px 40px" // Add margin to separate it from the title
                sx={{
                  position: "absolute",
                  right: -20,
                  top: 8,
                }}
              />
            )}
          </div>
        </DialogTitle>

        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ padding: "16px" }}
          className="mt-[3vh]"
        >
          <Grid container spacing={3.5}>
            {/* Subitem Name */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="subitem_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Subitem Name"
                    disabled={!isEditable}
                    placeholder="Enter subitem name"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.subitem_name}
                    helperText={errors.subitem_name?.message}
                  />
                )}
              />
            </Grid>

            {/* Subitem Code */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="subitem_code"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Subitem Code"
                    placeholder="Enter subitem code"
                    variant="outlined"
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Code />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.subitem_code}
                    helperText={errors.subitem_code?.message}
                  />
                )}
              />
            </Grid>

            {/* HSN Code */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="hsn_code"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="HSN Code"
                    placeholder="Enter HSN code"
                    variant="outlined"
                    disabled={!isEditable}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Category />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.hsn_code}
                    helperText={errors.hsn_code?.message}
                  />
                )}
              />
            </Grid>

            {/* Item Category */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth required variant="outlined">
                <Controller
                  name="item_category_id"
                  control={control}
                  defaultValue="" // Default value for item_category_id
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Item Category" // Floating label
                      placeholder="Select item category"
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Category className="md:mb-2 text-center" />
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "1vw", // Change font size for the label
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          fontSize: "1vw", // Change font size for the input text
                        },
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              maxHeight: 250, // Adjust the max height of the dropdown
                              "& .MuiMenuItem-root": {
                                typography: "body2", // Adjust text size
                                fontSize: "1vw", // Change font size
                              },
                            },
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null, // Ensure dropdown opens from top
                        },
                      }}
                      error={!!errors.item_category_id} // Validation error
                      helperText={errors.item_category_id?.message} // Error message
                    >
                      <MenuItem value="" disabled>
                        Select a category
                      </MenuItem>
                      {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>

            {/* Min Stock Alert */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="min_stock_alert"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Min Stock Alert"
                    disabled={!isEditable}
                    placeholder="Enter min stock alert"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Warning />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.min_stock_alert}
                    helperText={errors.min_stock_alert?.message}
                  />
                )}
              />
            </Grid>

            {/* Default Length (mm) */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="default_length_mm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Default Length (mm)"
                    placeholder="Enter length in mm"
                    variant="outlined"
                    disabled={!isEditable}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Straighten />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.default_length_mm}
                    helperText={errors.default_length_mm?.message}
                  />
                )}
              />
            </Grid>

            {/* Default Width (mm) */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="default_width_mm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Default Width (mm)"
                    placeholder="Enter width in mm"
                    disabled={!isEditable}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Straighten />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.default_width_mm}
                    helperText={errors.default_width_mm?.message}
                  />
                )}
              />
            </Grid>

            {/* Default Thickness (mm) */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="default_thickness_mm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Default Thickness (mm)"
                    placeholder="Enter thickness in mm"
                    disabled={!isEditable}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.default_thickness_mm}
                    helperText={errors.default_thickness_mm?.message}
                  />
                )}
              />
            </Grid>

            {/* Add remaining fields similarly (default_weight_kg, sgew_price, etc.) */}
            {/* Default Weight (kg) */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="default_weight_kg"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Default Weight (kg)"
                    placeholder="Enter weight in kg"
                    disabled={!isEditable}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.default_weight_kg}
                    helperText={errors.default_weight_kg?.message}
                  />
                )}
              />
            </Grid>

            {/* SGEW Price */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="sgew_price"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="SGEW Price"
                    placeholder="Enter SGEW price"
                    disabled={!isEditable}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.sgew_price}
                    helperText={errors.sgew_price?.message}
                  />
                )}
              />
            </Grid>

            {/* Processing Fees */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="processing_fees"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Processing Fees"
                    placeholder="Enter processing fees"
                    disabled={!isEditable}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.processing_fees}
                    helperText={errors.processing_fees?.message}
                  />
                )}
              />
            </Grid>

            {/* Total Weight (kg) */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="total_weight_kg"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Total Weight (kg)"
                    placeholder="Enter total weight in kg"
                    disabled
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.total_weight_kg}
                    helperText={errors.total_weight_kg?.message}
                  />
                )}
              />
            </Grid>

            {/* Scrap Weight (kg) */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="scrap_weight_kg"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Scrap Weight (kg)"
                    placeholder="Enter scrap weight in kg"
                    disabled={!isEditable}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.scrap_weight_kg}
                    helperText={errors.scrap_weight_kg?.message}
                  />
                )}
              />
            </Grid>

            {/* Sell Price */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="sell_price"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Sell Price"
                    disabled={!isEditable}
                    placeholder="Enter sell price"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.sell_price}
                    helperText={errors.sell_price?.message}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12} sx={{ textAlign: 'right' }}>
  <Button variant="contained" color="primary" type="submit">
    Save Subitem
  </Button>
</Grid> */}
            {isEditable ? (
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <div className="flex gap-2 w-full justify-end">
                  <button type="button" onClick={handleClose}>
                    <Button
                      func={handleClose}
                      title={"Cancel"}
                      customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
                    />
                  </button>
                  <button type="submit">
                    <Button title={"Save Changes"} isLoading={isLoading} />
                  </button>
                </div>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <div className="flex  w-full justify-end">
                  <button type="button" onClick={handleClose}>
                    <Button
                      func={handleClose}
                      title={"Cancel"}
                      customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
                    />
                  </button>
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default EditSubItemModal;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Grid,
  InputAdornment,
  MenuItem,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import InventoryIcon from "@mui/icons-material/Inventory";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../Button";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { postReq, getReq } from "../../../apis/api";

// Validation schema
const schema = yup.object({
  employee_id: yup.string().required("Employee is required."),
  assets: yup
    .array()
    .of(
      yup.object().shape({
        asset_id: yup.string().required("Asset is required."),
        quantity: yup
          .number()
          .required("Quantity is required.")
          .positive("Quantity must be positive.")
          .integer("Quantity must be an integer."),
      })
    )
    .min(1, "At least one asset-quantity pair is required."),
  date: yup.date().required("Date is required."),
});

export const AssignAssetModal = ({ open, handleClose, fetchData }) => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      employee_id: "",
      assets: [{ asset_id: "", quantity: "" }],
      date: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "assets",
  });

  // Fetch employees
  const fetchEmployees = async () => {
    try {
      const res = await getReq("/vendorEmployee");
      setEmployees(res.data || []);
    } catch (error) {
      toast.error("Error fetching employees.");
      console.error(error);
    }
  };

  // Fetch assets
  const fetchAssets = async () => {
    try {
      const res = await getReq("/assetsItem");
      setAssets(res.data || []);
    } catch (error) {
      toast.error("Error fetching assets.");
      console.error(error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchEmployees();
      fetchAssets();
    }
  }, [open]);
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      // Format date to DD-MM-YYYY
      const formattedDate = new Date(data.date)
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-"); // Converts DD/MM/YYYY to YYYY-MM-DD

      // Transforming form data into the required payload format
      const payload = {
        user_id: data.employee_id,
        assets_id: data.assets.map((asset) => asset.asset_id).join(","),
        assets_qty: data.assets.map((asset) => asset.quantity).join(","),
        date: formattedDate, // Use the formatted date
      };

      const response = await postReq(payload, "/assignAssets");

      if (response.status === 200) {
        setIsLoading(false);
        toast.success("Assets assign request sent successfully to admin");
        reset();
        handleClose();
        setTimeout(() => {
          fetchData();
        }, 2000);
      }
    } catch (error) { 
      setIsLoading(false);
      toast.error("Error assigning assets.");
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <Typography variant="h6">Assign Asset</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <div>
        <DialogContent>
          <Grid container spacing={3}>
            {/* Select Employee */}
            <Grid item xs={12} md={5}>
              <Controller
                name="employee_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Select Employee"
                    fullWidth
                    error={!!errors.employee_id}
                    helperText={errors.employee_id?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  >
                    {employees.map((employee) => (
                      <MenuItem key={employee.id} value={employee.id}>
                        {employee.user_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {/* Date */}
            <Grid item xs={12} md={5}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    label="Date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.date}
                    helperText={errors.date?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {/* Dynamic Asset-Quantity Rows */}
            {fields.map((field, index) => (
              <React.Fragment key={field.id}>
                {/* Select Asset */}
                <Grid item xs={12} md={5}>
                  <Controller
                    name={`assets.${index}.asset_id`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Select Asset"
                        fullWidth
                        error={!!errors.assets?.[index]?.asset_id}
                        helperText={errors.assets?.[index]?.asset_id?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <InventoryIcon />
                            </InputAdornment>
                          ),
                        }}
                      >
                        {assets.map((asset) => (
                          <MenuItem key={asset.id} value={asset.id}>
                            {asset.item_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>

                {/* Quantity */}
                <Grid item xs={12} md={5}>
                  <Controller
                    name={`assets.${index}.quantity`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        label="Quantity"
                        fullWidth
                        error={!!errors.assets?.[index]?.quantity}
                        helperText={errors.assets?.[index]?.quantity?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <InventoryIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                {/* Remove Button */}
                <Grid item xs={12} md={2}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => remove(index)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}

            {/* Add New Asset-Quantity Pair */}
            <Grid item xs={12}>
              <Button
                func={() => append({ asset_id: "", quantity: "" })}
                title="Add Asset"
                customcls="bg-[#1976D2] text-white"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </div>
      <DialogActions>
        <Button
          func={handleClose}
          title="Cancel"
          customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
        />
        <Button
          func={handleSubmit(onSubmit)}
          title="Assign"
          isLoading={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AssignAssetModal;

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import BreadCrumbs from "../../components/common/BreadCrumbs";

import CreateUser from "../../components/Modal/user/Create";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
export const User = () => {
  const [openModal, setOpenModal] = useState(false);
  const [fetchUsers, setFetchUsers] = useState(null);
  const { permissions } = useOutletContext();

  const { roleName } = useSelector((state) => state.role);
  const createUsersPermissions =
    permissions?.[roleName]?.modules?.["User Management"]?.pages?.Users
      ?.sections?.["Create Users"]; 

  console.log(createUsersPermissions, "User Create Permissions");

  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };

  const setFetchDataHandler = (fetchDataFn) => {
    setFetchUsers(() => fetchDataFn);
  };

  const handleUserCreated = () => {
    if (fetchUsers) {
      fetchUsers(); // Fetch the updated user list
      toast.success("User created and table updated!", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Users"} />
        {createUsersPermissions.create && (
          <Button title="Create" func={handleButtonClick} />
        )}
      </div>
      {openModal && (
        <CreateUser
          open={openModal}
          handleClose={handleButtonClick}
          onUserCreated={handleUserCreated}
        />
      )}
      <Outlet context={{ setFetchDataHandler, permissions }} />
    </div>
  );
};

export default User;

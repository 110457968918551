import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
  IconButton,
  Button as MuiButton,
  Select,
  MenuItem,
  InputAdornment,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import {
  Category,
  CurrencyRupee,
  Delete,
  FormatListNumbered,
  LocationOn,
  PersonPinCircleOutlined,
} from "@mui/icons-material";
import FormButton from "../../Button";
import { getReq, postReq, postReqwithIssueDesc } from "../../../apis/api";
import { MdDeleteForever, MdUploadFile } from "react-icons/md";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationwarrenty } from "../../../utils/validation";
import { BsPlusCircleDotted } from "react-icons/bs";

const AddWarrenty = ({
  open,
  handleClose,
  onItemAdded,
  orders = [],
  selectedWarrentyId,
}) => {
  const [imagePreviews, setImagePreviews] = useState([]);
  const [products, setProducts] = useState([]);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      order_id: "",
      customer_id: "",
      customer_name: "",
      products: [
        {
          product_id: "",
          product_name: "",
          product_quantity: "",
          product_description: "",
          issue_description: [],
        },
      ],
    },
    // resolver: yupResolver(validationwarrenty),
  });

  const {
    fields: productFields,
    append: addProduct,
    remove: removeProduct,
  } = useFieldArray({
    control,
    name: "products",
  });

  //  console.log("orderIDname",orderIds);
  const handleOrderChange = async (orderId) => {
    console.log("orderid", orderId);

    const selectedOrder = orders.find((order) => order.order_id === orderId);
    if (selectedOrder) {
      const customerName = selectedOrder.billing_address?.name || "";
      const customerId = selectedOrder.billing_address?.id || "";
      // const productName = selectedOrder?.products?.map(
      //   (product) => product.product_name
      // );
      setValue("customer_name", customerName);
      setValue("customer_id", customerId);
    }
    try {
      const response = await getReq(`/orderItem/${orderId}`);
      if (response.status === 200) {
        console.log("Extracted Products:", response.data);
        // Transform the response to create a flattened array
        const extractedProducts = response.data.flatMap((order) => [
          ...order.items.map((item) => ({
            product_id: item.id,
            product_name: item.item_name,
          })),
          ...order.sub_items.map((subItem) => ({
            product_id: subItem.id,
            product_name: subItem.subitem_name,
          })),
        ]);

        setProducts(extractedProducts);
        console.log("Extracted Products:", extractedProducts);
      } else {
        console.log("Error fetching order items");
        setProducts([]);
      }
    } catch (err) {
      console.error("Error:", err);
      toast.error("Error in fetching order with selected order ID");
    }
  };

  const handleImageUpload = (event, productIndex) => {
    const files = Array.from(event.target.files);
    const existingImages =
      getValues(`products.${productIndex}.issue_description`) || [];

    // Combine existing images and new uploads
    const allImages = [...existingImages, ...files];
    setValue(`products.${productIndex}.issue_description`, allImages);

    // Generate previews for the new images only
    const newPreviews = files.map((file) => URL.createObjectURL(file));

    setImagePreviews((prevPreviews) => {
      const updatedPreviews = [...prevPreviews];
      updatedPreviews[productIndex] = [
        ...(updatedPreviews[productIndex] || []),
        ...newPreviews,
      ];
      return updatedPreviews;
    });
  };

  const handleRemoveImage = (productIndex, imageIndex) => {
    const updatedImages = getValues(
      `products.${productIndex}.issue_description`
    ).filter((_, i) => i !== imageIndex);
    setValue(`products.${productIndex}.issue_description`, updatedImages);

    setImagePreviews((prevPreviews) => {
      const updatedPreviews = [...prevPreviews];
      updatedPreviews[productIndex] = updatedPreviews[productIndex].filter(
        (_, i) => i !== imageIndex
      );
      return updatedPreviews;
    });
  };

  useEffect(() => {
    console.log(selectedWarrentyId, "selectedPaymentId");

    const fetchSingleWarrenty = async () => {
      if (!selectedWarrentyId) return;

      try {
        const response = await getReq(
          `/fetchSingleWarranty/${selectedWarrentyId}`
        );
        const data = response.data[0];

        if (response.status === 200 && data) {
          console.log(data, "data");
          setValue("order_id", data.order_id || "");
          // Parse the 'products' field to get the issue_description array
          const products = JSON.parse(data.products || "[]");

          console.log("products", products);
          // Initialize the imagePreviews as an array of arrays (one for each product)
          const initialPreviews = products.map((product) => {
            const issueDescription = product.issue_description || [];
            return issueDescription.map(
              (img) => `https://sgewbackend.reflomsolutions.com/uploads/${img}`
            );
          });

          // Set image previews for each product
          setImagePreviews(initialPreviews);

          console.log(initialPreviews, "initialPreviews");

          // Set the form values
          setValue("order_id", data.order_id || "");
          setValue("id", selectedWarrentyId);
          setValue("customer_name", data.customer_name || "");
          // setValue("", data. || "");
          setValue("products", products);
        }
      } catch (error) {
        toast.error("Failed to fetch warranty details.");
      }
    };

    fetchSingleWarrenty();
  }, [selectedWarrentyId, setValue]);

  const onSubmit = async (data) => {
    console.log("data", data);
    // Ensure product_name is included in the payload
    const updatedData = {
      ...data,
      products: data.products.map((product) => ({
        product_id: product.product_id,
        product_quantity: product.product_quantity,
        product_description: product.product_description,
        product_name:
          products.find((p) => p.product_id === product.product_id)
            ?.product_name || "", // Match product_name
        issue_description: product.issue_description,
      })),
    };

    console.log(updatedData, "updated payload");

    const apiUrl = selectedWarrentyId ? "/updateWarranty" : "/addWarranty";

    try {
      const response = await postReqwithIssueDesc(updatedData, apiUrl);

      if (response.status === 200 || response.status === 201) {
        const successmsg =
          response?.data?.message || "Warranty Added Successfully";
        toast.success(
          selectedWarrentyId ? "Warranty Updated Successfully" : successmsg
        );
        reset();
        handleClose();
        setTimeout(() => {
          onItemAdded();
        }, 1000);
      } else {
        toast.error(response.data.message || "Warranty Not Added", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error:", error);

      const errorMessage =
        error.response?.data?.message ||
        "Something went wrong. Please try again."; // Fallback message

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      sx={{
        "& .MuiDialogContent-root": {
          padding: "24px !important",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Typography variant="h6">Add Warranty</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            {/* Order ID */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <Controller
                  name="order_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Order ID"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">#</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        handleOrderChange(e.target.value);
                      }}
                      error={!!errors?.order_id}
                      helperText={errors?.order_id?.message}
                    >
                      {orders.map((order) => (
                        <MenuItem key={order.id} value={order.order_id}>
                          {order.order_id}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="customer_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field} // Bind the field correctly to react-hook-form
                    fullWidth
                    label="Customer Name"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonPinCircleOutlined />
                        </InputAdornment>
                      ),
                      readOnly: true, // Make it read-only
                    }}
                    error={!!errors?.customer_name}
                    helperText={errors?.customer_name?.message}
                  />
                )}
              />
            </Grid>

            {productFields.map((product, index) => (
              <Grid container item spacing={2} key={product.id}>
                {/* Product ID */}
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth required variant="outlined">
                    <Controller
                      name={`products.${index}.product_id`}
                      control={control}
                      defaultValue={product.product_id}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          label="Product Name"
                          placeholder="Select Product"
                          onChange={(e) => {
                            field.onChange(e);
                            const selectedProduct = products.find(
                              (product) => product.id === e.target.value
                            );
                            setValue(
                              `products.${index}.product_name`,
                              selectedProduct?.product_name || ""
                            );
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Category />
                              </InputAdornment>
                            ),
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                sx: {
                                  maxHeight: 250,
                                },
                              },
                            },
                          }}
                          error={!!errors.products?.[index]?.product_id}
                          helperText={
                            errors.products?.[index]?.product_id?.message
                          }
                        >
                          <MenuItem value="" disabled>
                            Select a Product
                          </MenuItem>
                          {products.map((product) => (
                            <MenuItem
                              key={product.id}
                              value={product.product_id}
                            >
                              {product.product_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name={`products.${index}.product_quantity`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Quantity"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FormatListNumbered />
                            </InputAdornment>
                          ),
                        }}
                        readOnly
                        error={!!errors.products?.[index]?.quantity}
                        helperText={errors.products?.[index]?.quantity?.message}
                        inputProps={{
                          min: 0, // Ensure the value can't go below 0
                        }}
                        onInput={(e) => {
                          // Prevent negative numbers
                          if (parseInt(e.target.value) < 0) {
                            e.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name={`products.${index}.product_description`} // Field name for product description
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Product Description"
                        multiline
                        rows={2} // You can adjust the rows as needed
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FormatListNumbered />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.products?.[index]?.product_description}
                        helperText={
                          errors.products?.[index]?.product_description?.message
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={4} sm={2}>
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: 1,
                      backgroundColor: "#0074ff",
                      color: "#ffffff",
                      fontWeight: "500",
                    }}
                  >
                    <MdUploadFile size={20} style={{ marginRight: "6px" }} />
                    {getValues(`products.${index}.issue_description`)?.length >
                    0
                      ? "Add More Issue Images"
                      : "Upload Issue Images"}
                    <input
                      type="file"
                      hidden
                      multiple
                      onChange={(e) => handleImageUpload(e, index)}
                    />
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {Array.isArray(imagePreviews[index]) &&
                      imagePreviews[index].map((src, imgIndex) => (
                        <div
                          key={imgIndex}
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <img
                            src={src}
                            alt={`preview-${imgIndex}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                          <IconButton
                            onClick={() => handleRemoveImage(index, imgIndex)}
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              backgroundColor: "rgba(255, 255, 255, 0.7)",
                            }}
                            size="small"
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </div>
                      ))}
                  </div>
                </Grid>

                {/* Actions */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={2}
                  className="flex items-center gap-3"
                >
                  <IconButton onClick={() => removeProduct(index)}>
                    <MdDeleteForever size={30} style={{ color: "#d0412e" }} />
                  </IconButton>
                  {index === productFields.length - 1 && (
                    <IconButton
                      onClick={() =>
                        addProduct({
                          product_id: "",
                          product_name: "",
                          product_quantity: "",
                          product_description: "",
                          issue_description: [],
                        })
                      }
                      color="primary"
                    >
                      <BsPlusCircleDotted size={30} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions className=" md:mr-14px-vw md:mb-10px-vw ">
          <FormButton
            func={handleClose}
            title={"Cancel"}
            customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
          />
          <FormButton
            type="submit"
            func={handleSubmit(onSubmit)}
            title={selectedWarrentyId ? "Update Warranty" : "Add Warranty"}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddWarrenty;

import React, { useState } from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import FormButton from "../../components/Button";
import { Link, useOutletContext } from "react-router-dom";
import TurnoverReport from "./Turnoverreport";
import { sections } from "../../data/reportingsections";
import Orderreport from "./Orderreport";
import { useSelector } from "react-redux";
import NoPermissionModal from "../../components/Modal/nopermissionmodal/NopermissionModal";

const Allreporting = () => {
  const [modalType, setModalType] = useState(null);
 

  const handleModal = (type) => {
    let formattedType = type
      .toLowerCase()
      .replace("report", "")
      .replace("performance", "")
      .trim();
    console.log(`Opening modal of type: ${formattedType}`);
    setModalType(formattedType);
  };

  const handleCloseModal = () => {
    setModalType(null);
  };

  return (
    <div className=" bg-gray-50 h-full w-full">
      <div className="flex justify-between items-center bg-white rounded-lg shadow-md w-full py-4 px-6 my-6">
        <BreadCrumbs title={"Home  /  Dashboard / Reporting"} />
      </div>
      <Grid container spacing={3}>
        {sections.map((section, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card className="rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 border border-gray-200 hover:border-blue-500 bg-white">
              <CardContent className="p-6">
                <div className="flex items-center gap-4 mb-4">
                  {section.icon}
                  <Typography
                    variant="h6"
                    className="font-semibold text-gray-800 ml-3"
                  >
                    {section.title}
                  </Typography>
                </div>
                <Typography
                  variant="body2"
                  className="text-gray-600 text-md leading-relaxed mb-40px-vw"
                >
                  {section.description}
                </Typography>

                <div className="flex justify-end">
                  {section?.modal ? (
                    <div
                      onClick={() => {
                        const modalType = section.title
                          .toLowerCase()
                          .replace(/\s+/g, ""); // Remove spaces
                        console.log(`Modal type: ${modalType}`);
                        handleModal(modalType);
                      }}
                    >
                      <FormButton title="View All" />
                    </div>
                  ) : (
                    <Link to={section.link}>
                      <FormButton title="View All" />
                    </Link>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal Rendering Based on Modal Type */}
      {modalType === "turnover" && (
        <TurnoverReport open={true} handleClose={handleCloseModal} />
      )}

      {modalType === "orders" && (
        <Orderreport open={true} handleClose={handleCloseModal} />
      )}
    </div>
  );
};

export default Allreporting;

import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { getReq, getReq1 } from "../../apis/api"; // Ensure getReq is properly set up
import { toast } from "react-toastify";
import TransactionHistoryTable from "./Transaction";

import { useParams } from "react-router-dom";
import BreadCrumbs from "../common/BreadCrumbs";
import Loader from "../loader/Loading";
import FormButton from "../Button";
import AddPaymentModal from "../Modal/paymentmodal/AddPaymentModal";

export const TransactionMain = () => {
  const { paymentId } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [payments, setPayments] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [uId, setUid] = useState(null);

  const fetchCustomers = async () => {
    try {
      const res = await getReq("/fetchCustomer");
      setCustomers(res.data || []);
    } catch (e) {
      console.error("Error fetching categories:", e);
      toast.error("Error in fetching categories");
    }
  };

  useEffect(() => {
    fetchCustomers();
    fetchPaymentData();
  }, []);

  const fetchPaymentData = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/paymentHistory");

      if (response.status === 200) {
        setPayments(response.data);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in Fetching Payment ");
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };

  const fetchTransactionHistory = async () => {
    try {
      setLoading(true);
      const response = await getReq1(`/payments/fetch/${paymentId}`);
      console.log(response, "api response");
      setData(response.data.data);
      setUserData(response.data.users);
      if (response.data.users && response.data.users.length > 0) {
        setUid(response.data.users[0].id); // Assuming you want the first user's id
        // console.log(uId, "qwertyu");
      }
      // Ensure response is an array and contains items
    } catch (error) {
      console.error("Error fetching transaction history:", error);
      toast.error("Failed to fetch transaction history.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paymentId) {
      fetchTransactionHistory();
    }
  }, [paymentId]);

  return (
    <div className="font-poppins">
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Payments / Transaction-History "} />
        <FormButton title="Settle" func={handleButtonClick} />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <TransactionHistoryTable
          transactionData={data}
          onItemAdded={fetchTransactionHistory}
          userData={userData} 
          customers={customers}
        />
      )}

      {openModal && (
        <AddPaymentModal
          open={openModal}
          handleClose={handleButtonClick}
          customers={customers}
          onItemAdded={fetchTransactionHistory}
          id={uId}
        />
      )}
    </div>
  );
};

export default TransactionMain;


import './App.css';
import AppRoutes from './routes/AppRoutes';
import { ToastContainer } from "react-toastify";


function App() {
  return (
    <div className='w-full m-0 p-0 '
    style={{
      backgroundColor:'#f9f9fb',
    }}
    >
   <ToastContainer 
  position="top-right" 
  autoClose={5000} 
  hideProgressBar={false} 
  closeOnClick 
  pauseOnHover 
  draggable 
  pauseOnFocusLoss 
/>
  <AppRoutes/>
    </div>
  );
}

export default App;

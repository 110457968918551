import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Grid,
  InputAdornment,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InventoryIcon from "@mui/icons-material/Inventory";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NotesIcon from "@mui/icons-material/Notes";
import Button from "../../Button";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { postReq, getReq } from "../../../apis/api";
import { Category } from "@mui/icons-material";

// Validation schema
const schema = yup.object({
  product_name: yup.string().required("At least one product must be selected."),
  quantity: yup
    .string()
    .required("Quantity is required.")
    .matches(/^[\d,]+$/, "Must be numbers separated by commas."),
  dealer_name: yup.string().required("Dealer name is required."),
  date: yup.date().required("Date is required."),
  note: yup.string().optional(),
});

const AddDealer = ({ open, handleClose, title, onItemAdded }) => {
  const [products, setProducts] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      product_name: "",
      quantity: "",
      dealer_name: "",
      date: "",
      note: "",
    },
  });

  // Fetch products from API
  const fetchProducts = async () => {
    try {
      const res = await getReq("/products");
      setProducts(res.data || []);
    } catch (e) {
      console.error("Error fetching Items:", e);
      toast.error("Error in fetching Items");
    }
  };

  const fetchDealer = async () => {
    try {
      const res = await getReq("/fetchDealer");
      setDealers(res.data || []);
    } catch (e) {
      console.error("Error fetching dealer", e);
      toast.error("Error in fetching Dealer");
    }
  };

  // Fetch products on component mount
  useEffect(() => {
    fetchProducts();
    fetchDealer();
  }, []);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await postReq(data, "/createDealerOrder");
      if (response.status === 201) {
        toast.success("Dealer order created successfully!");
        reset();
        handleClose();
        setIsLoading(false);
        setTimeout(() => {
          onItemAdded();
        }, 1000);
      }
    } catch (error) {
      toast.error("Error creating dealer order. Please try again.");
      console.error("API error:", error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialogContent-root": {
          padding: "24px !important",
        },
      }}
    >
      <DialogTitle
        className="border-b border-gray-300"
        sx={{ position: "relative" }}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          {/* Product Name */}
          <Grid item xs={12}>
            <Controller
              name="product_name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  select
                  label="Product Name"
                  value={value ? value.split(",") : []}
                  onChange={(event) => {
                    const selected = event.target.value.join(",");
                    onChange(selected);
                  }}
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected) =>
                      selected.length ? selected.join(", ") : "Select Products",
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          maxHeight: 250,
                        },
                      },
                    },
                  }}
                  fullWidth
                  error={!!errors.product_name}
                  helperText={errors.product_name?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InventoryIcon />
                      </InputAdornment>
                    ),
                  }}
                >
                  {products.map((product) => (
                    <MenuItem key={product.id} value={product.product_name}>
                      {product.product_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {/* Quantity */}
          <Grid item xs={12}>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Quantity"
                  placeholder="e.g., 10,20,30"
                  fullWidth
                  error={!!errors.quantity}
                  helperText={errors.quantity?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InventoryIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required variant="outlined">
              <Controller
                name="dealer_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Dealer Name"
                    placeholder="Select Product"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Category />
                        </InputAdornment>
                      ),
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            maxHeight: 250,
                          },
                        },
                      },
                    }}
                    error={!!errors.dealer_name}
                    helperText={errors.dealer_name?.message}
                  >
                    <MenuItem value="" disabled>
                      Select a Dealer
                    </MenuItem>
                    {/* Map through product options */}
                    {dealers.map((product) => (
                      <MenuItem key={product.id} value={product.user_name}>
                        {product.user_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </FormControl>
          </Grid>

          {/* Date */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  label="Date"
                  fullWidth
                  error={!!errors.date}
                  helperText={errors.date?.message}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          {/* Note */}
          <Grid item xs={12}>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Note"
                  placeholder="Add a note (optional)"
                  fullWidth
                  multiline
                  rows={2}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NotesIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          func={handleClose}
          title={"Back"}
          customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
        />
        <Button
          func={handleSubmit(onSubmit)}
          title={"Save"}
          isLoading={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddDealer;

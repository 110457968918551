import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPermissions } from "../../slices/AccessManagementSlice";
import Header from "../../components/header";
import Footer from "../../components/footer/Footer";
import Cookies from "js-cookie";
import { getReq } from "../../apis/api";
import Loader from "../../components/loader/Loading";

const HomePage = () => {
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.access);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const userCookie = Cookies.get("user");
        if (!userCookie) {
          throw new Error("User session not found. Please log in.");
        }

        const userData = JSON.parse(decodeURIComponent(userCookie));
        const roleId = userData.role_id;

        const response = await getReq(`getPermissionsForRole/${roleId}`);
        dispatch(setPermissions(response));
      } catch (err) {
        console.error("Error fetching permissions:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, [dispatch]);

  if (loading) {
    return <div>
      <Loader/>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Header permissions={permissions} />
      <div className="mt-[20vw] md:mt-[4.5rem]">
        <div className="flex-1 md:px-8 px-12px-vw overflow-auto w-full h-full">
          {/* Pass permissions via Outlet context */}
          <Outlet context={{ permissions }} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;

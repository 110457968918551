import { FaUserCircle } from "react-icons/fa";
import {
  FaPhoneAlt,
  FaMobileAlt,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { Fingerprint as FingerprintIcon } from "@mui/icons-material";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { TextField, Card, CardContent, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Cookies from "js-cookie";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import BreadCrumButton from "../../components/button/BreadCrumButton";
import CustomButton from "../../components/button/CustomButton";
import { getReq, postReq } from "../../apis/api";
import { toast } from "react-toastify";
import FormButton from "../../components/Button";

const initialUserData = {
  user_id: "",
  userType: "", // Updated user types
  user_name: "",
  address: "",
  father_name: "",
  phone: "",
  mobile: "",
  email: "",
  address_line_1: "",
  address_line_2: "",
  village: "",
  district: "",
  state: "",
  country: "",
  bankDetails: "",
  gstinNumber: "",
  employeeMonthlySalary: "",
};

export default function UserProfile() {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(initialUserData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = async (e) => {
    console.log("formdata", userData);
    e.preventDefault();
    try {
      setIsLoading(true);
      const { userType, ...userDataWithoutType } = userData;
      const response = await postReq(userDataWithoutType, "/updateUser");
      if (response.status === 200) {
        toast.success(
          ` ${userData.user_name} Your Detail Updated Successfully`
        );
        setIsEditing(false);
      } else {
        toast.error(response.data.message || "User Not Updated", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      // Handling errors and displaying the appropriate error message
      if (error.response && error.response.data && error.response.data.msg) {
        const errorMsg = error.response.data.msg;

        // Display error message in toast
        Object.values(errorMsg).forEach((msg) => {
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
          });
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const userCookie = Cookies.get("user");
  const user = userCookie ? JSON.parse(userCookie) : null;

  console.log("user", user);

  const roleId = user?.role_id;
  const username = user?.username;
  const userId = user?.user_id;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        const response = await getReq(`/fetchUsers/${userId}`);

        if (response.data) {
          setUserData(response.data);
        }
      } catch (error) {
        toast.error("error in fetching userdetails");
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, [userId]);

  return (
    <div className="flex font-poppins h-full !overflow-x-hidden ">
      {/* Left Sidebar */}
      <div className="w-1/4 p-60px-vw   border-r ">
        <div className="flex flex-col items-center mb-6 ">
          <div className=" md:p-10px-vw md:pb-20px-vw  flex justify-center items-center">
            <FaUserCircle
              className="text-blue-500"
              style={{ fontSize: "8vw" }}
            />
          </div>
          <Typography
            variant="h5"
            className="mt-20px-vw font-bold text-gray-800"
          >
            {username}
          </Typography>
          <Typography variant="body2" className="text-gray-600">
            {roleId === "1" ? "Admin" : ""}
          </Typography>
        </div>
        <div className="space-y-4 text-lg text-gray-600">
          {/* Additional sidebar content can go here */}
        </div>

        <div className="border mt-40px-vw bg-white border-gray-300 rounded-[1.5vw] flex flex-col items-center justify-center p-6   ">
          <Typography
            variant="h6"
            className="font-bold text-center text-[#10131e] mb-2"
          >
            Outstanding Balance Amount
          </Typography>
          <Typography variant="h4" className="text-[#00796b] font-bold">
            <span className="text-[1.1em]">₹</span> 500,000
          </Typography>
        </div>
        {/* <div className="border mt-20px-vw bg-white border-gray-300 rounded-[1.5vw] flex flex-col items-center justify-center p-6   transition-transform transform hover:scale-105">
          <Typography variant="h6" className="font-bold text-[#10131e] mb-2">
            Total Dues
          </Typography>
          <Typography variant="h4" className="text-[#00796b] font-bold">
            500,000
          </Typography>
          <Typography variant="body2" className="text-gray-600 mt-1">
            This month
          </Typography>
        </div> */}

        <div className="border mt-20px-vw border-gray-300 rounded-[1.5vw] flex flex-col items-center justify-center p-20px-vw bg-white">
          <Typography
            variant="h6"
            className="font-bold text-[#10131e] md:mb-24px-vw"
          >
            Connect
          </Typography>

          <div className="flex flex-col md:mt-10px-vw space-y-4">
            {/* Mobile Number */}
            <div className="flex items-center space-x-2">
              <FaMobileAlt className="mr-2 text-gray-500 text-[1.2vw] w-6" />
              <a
                href={`tel:+${userData.mobile}`}
                className="text-[1vw] hover:text-blue-600 cursor-pointer"
              >
                {userData.mobile || "N/A"}
              </a>
            </div>

            {/* Phone Number */}
            <div className="flex items-center space-x-2">
              <FaPhoneAlt className="mr-2 text-gray-500 text-[1.2vw] w-6" />
              <a
                href={`tel:+${userData.phone}`}
                className="text-[1vw] hover:text-blue-600 cursor-pointer"
              >
                {userData.phone || "N/A"}
              </a>
            </div>

            {/* Email */}
            <div className="flex items-center space-x-2">
              <FaEnvelope className="mr-2 text-gray-500 text-[1.2vw] w-6" />
              <a
                href={`mailto:${userData.email}`}
                className="text-[1vw] hover:text-blue-600 cursor-pointer"
              >
                {userData.email || "N/A"}
              </a>
            </div>

            {/* Address */}
            <div className="flex items-start space-x-2">
              <FaMapMarkerAlt className="mr-2 text-gray-500 text-[1.2vw] w-6" />
              <a
                href={`https://www.google.com/maps?q=${encodeURIComponent(
                  userData.address_line_1 || "N/A"
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-[1vw] hover:text-blue-600 cursor-pointer"
              >
                {userData.address_line_1 || "N/A"}
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1   px-40px-vw">
        <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
          <BreadCrumbs title={"Home  /  Profile"} />
          <CustomButton
            variant="outlined"
            onClick={toggleEdit}
            isEditing={isEditing}
            text={isEditing ? "Cancel" : "Edit"}
            bgColor={isEditing ? "#0074ff1a" : "#0074ff1a"}
            textColor={isEditing ? "#0074ff" : "#0074ff"}
            fontWeight={600}
            borderRadius="8px"
            border="none"
            padding="10px 20px"
          />
        </div>

        <Card className=" rounded-[1vw]">
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-6">
              <Typography
                variant="h6"
                className="font-semibold text-gray-800 border-b-2 border-gray-300 pb-2"
              >
                User Details
              </Typography>
              {/* User Type and User Name */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <TextField
                    id="user_id"
                    name="user_id"
                    type="text"
                    label="User Id"
                    value={userId}
                    onChange={handleInputChange}
                    disabled
                    variant="outlined"
                    fullWidth
                    className={`rounded-md ${
                      !isEditing ? "" : "border-[#10131e]"
                    }`}
                    InputProps={{
                      startAdornment: (
                        <FingerprintIcon className="mr-2 text-gray-500 " />
                      ),
                    }}
                  />
                </div>
                <div>
                  <TextField
                    id="userType"
                    name="userType"
                    label="User Type"
                    value={userData.userType}
                    variant="outlined"
                    fullWidth
                    disabled // Make this field non-editable
                    className={`rounded-md ${
                      isEditing ? "border-[#10131e]" : ""
                    }`}
                    InputProps={{
                      startAdornment: (
                        <PersonIcon className="mr-2 text-gray-500" />
                      ),
                    }}
                  />
                </div>
                <div>
                  <TextField
                    id="user_name"
                    name="user_name"
                    label="User Name"
                    value={userData.user_name}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    variant="outlined"
                    fullWidth
                    className={`rounded-md  ${
                      !isEditing ? "" : "border-[#10131e]"
                    }`}
                    InputProps={{
                      startAdornment: (
                        <PersonIcon className="mr-2 text-gray-500" />
                      ),
                    }}
                  />
                </div>

                <div>
                  <TextField
                    id="address_line_1"
                    name="address_line_1"
                    type="text"
                    label="Address"
                    value={userData.address_line_1}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    variant="outlined"
                    fullWidth
                    className={`rounded-md ${
                      !isEditing ? "" : "border-[#10131e]"
                    }`}
                    InputProps={{
                      startAdornment: (
                        <LocationOnIcon className="mr-2 text-gray-500 " />
                      ),
                    }}
                  />
                </div>
              </div>

              {/* Father Name, Phone, and Mobile */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <TextField
                    id="father_name"
                    name="father_name"
                    label="Father Name"
                    value={userData.father_name}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    variant="outlined"
                    fullWidth
                    className={`rounded-md ${
                      !isEditing ? "" : "border-[#10131e]"
                    }`}
                  />
                </div>
                <div>
                  <TextField
                    id="phone"
                    name="phone"
                    label="Phone"
                    value={userData.phone}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    variant="outlined"
                    fullWidth
                    className={`rounded-md  ${
                      !isEditing ? "" : "border-[#10131e]"
                    }`}
                    InputProps={{
                      startAdornment: (
                        <PhoneIcon className="mr-2 text-gray-500" />
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <TextField
                    id="mobile"
                    name="mobile"
                    label="Mobile"
                    value={userData.mobile}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    variant="outlined"
                    fullWidth
                    className={`rounded-md  ${
                      !isEditing ? "" : "border-[#10131e]"
                    }`}
                  />
                </div>
                <div>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    label="Email"
                    value={userData.email}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    variant="outlined"
                    fullWidth
                    className={`rounded-md  ${
                      !isEditing ? "" : "border-[#10131e]"
                    }`}
                    InputProps={{
                      startAdornment: (
                        <EmailIcon className="mr-2 text-gray-500 " />
                      ),
                    }}
                  />
                </div>
              </div>

              {/* Control Buttons */}
              <div className="flex justify-between w-full items-end mt-6">
                {isEditing && (
                  <FormButton
                    type="submit"
                    func={handleSubmit}
                    title={"Save"}
                    isLoading={isLoading}
                  />
                )}
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

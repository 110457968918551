import React, { useEffect, useState } from "react";

import BreadCrumbs from "../../components/common/BreadCrumbs";
import Button from "../../components/Button";
import Loader from "../../components/loader/Loading";
import AssetsDataTable from "../../components/DataTable/AssetsDataTable";
import AssignAssetModal from "../../components/Modal/asset/AssignAssetModal";
import { getReq } from "../../apis/api";
import { toast } from "react-toastify";

export const AssetsTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVendorOpen, setVendorOpen] = useState(false);
  const [assetsData, setAssetData] = useState([]);
  // Toggle Vendor Modal
  const handleVendorFormOpenModal = () => {
    setTimeout(() => {
      setVendorOpen(!isVendorOpen);
    }, 1000); // 1-second delay
  };
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await getReq("fetchAssets");
      if (res.status === 200) {
        const sortedDataDescending = res.data.sort((a, b) => b.id - a.id);
        setAssetData(sortedDataDescending);
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e, "error");
      toast.error("error in fetching assets");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Items / Assets "} />
        <div className="flex items-center justify-center gap-[1vw]">
          <Button title="Assign Assets" func={handleVendorFormOpenModal} />
        </div>
      </div>

      {isLoading ? <Loader /> : <AssetsDataTable assetsData={assetsData} />}

      {/* Add Vendor Attendance Modal */}
      <AssignAssetModal
        fetchData={fetchData}
        open={isVendorOpen}
        handleClose={handleVendorFormOpenModal}
      />
    </div>
  );
};

export default AssetsTable;

import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  MenuItem,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import Button from "../../Button";
import { Controller, useForm } from "react-hook-form";
import CategoryIcon from "@mui/icons-material/Category";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import HttpsIcon from "@mui/icons-material/Https";
import StraightenIcon from "@mui/icons-material/Straighten";
import ScaleIcon from "@mui/icons-material/Scale";
import PercentIcon from "@mui/icons-material/Percent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import UsageIcon from "@mui/icons-material/Build";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Category } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { editItemSchema } from "../../../utils/validation";
import { getReq, postReqSecure } from "../../../apis/api"; // Assuming you have getReq for fetching data
import { toast } from "react-toastify";
import Loader from "../../loader/Loading";
import CustomButton from "../../button/CustomButton";

const EditItemModal = ({
  updateItemRecordPermissions,
  open,
  handleClose,
  ItemId,
  categories,
  onItemAdded,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [itemData, setItemData] = useState(null);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editItemSchema),
    defaultValues: {
      item_category_id: "",
      item_category: "",
      item_name: "",
      hsn: "",
      default_length_mm: "",
      default_width_mm: "",
      default_thickness_mm: "",
      default_weight_kg: "",
      default_tax_percentage: "18",
      usage_type: "",
      type: "",
      stock: "",
      unit_price: "",
      sell_price: "",
      expected_delivery_party: "",
      minimum_stock_alert: "",
      status: "",
    },
  });

  // Fetch the item details when the modal opens
  useEffect(() => {
    if (ItemId && open) {
      fetchItemDetails();
    }
  }, [ItemId, open]);

  const fetchItemDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getReq(`/fetchItem/${ItemId}`);
      if (response.status === 200) {
        const data = response.data;

        setItemData(data);
        reset(data);
      } else {
        toast.error("Failed to fetch item details.");
      }
    } catch (error) {
      toast.error("Error fetching item details.");
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    console.log("Updated Data:", data);

    try {
      setIsLoading(true);
      const response = await postReqSecure(data, `/updateItem/${ItemId}`);

      if (response.status === 200) {
        toast.success("Item updated successfully!", {
          position: "top-right",
          autoClose: 5000,
        });

        reset();
        handleClose();
        setTimeout(() => {
          onItemAdded();
        }, 1000);
      } else {
        toast.error(response.data.message || "Item Not Created", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {

      if (error.response) {
        // Handle API errors (like 404)
        const errorMsg = error.response.data.message || "Something went wrong!";
        toast.error(errorMsg, {
          position: "top-right",
          autoClose: 5000,
        });
      } else if (error.request) {
        // Handle network errors (no response received)
        toast.error("No response from server. Please check your connection.", {
          position: "top-right",
          autoClose: 5000,
        });
      } else {
        // Handle unexpected errors
        toast.error(
          error.message || "Something went wrong. Please try again.",
          {
            position: "top-right",
            autoClose: 5000,
          }
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              fontSize: "1vw",
            },
            "& .MuiInputLabel-root": {
              fontSize: "1vw",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiDialogContent-root": {
            padding: "28px !important",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            className="border-b border-gray-300 flex items-center  justify-between"
            sx={{ position: "relative" }}
          >
            {/* Title Section */}
            <Typography variant="h6" className="mr-auto">
              View / Edit Item
            </Typography>

            {/* Edit/Cancel Button */}
            {updateItemRecordPermissions && (
              <CustomButton
                variant="outlined"
                onClick={() => setIsEditMode(!isEditMode)}
                isEditing={isEditMode}
                text={isEditMode ? "Cancel" : "Edit"}
                bgColor={isEditMode ? "#0074ff1a" : "#0074ff1a"}
                textColor={isEditMode ? "#0074ff" : "#0074ff"}
                fontWeight={600}
                borderRadius="8px"
                border="none"
                padding="8px 20px"
                // Add margin to separate it from the title
              />
            )}

            {/* Close Button */}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            ></IconButton>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={4}>
              {/* Render all fields with the 'disabled' prop if not in edit mode */}
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth required variant="outlined">
                  <Controller
                    name="item_category_id"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Item Category"
                        fullWidth
                        disabled={!isEditMode}
                        placeholder="Select item category"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Category className="md:mb-2 text-center" />
                            </InputAdornment>
                          ),
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                maxHeight: 250,
                                "& .MuiMenuItem-root": {
                                  typography: "body2",
                                  fontSize: "1vw",
                                },
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          },
                        }}
                        error={!!errors.item_category_id}
                        helperText={errors.item_category_id?.message}
                      >
                        <MenuItem value="" disabled>
                          Select a category
                        </MenuItem>
                        {categories.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="item_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Item Name"
                      placeholder="Enter Item Name"
                      disabled={!isEditMode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CategoryIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.item_name}
                      helperText={errors.item_name?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="hsn"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="HSN"
                      disabled
                      placeholder="Enter HSN"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HttpsIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.hsn}
                      helperText={errors.hsn?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth variant="outlined">
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Item Type"
                        variant="outlined"
                        fullWidth
                        disabled={!isEditMode}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <UsageIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.type}
                        helperText={errors.type?.message}
                      >
                        <MenuItem value="" disabled>
                          Select Item Type
                        </MenuItem>
                        <MenuItem value="Kilogram">Kilogram</MenuItem>
                        <MenuItem value="Quantity">Quantity</MenuItem>
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth variant="outlined">
                  <Controller
                    name="usage_type"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Usage Type"
                        variant="outlined"
                        disabled={!isEditMode}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <UsageIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.usage_type}
                        helperText={errors.usage_type?.message}
                      >
                        <MenuItem value="" disabled>
                          Select Usage Type
                        </MenuItem>
                        <MenuItem value="For Sale">For Sale</MenuItem>
                        <MenuItem value="For Assets">For Assets</MenuItem>
                        <MenuItem value="For Expense">For Expense</MenuItem>
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>

              {/* stock  */}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="stock"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Stock"
                      disabled={!isEditMode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Inventory2OutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.stock}
                      helperText={errors.stock?.message}
                    />
                  )}
                />
              </Grid>

              {/* unit price  */}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="unit_price"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Unit Price"
                      disabled={!isEditMode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.unit_price}
                      helperText={errors.unit_price?.message}
                    />
                  )}
                />
              </Grid>

              {/* sell Price */}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="sell_price"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Sell Price"
                      disabled={!isEditMode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.sell_price}
                      helperText={errors.sell_price?.message}
                    />
                  )}
                />
              </Grid>

              {/* expected delivery party  */}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="expected_delivery_party"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Expected Delivery Party"
                      disabled={!isEditMode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <WarningAmberIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.expected_delivery_party}
                      helperText={errors.expected_delivery_party?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="minimum_stock_alert"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Minimum Stock Alert"
                      disabled={!isEditMode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <WarningAmberIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.minimum_stock_alert}
                      helperText={errors.minimum_stock_alert?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth variant="outlined">
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Status"
                        variant="outlined"
                        disabled={!isEditMode}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <UsageIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.status}
                        helperText={errors.status?.message}
                      >
                        <MenuItem value="Enable">Enable</MenuItem>
                        <MenuItem value="Disable">Disable</MenuItem>
                        <MenuItem value="Scrap">Scrap</MenuItem>
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="default_length_mm"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Default Length (mm)"
                      placeholder="Enter Default Length"
                      disabled={!isEditMode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <StraightenIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.default_length_mm}
                      helperText={errors.default_length_mm?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="default_width_mm"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Default Width (mm)"
                      placeholder="Enter Default Width"
                      disabled={!isEditMode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <StraightenIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.default_width_mm}
                      helperText={errors.default_width_mm?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="default_thickness_mm"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Default Thickness (mm)"
                      placeholder="Enter Default Thickness"
                      disabled={!isEditMode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <StraightenIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.default_thickness_mm}
                      helperText={errors.default_thickness_mm?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="default_weight_kg"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Default Weight (kg)"
                      placeholder="Enter Default Weight"
                      disabled={!isEditMode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ScaleIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.default_weight_kg}
                      helperText={errors.default_weight_kg?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="default_tax_percentage"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Default Tax Percentage (%)"
                      placeholder="Enter Tax Percentage"
                      disabled={!isEditMode}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PercentIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.default_tax_percentage}
                      helperText={errors.default_tax_percentage?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions className=" md:mr-14px-vw md:mb-8px-vw ">
            <Button
              func={handleClose}
              title={"Cancel"}
              customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
            />
            {isEditMode && (
              <Button
                type="submit"
                func={handleSubmit(onSubmit)}
                title={"Save Changes"} 
                isLoading={isLoading}
              />
            )}
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default EditItemModal;

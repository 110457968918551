import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  RadioGroup,
  Radio,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Button as MuiButton,
  Button,
  Box,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CommentIcon from "@mui/icons-material/Comment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as yup from "yup";
import Loader from "../../loader/Loading";
import { paymentSchema } from "../../../utils/validation";
import { Delete, FormatListNumbered } from "@mui/icons-material";
import FormButton from "../../Button";
import { toast } from "react-toastify";
import { getReq, postReq, postReqwithFile } from "../../../apis/api";
import { MdUploadFile } from "react-icons/md";
import { useParams } from "react-router-dom";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            fontSize: "1vw",
          },
          "& .MuiInputLabel-root": {
            fontSize: "1vw",
          },
        },
      },
    },
  },
});

const AddPaymentModal = ({
  open,
  customers,
  handleClose,
  onItemAdded,
  userId,
  id,
  selectedPaymentId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [paymentScreenshot, setPaymentScreenshot] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(paymentSchema),
    defaultValues: {
      user_id: "",
      debit_bank: "",
      reference_id: "",
      payment_comment: "",
      amount: "",
      type: "",
      received_by: "",
      next_due_date: "",
      payment_screenshot: null,
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPaymentScreenshot(file);
      setPreviewUrl(URL.createObjectURL(file)); // Generate preview URL
    }
  };

  useEffect(() => {
    if (id) {
      setValue("user_id", id);
    }
  }, [id, setValue]);

  useEffect(() => {
    console.log(selectedPaymentId, "selectedPaymentId");
    const fetchSinglePaymentHistory = async () => {
      if (!selectedPaymentId) return;

      try {
        // setIsLoading(true);
        const response = await getReq(
          `/singlePaymentHistory/${selectedPaymentId}`
        );
        const data = response.data[0];
        if (response.status === 200 && data) {
          setValue("user_id", data.user_id || "");
          setValue("debit_bank", data.debit_bank || "");
          setValue("reference_id", data.reference_id || "");
          setValue("payment_comment", data.payment_comment || "");
          setValue("amount", data.amount || "");
          setValue("type", data.type || "");
          setValue("received_by", data.received_by || "");
          setValue(
            "next_due_date",
            data.next_due_date
              ? format(new Date(data.next_due_date), "yyyy-MM-dd")
              : ""
          );
          // setValue("payment_screenshot", data.payment_screenshot || null);
          // setPreviewUrl(data.payment_screenshot || ""); 
          
        }
      } catch (error) {
        toast.error("Failed to fetch payment details.");
      } finally {
        // setIsLoading(false);
      }
    };

    fetchSinglePaymentHistory();
  }, [selectedPaymentId, setValue]);

  const onSubmit = async (data) => {
    if (!paymentScreenshot) {
      toast.error("Please upload a screenshot.");
      return;
    }
    // console.log("data",data);

    const formattedData = {
      ...data,

      next_due_date: data.next_due_date
        ? format(new Date(data.next_due_date), "yyyy-MM-dd") // Format the date
        : "",
      id: selectedPaymentId,
    };
    try {
      setIsLoading(true);

      const apiCall = selectedPaymentId
        ? postReqwithFile(formattedData, paymentScreenshot, `/updatePayment`)
        : postReqwithFile(
            formattedData,
            paymentScreenshot,
            "/payments/settlePayment"
          );

      const response = await apiCall;

      // Close the modal on success
      if (response.status === 200 || response.status === 201) {
        toast.success(
          `Payment ${
            selectedPaymentId ? "Settlement Updated" : "Settled"
          } Successfully!`
        );

        reset();
        handleClose();
        setPreviewUrl(null);
        setTimeout(() => {
          onItemAdded();
        }, 1000);
      } else {
        // Show error message for non-200 responses
        toast.error(response.data.message || "payment Not Added", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages
      ) {
        const errorMsg = error.response.data.messages;

        // Display error message in toast
        Object.values(errorMsg).forEach((msg) => {
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
          });
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiDialogContent-root": {
            padding: "24px !important",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            className="border-b border-gray-300"
            sx={{ position: "relative" }}
          >
            <Typography variant="h6">
              {selectedPaymentId ? "Update Payment" : "settle Payment"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={4}>
              {/* User ID */}
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth required variant="outlined">
                  <Controller
                    name="user_id"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Customer Name"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FormatListNumbered />
                            </InputAdornment>
                          ),
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                maxHeight: 250,
                              },
                            },
                          },
                        }}
                        error={!!errors.user_id}
                        helperText={errors.user_id?.message}
                        disabled={selectedPaymentId}
                      >
                        <MenuItem value="" disabled>
                          Select a Customer
                        </MenuItem>

                        {customers.map((customer) => (
                          <MenuItem key={customer.id} value={customer.id}>
                            {customer.user_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Debit Bank */}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="debit_bank"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Debit Bank"
                      placeholder="Enter Debit Bank"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountBalanceIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.debit_bank}
                      helperText={errors.debit_bank?.message}
                      disabled={selectedPaymentId}
                    />
                  )}
                />
              </Grid>

              {/* Reference */}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="reference_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Reference"
                      placeholder="Enter Reference"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ReceiptIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.reference_id}
                      helperText={errors.reference_id?.message}
                      disabled={selectedPaymentId}
                    />
                  )}
                />
              </Grid>

              {/* Payment Comment */}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="payment_comment"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Payment Comment"
                      placeholder="Enter Payment Comment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CommentIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.payment_comment}
                      helperText={errors.payment_comment?.message}
                      disabled={selectedPaymentId}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Amount"
                      placeholder="Enter Amount"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.amount}
                      helperText={errors.amount?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth error={!!errors.type}>
                      <InputLabel id="type-label">Type</InputLabel>
                      <Select
                        {...field}
                        labelId="type-label"
                        label="Type"
                        startAdornment={
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        } 
                        disabled={selectedPaymentId}
                      >
                        <MenuItem value="credit">Credit</MenuItem>
                        <MenuItem value="debit">Debit</MenuItem>
                      </Select>
                      <FormHelperText>{errors.type?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>

              {/* Received By (Always Visible) */}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="received_by"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Received By"
                      placeholder="Enter Receiver's Name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountBalanceIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.received_by}
                      helperText={errors.received_by?.message}
                      disabled={selectedPaymentId}
                    />
                  )}
                />
              </Grid>

              {/* Next Due Date */}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="next_due_date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Next Due Date"
                      placeholder="Select Due Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ReceiptIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.next_due_date}
                      helperText={errors.next_due_date?.message}
                    />
                  )}
                />
              </Grid>

              {/* Payment Screenshot */}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="payment_screenshot"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <label htmlFor="payment_screenshot">
                        <Typography>Upload Screenshot</Typography>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <input
                            {...field} // This binds the field properties to the input
                            type="file"
                            id="payment_screenshot"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleFileChange(e);
                              field.onChange(e); // Ensure the field's onChange is triggered
                            }}
                          />
                          <Button
                            variant="outlined"
                            component="span"
                            startIcon={<FileUploadIcon />}
                          >
                            Choose File
                          </Button>
                          {previewUrl && (
                            <img
                              src={previewUrl}
                              alt="Selected screenshot"
                              style={{
                                width: "70px", // Adjust to fit beside the button
                                height: "70px",
                                objectFit: "cover",
                                borderRadius: "8px", // Optional: Adds rounded corners
                              }}
                            />
                          )}
                        </Box>
                      </label>
                      {paymentScreenshot && (
                        <Typography sx={{ mt: 1 }}>
                          Selected: {paymentScreenshot.name}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className=" md:mr-14px-vw md:mb-10px-vw ">
            <FormButton
              func={handleClose}
              title={"Cancel"}
              customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
            />
            <FormButton
              type="submit"
              func={handleSubmit(onSubmit)}
              title={selectedPaymentId ? "Update Payment" : "Settle Payment"}
            />
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddPaymentModal;

import React, { useEffect, useState } from "react";
import { getReq } from "../../apis/api";
import { toast } from "react-toastify";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";

const TurnoverReport = ({ open, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState(null); 
  const { permissions } = useOutletContext();
  const { roleName } = useSelector((state) => state.role);
  const turnoverReadingPermission =
    permissions?.[roleName]?.modules?.["Reporting"]?.pages?.["Reporting"]
      ?.sections?.["Turnover report"];


  const fetchProductReport = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/turnoverReport");

      if (response.status === 200) {
        setReportData(response.data);
      }
    } catch (error) {
      console.error("Error fetching product report", error);
      toast.error("Error in Fetching Product report");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchProductReport();
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "rgba(255, 255, 255, 0.85)", // Glassmorphism effect
          backdropFilter: "blur(12px)",
          boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.15)",
          p: 4,
          borderRadius: "18px",
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        {/* Close Button */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            bgcolor: "rgba(0, 0, 0, 0.05)",
            "&:hover": { bgcolor: "rgba(0, 0, 0, 0.1)" },
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Title */}
        <Typography
          variant="h5"
          fontWeight="bold"
          gutterBottom
          sx={{
            color: "#1a1a1a",
            fontSize: "24px",
            letterSpacing: "0.5px",
            mb: 3,
          }}
        >
          📊 SGEW Turnover Report
        </Typography>

        {isLoading ? (
          <CircularProgress />
        ) : reportData && turnoverReadingPermission?.read ?   (
          <Grid container spacing={2} justifyContent="center">
            {/* Total Purchase */}
            <Grid item xs={12} sm={4}>
              <Card
                className="h-[10vw] w-[14vw]  flex flex-col items-center justify-center"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 2,
                  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
                  borderRadius: "14px",
                  bgcolor: "#F9FAFB",
                  transition: "transform 0.2s, background 0.3s",
                  "&:hover": { transform: "scale(1.03)", bgcolor: "#F1F5F9" },
                }}
              >
                <ShoppingBagIcon
                  sx={{ fontSize: 42, color: "#1976D2", mr: 2 }}
                />
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    fontWeight="500"
                    color="text.primary"
                  >
                    Total Purchase
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    ₹{reportData.total_purchase}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Total Sales */}
            <Grid item xs={12} sm={4}>
              <Card
                className="h-[10vw] w-[14vw]  flex flex-col items-center justify-center"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 2,
                  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
                  borderRadius: "14px",
                  bgcolor: "#F9FAFB",
                  transition: "transform 0.2s, background 0.3s",
                  "&:hover": { transform: "scale(1.03)", bgcolor: "#F1F5F9" },
                }}
              >
                <TrendingUpIcon
                  sx={{ fontSize: 42, color: "#2E7D32", mr: 2 }}
                />
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    fontWeight="500"
                    color="text.primary"
                  >
                    Total Sales
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    ₹{reportData.total_sales}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Total Other Income */}
            <Grid item xs={12} sm={4}>
              <Card
                className="h-[10vw] w-[14vw] flex flex-col items-center justify-center"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 2,
                  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
                  borderRadius: "14px",
                  bgcolor: "#F9FAFB",
                  transition: "transform 0.2s, background 0.3s",
                  "&:hover": { transform: "scale(1.03)", bgcolor: "#F1F5F9" },
                }}
              >
                <CurrencyRupeeIcon
                  sx={{ fontSize: 42, color: "#F57C00", mr: 2 }}
                />
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    fontWeight="500"
                    color="text.primary"
                  >
                    Other Income
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    ₹{reportData.total_other_income}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body1" color="error">
           You Do Not Have Permission to View This Data
          </Typography>
        )}

        <Divider sx={{ my: 3 }} />

        <Typography variant="body2" color="text.secondary">
          Report generated in real-time. Data is accurate and up to date.
        </Typography>
      </Box>
    </Modal>
  );
};

export default TurnoverReport;

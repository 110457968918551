
import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Grid,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import { getReq } from "../../../apis/api";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toWords } from "number-to-words";

const ServiceInvoice = ({ open, handleClose, ItemId }) => {
  const [invoiceData, setInvoiceData] = useState(null);
  const [settingData, setSettingData] = useState(null); // Separate state for settingData
  const [loading, setLoading] = useState(true);
  const invoiceRef = useRef();

  // Function to fetch invoice and setting data
  const fetchInvoice = async () => {
    try {
      setLoading(true);
      const res = await getReq(`/serviceInvoice/${ItemId}`);

      // Extract the invoice and settingdata from the response
      const invoice = res?.data?.[0]; // Accessing first item in the array

      if (invoice) {
        if (typeof invoice.customer == "string") {
          invoice.customer = JSON.parse(invoice.customer);
        }
      }

      setInvoiceData(invoice);

      console.log("invoice", invoice);

      const settingData = res?.settingdata || []; // Adjusted to look at the top level for settingdata
      setSettingData(settingData);
      console.log("settingData", settingData);
    } catch (error) {
      console.error("Error fetching invoice data:", error);
      toast.error("Error in fetching invoice data");
    } finally {
      setLoading(false);
    }
  };
  const totalAmountWords = invoiceData?.total_amount
    ? toWords(parseFloat(invoiceData.total_amount))
    : "N/A";

  const downloadInvoice = async (invoiceNumber) => {
    if (!invoiceRef.current) return;

    try {
      const canvas = await html2canvas(invoiceRef.current, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      const contentHeight = pdfHeight; // Height of the content (canvas)
      const maxHeight = pdf.internal.pageSize.height;

      let position = 0;

      // If the content height is greater than the page height, add multiple pages
      while (contentHeight > maxHeight) {
        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, maxHeight);
        position += maxHeight;
        // Add a new page if content height exceeds the current page height
        pdf.addPage();
      }

      // Add the remaining content if any
      pdf.addImage(
        imgData,
        "PNG",
        0,
        position,
        pdfWidth,
        contentHeight - position
      );

      // Save the PDF
      pdf.save(`Invoice_${invoiceNumber}.pdf`);
      toast.success("Invoice downloaded successfully!");
      handleClose();
    } catch (error) {
      console.error("Error downloading invoice:", error);
      toast.error("Failed to download invoice.");
    }
  };

  useEffect(() => {
    if (open && ItemId) {
      fetchInvoice();
    }
  }, [open, ItemId]);

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{ boxShadow: "none" }}
    >
      {/* Header */}
      <div ref={invoiceRef}>
        <DialogTitle
          sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.5rem" }}
        >
          Tax Invoice
        </DialogTitle>

        <Divider />

        <DialogContent sx={{ padding: "24px" }}>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="300px"
            >
              <CircularProgress />
            </Box>
          ) : invoiceData ? (
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography variant="body2" fontWeight="bold">
                    Invoice Number:
                  </Typography>
                  <Typography variant="body2">
                    {invoiceData?.service_invoice_number || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" fontWeight="bold">
                    Claim Id:
                  </Typography>
                  <Typography variant="body2">
                    {invoiceData?.claim_id || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" fontWeight="bold">
                    Service Date:
                  </Typography>
                  <Typography variant="body2">
                    {invoiceData?.date
                      ? new Date(invoiceData?.date).toLocaleDateString()
                      : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              {/* Seller and Buyer Information */}
              <Grid container spacing={2}>
                {/* Render SettingData */}
                {settingData && settingData.length > 0 ? (
                  settingData.map((company) => (
                    <Grid item xs={12} sm={4} key={company.id}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        // mb={1}
                        sx={{
                          borderBottom: "2px solid #e0e0e0",
                          paddingBottom: "8px",
                        }}
                      >
                        Company Details
                      </Typography>
                      <Typography variant="body2" fontWeight="bold">
                        {company?.company_name || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        {company?.company_address1 || "N/A"},{" "}
                        {company?.company_address2 || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        {company?.company_phone1 || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        GSTIN/UIN: {company?.gstin_number || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        State: {company?.state || "N/A"}
                      </Typography>
                      <Typography variant="body2">
                        Email: {company?.company_email || "N/A"}
                      </Typography>
                    </Grid>
                  ))
                ) : (
                  <Typography>No company details available.</Typography>
                )}

                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    // mb={1}
                    sx={{
                      borderBottom: "2px solid #e0e0e0",
                      paddingBottom: "8px",
                    }}
                  >
                    Billing
                  </Typography>
                  <Typography variant="body2">
                    {invoiceData?.customer?.billing?.name || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    {invoiceData?.customer?.billing?.address1 || "N/A"},{" "}
                    {invoiceData?.customer?.billing?.address2 || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    GST Number: {invoiceData?.customer?.billing?.gst || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    State: {invoiceData?.customer?.billing?.state || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    Contact: {invoiceData?.customer?.billing?.phone || "N/A"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    // mb={1}
                    sx={{
                      borderBottom: "2px solid #e0e0e0",
                      paddingBottom: "8px",
                    }}
                  >
                    Shipping
                  </Typography>
                  <Typography variant="body2">
                    {invoiceData?.customer?.shipping?.name || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    {invoiceData?.customer?.shipping?.address1 || "N/A"},{" "}
                    {invoiceData?.customer?.shipping?.address2 || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    GST Number: {invoiceData?.customer?.shipping?.gst || "N/A"}
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ my: 1 }} />

              {/* Product Details Table */}
              <Table sx={{ borderCollapse: "collapse", width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S. No.</TableCell>
                    <TableCell align="center">Description of Goods</TableCell>
                    <TableCell align="center">HSN/SAC</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="center">Rate</TableCell>
                    <TableCell align="center">GST</TableCell>
                    <TableCell align="center">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData?.items?.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        {item.item_name || "N/A"}({item.id})
                      </TableCell>
                      <TableCell align="center">
                        {item?.hsn_code || "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {item?.quantity || "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        ₹{parseFloat(item.sell_price).toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        ₹{parseFloat(item.gst_amount).toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        ₹{parseFloat(item.amount).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}

                  {invoiceData.sub_items?.map((subItem, index) => (
                    <TableRow key={`subitem-${index}`}>
                      <TableCell align="center">{index + 2}</TableCell>{" "}
                      {/* Empty for indentation */}
                      <TableCell align="center">
                        {subItem.subitem_name}({subItem.id})
                      </TableCell>
                      <TableCell align="center">{subItem.hsn_code}</TableCell>
                      <TableCell align="center">{subItem.quantity}</TableCell>
                      <TableCell align="center">
                        ₹{parseFloat(subItem.sell_price).toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        ₹{parseFloat(subItem.gst_amount).toFixed(2)}
                      </TableCell>
                      <TableCell align="center">
                        ₹{parseFloat(subItem.amount).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableRow>
                  {/* Taxable Value */}
                  <TableCell
                    colSpan={4}
                    align="right"
                    style={{ fontWeight: "bold" }}
                  >
                    Taxable Value:
                  </TableCell>
                  <TableCell align="right">
                    ₹
                    {parseFloat(
                      invoiceData.total_amount - invoiceData.shipping_amount
                    ).toFixed(2)}
                  </TableCell>

                  {/* Total */}
                  <TableCell align="right" style={{ fontWeight: "bold" }}>
                    Total:
                  </TableCell>
                  <TableCell align="right">
                    ₹{parseFloat(invoiceData.total_amount).toFixed(2)}
                  </TableCell>
                </TableRow>
              </Table>

              <Divider sx={{ my: 1 }} />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <Typography variant="body2" fontWeight="bold">
                    Amount Chargeable (in words):
                  </Typography>
                  <Typography variant="body2" fontStyle="italic">
                    {totalAmountWords}
                  </Typography>
                  <Typography variant="body2" mt={3} fontStyle="italic">
                    "We declare that this invoice shows the actual price of the
                    goods described and that all particulars are true and
                    correct."
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant="body" fontWeight="bold">
                    Company Bank Details
                  </Typography>
                  <Typography variant="body2">
                    <strong>A/C Holder Name:</strong>{" "}
                    {settingData?.[0]?.company_name}
                  </Typography>
                  <Typography variant="body2">
                    <strong> Bank Name:</strong>{" "}
                    {settingData?.[0]?.company_address1}
                  </Typography>
                  <Typography variant="body2">
                    <strong>A/C Number:</strong>{" "}
                    {settingData?.[0]?.bank_account_number}
                  </Typography>

                  <Typography variant="body2">
                    <strong>IFSC Code:</strong> {settingData?.[0]?.IFSC}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Typography variant="body2">No invoice data found.</Typography>
          )}
        </DialogContent>
      </div>
      <DialogActions sx={{ padding: "16px" }}>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => downloadInvoice(invoiceData?.service_invoice_number)}
        >
          Download Invoice
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceInvoice;

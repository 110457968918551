import React, { useState, useEffect } from 'react';
import RolePermissionsTable from './RolePermissionsTable'; // Import your component
import { getReq } from '../../apis/api';

const AllRoleAccess = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('Select'); // Stores the selected role
  const [rolePermissionsData, setRolePermissionsData] = useState({}); // Stores permissions for the selected role
  const [error, setError] = useState(''); // Error state

  // Fetch roles when the component mounts
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getReq("/fetchrole"); // Fetch all roles
        setRoles(response.data); // Set the roles from the response
        setError(''); // Clear any previous errors
      } catch (error) {
        setError("Failed to fetch roles. Please try again later."); // Set error message
      }
    };

    fetchRoles();
  }, []);

  // Monitor rolePermissionsData for changes
  useEffect(() => {
    if (rolePermissionsData) {
      console.log("Updated rolePermissionsData:", rolePermissionsData);
    }
  }, [rolePermissionsData]);
   
  // Handle role change
  const handleRoleChange = async (e) => {
    const selectedRoleValue = e.target.value;
    setSelectedRole(selectedRoleValue);

    try {
      const response = await getReq(`/getPermissionsForRole/${selectedRoleValue}`); // Fetch permissions based on selected role
      console.log(response, "API response");

      if (response.status === 200 && response) {
        console.log("Setting role permissions...");
        setRolePermissionsData(response); // Set only the 'Admin' permissions data
       console.log(rolePermissionsData,"rolepermission data");
        setError(''); // Clear error if successful
      } else {
        setError("No permissions available for this role.");
        setRolePermissionsData(null); // Clear previous permissions data
      }
    } catch (error) {
      setError("Failed to fetch role permissions. Please try again later.");
      setRolePermissionsData(null); // Clear previous permissions data
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-lg font-semibold mb-4">Role Permissions</h1>

      {/* Dropdown for role selection */}
      <div className="mb-4">
        <label className="block text-gray-700 mb-2">Select Role:</label>
        <select
          value={selectedRole}
          onChange={handleRoleChange}
          className="border px-4 py-2 rounded-md focus:outline-none focus:ring"
        >
          <option value="Select" disabled>Select Role</option>
          {roles.map((role) => (
            <option key={role.id} value={role.id}>{role.role_name}</option> // Use role_name from your API response
          ))}
        </select>
      </div>

      {/* Display role permissions if available */}
      {rolePermissionsData ? (
        <RolePermissionsTable permissions={rolePermissionsData} />
      ) : (
        <div>{error || "Select a role to view permissions."}</div>
      )}

      {/* Display error message if there's any */}
      {error && <div className="text-red-500">{error}</div>}
    </div>
  );
};

export default AllRoleAccess;

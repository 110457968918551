import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import Button from "../../components/Button";
import OrderCreateModal from "../../components/purchase/OrderCreate";
import PurchaseOrderTable from "./PurchaseTable";
import { getReq } from "../../apis/api";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
export const AllPurchaseOrders = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [itemsList, setItemsList] = useState([]); // State for items list
  const [purchaseOrders, setPurchaseOrders] = useState([]); // State to store purchase order data
  const [isLoading, setIsLoading] = useState(false);
  const { permissions } = useOutletContext();
  const { roleName } = useSelector((state) => state.role);
  const purchaseOrderPermission =
    permissions?.[roleName]?.modules?.["Purchase Management"]?.pages?.[
      "Manage Purchases"
    ]?.sections?.["Purchase Orders"];

  console.log(purchaseOrderPermission, "purchaseOrderPermission");
  const handleButtonClick = () => {
    setIsModalOpen(true); // Open modal on button click
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close modal function
  };

  const handleSubmitModal = (formData) => {
    // Handle the form submission logic here
    console.log("Form Submitted:", formData);
    handleCloseModal(); // Close modal after submit
  };

  const handleStatusChange = (row) => {
    console.log("Status Change for order:", row.purchaseOrderNumber);
  };

  const handlePayment = (row) => {
    console.log("Payment for order:", row.purchaseOrderNumber);
  };

  const handleCancel = (row) => {
    console.log("Cancel order:", row.purchaseOrderNumber);
  };

  // Fetch purchase orders from API
  useEffect(() => {
    const fetchPurchaseOrders = async () => {
      try {
        setIsLoading(true);
        const response = await getReq("/fetchAllPurchase"); // Fetch data from API
        if (response.status === 200) { 
          const sortedDataDescending = response.data.sort((a, b) => b.id - a.id);
          setPurchaseOrders(sortedDataDescending); // Set the fetched data to state
          setIsLoading(false);
        } else {
          console.error("Error fetching purchase orders:", response.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPurchaseOrders();

    //supplier
    const fetchSuppliers = async () => {
      try {
        const response = await getReq("/fetchSupplier"); // Fetch data from API
        if (response.status === 200) {
          setSuppliers(response.data); // Set the fetched data to state
        } else {
          console.error("Error fetching purchase orders:", response.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSuppliers();
  }, []); // Empty dependency array means it runs once when the component mounts

  // Fetch items for the modal
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await getReq("/fetchItems");
        if (response.status === 200) {
          const fetchedItems = response.data.map((item) => ({
            id: item.item_id,
            name: item.item_name,
            category: item.item_category,
            hsn: item.hsn,
            tag: item.item_tag,
            unit_price: item.unit_price,
            length_mm: item.default_length_mm,
            width_mm: item.default_width_mm,
            thickness_mm: item.default_thickness_mm,
            weight_kg: item.default_weight_kg,
          }));

          setItemsList(fetchedItems);
        } else {
          console.error("Error fetching items:", response.message);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchItems();
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw]">
        <BreadCrumbs title={"Purchase / All Purchase-Orders "} />
        {purchaseOrderPermission?.create && (
          <Button title="Create Purchase " func={handleButtonClick} />
        )}
      </div>
      <main>
        {purchaseOrderPermission?.read && (
          <PurchaseOrderTable
            updatePurchasepermission={purchaseOrderPermission?.update}
            data={purchaseOrders} // Pass the fetched purchase orders data
            onStatusChange={handleStatusChange} // Handle status change
            onPayment={handlePayment} // Handle payment
            onCancel={handleCancel} // Handle cancel order
            suppliers={suppliers}
            itemsList={itemsList}
            isLoading={isLoading}
          />
        )}
      </main>
      <OrderCreateModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        suppliers={suppliers}
        itemsList={itemsList} // Pass the fetched items list to the modal
      />
    </div>
  );
};

export default AllPurchaseOrders;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Typography,
  InputLabel,
  FormControl,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InventoryIcon from "@mui/icons-material/Inventory";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"; // Updated icon
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined"; // Icon for date picker
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { format, parseISO } from "date-fns";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Button, { FormButton } from "../../Button";
import { getReq, postReq } from "../../../apis/api";
import {
  DescriptionOutlined,
  PersonPinCircleOutlined,
  TagOutlined,
  TitleOutlined,
} from "@mui/icons-material";

// Validation schema
const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  user_id: yup.string().required("User Id tag is required"),
  user_type: yup.string().required("User type is required"),
  schedule: yup
    .date()
    .required("Schedule is required")
    .typeError("Please select a valid date and time"),
});

const Add = ({ open, handleClose }) => {
  const [users, setUsers] = useState([]);
  const [userType, setUserType] = useState("");
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      title: "",
      description: "",
      // user_type: "",
      schedule: new Date(),
      user_id: "",
    },
  });

  const fetchUsers = async (type) => {
    try {
      const endpoint =
        type === "dealer"
          ? "/fetchDealer"
          : type === "employees"
          ? "/fetchEmployee"
          : type === "customers"
          ? "/fetchCustomer"
          : type === "suppliers"
          ? "/fetchSupplier"
          : null;

      if (endpoint) {
        const response = await getReq(endpoint);
        setUsers(response.data || []);
      } else {
        setUsers([]);
      }
    } catch (err) {
      toast.error("error in selecting the Users");
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (userType) {
      fetchUsers(userType);
    }
  }, [userType]);

  const onSubmit = async (data) => {
    console.log(data, "data");
    try {
      const response = await postReq(data, "/createNotification");
      if (response.status === 200) {
        toast.success("Notification Sent successfully!");
        reset();
        handleClose();
      } else {
        console.error("Unexpected status:", response.status);
        throw new Error("Failed to create notification");
      }
    } catch (error) {
      toast.error("Error creating notification. Please try again.");
      console.error("API error:", error.response?.data || error.message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "20px",
          boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #e0e0e0",
          paddingBottom: "16px",
        }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <NotificationsActiveIcon color="primary" /> {/* Updated icon */}
          Create Notification
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ px: 4, py: 2 }}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Title"
                placeholder="Enter Title"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TitleOutlined color="primary" /> {/* Updated icon */}
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                error={!!errors.title}
                helperText={errors.title?.message}
                sx={{ mb: 3 }}
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DescriptionOutlined color="primary" />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                multiline
                placeholder="Enter Description"
                rows={3}
                error={!!errors.description}
                helperText={errors.description?.message}
                sx={{ mb: 3 }}
              />
            )}
          />

          <Controller
            name="user_type"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel>User Type</InputLabel>
                <Select
                  value={value}
                  onChange={(e) => {
                    const selectedType = e.target.value;
                    setUserType(selectedType); // Update user type
                    onChange(selectedType);
                  }}
                  error={!!errors.user_type}
                >
                  <MenuItem value="dealer">Dealer</MenuItem>
                  <MenuItem value="employees">Employees</MenuItem>
                  <MenuItem value="customers">Customers</MenuItem>
                  <MenuItem value="suppliers">Suppliers</MenuItem>
                </Select>
                {errors.user_type && (
                  <Typography variant="body2" color="error">
                    {errors.user_type.message}
                  </Typography>
                )}
              </FormControl>
            )}
          />

          <Controller
            name="user_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                select
                label="Select Senders"
                value={value ? value.split(",") : []}
                onChange={(event) => {
                  const selected = event.target.value.join(",");
                  onChange(selected);
                }}
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) =>
                    selected.length ? selected.join(", ") : "Select Senders",
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        maxHeight: 250,
                      },
                    },
                  },
                }}
                fullWidth
                error={!!errors.user_id}
                helperText={errors.user_id?.message}
                sx={{ mb: 3 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InventoryIcon />
                    </InputAdornment>
                  ),
                }}
              >
                {users.map((product) => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.user_name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Controller
            name="schedule"
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDateTimePicker
                  {...field}
                  label="Schedule"
                  disablePast
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder="Select Date & Time"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarTodayOutlined />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.schedule}
                      helperText={errors.schedule?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
        </DialogContent>

        <DialogActions sx={{ px: 4, py: 2, borderTop: "1px solid #e0e0e0" }}>
          <Button
            func={handleClose}
            title="Cancel"
            customcls="bg-gray-100 text-gray-800 border border-gray-300 hover:bg-gray-200"
          />
          <FormButton
            func={handleSubmit(onSubmit)}
            title="Save Notification"
            customcls="bg-blue-500 text-white hover:bg-blue-600"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Add;

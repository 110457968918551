import React, { useState, useEffect } from "react";
import AttendanceDataTable from "../../components/DataTable/AllAttendanceTable";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import Button from "../../components/Button";
import Loader from "../../components/loader/Loading";
import {
  Modal,
  Box,
  Typography,
  Button as MUIButton,
  CircularProgress,
} from "@mui/material";
import { FiUpload } from "react-icons/fi";
import AddVendorAttendance from "./VendorAttendance";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { getReq, postReq, postReqfileupload } from "../../apis/api";
import AllVendorTable from "../../components/DataTable/AllVendorTable";

const AttendanceTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [isVendorOpen, setVendorOpen] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]); // State to store attendance data
  const [activeTab, setActiveTab] = useState("Attendence");

  const moduleTypes = ["Attendence", "Vendor"];

  // Fetch attendance data on component mount
  const fetchAttendanceData = async () => {
    setIsLoading(true);
    try {
      const response = await getReq("/fetchEmpAttendance");
      if (response.status === 200) {
        console.log("response", response.data);
        setAttendanceData(response.data); // Set the fetched data
      } else {
        toast.error("Failed to fetch attendance data.");
      }
    } catch (error) {
      console.error(
        "Error fetching attendance data:",
        error?.response?.data?.message
      );
      const toastmsg = error?.response?.data?.message;
      toast.error(
        toastmsg || "An error occurred while fetching attendance data."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchVendorData = async () => {
    setIsLoading(true);
    try {
      const response = await getReq("/getVendor");
      if (response.status === 200) {
        console.log("response", response.data);
        setAttendanceData(response.data); // Set the fetched data
      } else {
        toast.error("Failed to fetch Vendor data.");
      }
    } catch (error) {
      console.error(
        "Error fetching vendor data:",
        error?.response?.data?.message
      );
      const toastmsg = error?.response?.data?.message;
      toast.error(toastmsg || "An error occurred while fetching vendor data.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAttendanceData();
  }, []);

  // Toggle Vendor Modal
  const handleVendorFormOpenModal = () => setVendorOpen(!isVendorOpen);

  // Toggle Attendance Modal
  const handleOpenModal = () => setOpenAddModal(true);
  const handleCloseModal = () => {
    setOpenAddModal(false);
    setFile(null);
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error("Please select a CSV file to upload.");
      return;
    }
    setUploading(true);

    try {
      const formData = new FormData();
      formData.append("csv_file", file); // Ensure key matches cURL

      const response = await postReqfileupload(formData, "/EmpAttendance");

      if (response.status === 200) {
        toast.success("CSV file uploaded successfully!");

        // Fetch updated data
        const fetchResponse = await getReq("/EmpAttendance");
        setAttendanceData(fetchResponse.data);
      } else {
        toast.error("CSV file upload failed. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("An error occurred during file upload.");
    } finally {
      setUploading(false);
      handleCloseModal();
    }
  };

  const handleTabChange = (moduleType) => {
    setActiveTab(moduleType);
    if (moduleType === "Attendence") {
      fetchAttendanceData();
    } else {
      fetchVendorData();
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Attendance "} />
        <div className="flex items-center justify-center gap-[1vw]">
          <Button title="Add Attendance" func={handleOpenModal} />
          <Button
            title="Vendor Work Payment"
            func={handleVendorFormOpenModal}
          />
        </div>
      </div>

      <div className="flex space-x-35px-vw w-full items-center mb-6 overflow-x-auto">
        {moduleTypes.map((moduleType) => (
          <button
            key={moduleType}
            onClick={() => handleTabChange(moduleType)}
            className={`px-20px-vw py-12px-vw rounded-lg text-[1vw] font-medium transition-all duration-300 ${
              activeTab === moduleType
                ? "bg-blue-500 text-white shadow-lg"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            {moduleType.charAt(0).toUpperCase() + moduleType.slice(1)}
          </button>
        ))}
      </div>
      {isLoading ? (
        <Loader />
      ) : activeTab === "Attendence" ? (
        <AttendanceDataTable attendanceData={attendanceData} />
      ) : (
        <AllVendorTable attendanceData={attendanceData} />
      )}

      {/* Add Attendance Modal */}
      <Modal open={openAddModal} onClose={handleCloseModal}>
        <Box
          className="flex flex-col items-center justify-center gap-5"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            border: "none",
            p: 4,
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            className="text-center font-semibold mb-8"
          >
            Upload Attendance CSV File
          </Typography>
          <div className="flex flex-col items-center w-full gap-5">
            <label
              htmlFor="file-upload"
              className="flex items-center justify-center w-full p-3 border rounded cursor-pointer hover:bg-gray-100"
            >
              <FiUpload className="mr-2 text-gray-600" />
              {file ? file.name : "Select CSV File"}
              <input
                id="file-upload"
                type="file"
                accept=".csv"
                className="hidden"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </label>
            <MUIButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleUpload}
              disabled={uploading}
              startIcon={uploading ? <CircularProgress size={20} /> : null}
            >
              {uploading ? "Uploading..." : "Upload"}
            </MUIButton>
          </div>
        </Box>
      </Modal>

      {/* Add Vendor Attendance Modal */}
      <AddVendorAttendance
        open={isVendorOpen}
        handleClose={handleVendorFormOpenModal}
      />
    </div>
  );
};

export default AttendanceTable;

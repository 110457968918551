import React, { useState } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { BiUniversalAccess } from "react-icons/bi";
import { MdNotificationAdd } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import Add from "../Modal/notification/Add";
const ProfileMenu = ({
  username,
  roleId,
  getGreeting,
  onLogout,
  notificationPermission,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const navigate = useNavigate();

  console.log("roleID", roleId);

  return (
    <div
      className="relative cursor-pointer"
      onMouseEnter={() => setIsModalOpen(true)}
      onMouseLeave={() => setIsModalOpen(false)}
    >
      <div className="flex items-center justify-center bg-white border rounded-full shadow-md w-[2.3vw] h-[2.3vw]">
        <FaUserCircle className="text-gray-500 md:text-[1.6vw] text-[6vw]" />
      </div>

      {isModalOpen && (
        <div className="absolute top-[110%] z-50 right-0 bg-white p-30px-vw w-[18rem] rounded-xl text-gray-800 shadow-lg border border-gray-200">
          <div className="flex items-center mb-16px-vw">
            <div className="bg-blue-600 text-white rounded-full w-12 h-12 flex items-center justify-center font-bold text-xl">
              {username[0]}
            </div>
            <div className="ml-16px-vw">
              <h3 className="text-xl font-semibold">{username}</h3>
              <p className="text-sm text-gray-500 italic">{getGreeting()}</p>
            </div>
          </div>

          <div className="space-y-10px-vw">
            <button
              className="flex items-center w-full p-3 rounded-lg hover:bg-gray-100 transition"
              onClick={() => navigate(`/profile/${roleId}`)}
            >
              <CgProfile size={26} className="text-blue-600 mr-3" />
              <span className="text-[1vw] font-medium">Profile</span>
            </button>

            <button
              className="flex items-center w-full p-3 rounded-lg hover:bg-gray-100 transition"
              onClick={() => navigate(`/settings/${roleId}`)}
            >
              <CiSettings size={26} className="text-blue-600 mr-3" />
              <span className="text-[1vw] font-medium">Settings</span>
            </button>

            {notificationPermission?.create && (
              <button
                className="flex items-center w-full p-3 rounded-lg hover:bg-gray-100 transition"
                onClick={() => setOpenNotificationModal(true)}
              >
                <MdNotificationAdd size={26} className="text-blue-600 mr-3" />
                <span className="text-[1vw] font-medium">
                  Send Notification
                </span>
              </button>
            )}

            {roleId == 1 && (
              <button
                className="flex items-center w-full p-3 rounded-lg hover:bg-gray-100 transition"
                onClick={() => navigate("/access-management")}
              >
                <BiUniversalAccess size={26} className="text-blue-600 mr-3" />
                <span className="text-[1vw] font-medium">
                  Access Management
                </span>
              </button>
            )}

            <button
              className="flex items-center w-full p-3 rounded-lg hover:bg-gray-100 transition text-red-600"
              onClick={onLogout}
            >
              <AiOutlineLogout size={26} className="mr-3" />
              <span className="text-[1vw] font-medium">Logout</span>
            </button>
          </div>
        </div>
      )}
      {openNotificationModal && (
        <Add
          open={openNotificationModal}
          handleClose={() => setOpenNotificationModal(false)}
        />
      )}
    </div>
  );
};

export default ProfileMenu;

import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import FormButton from "../../components/Button";
import { useNavigate, useOutletContext } from "react-router-dom";
import AddPaymentModal from "../../components/Modal/paymentmodal/AddPaymentModal";
import { getReq } from "../../apis/api";
import { toast } from "react-toastify";
import AllPaymentTable from "../../components/DataTable/AllPaymentTable";
import Loader from "../../components/loader/Loading";
import { Modal, Box, Button, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";

const PaymentManagement = () => {
  const [openModal, setOpenModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [file, setFile] = useState(null);
  const { permissions } = useOutletContext();
  const { roleName } = useSelector((state) => state.role);
  const paymentPermission =
    permissions?.[roleName]?.modules?.["Payment Management"]?.pages?.[
      "Payment Management"
    ];

  console.log(paymentPermission, "paymentPermission"); 
  

  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    // Logic for uploading the file (e.g., sending it to the backend API)
    console.log("File to upload:", file);
    toast.success("File uploaded successfully!");
    setOpenModal(false);
    setFile(null);
  };

  const fetchCustomers = async () => {
    try {
      const res = await getReq("/fetchCustomer");
      setCustomers(res.data || []);
    } catch (e) {
      console.error("Error fetching categories:", e);
      toast.error("Error in fetching categories");
    }
  };

  useEffect(() => {
    fetchCustomers();
    fetchPaymentData();
  }, []);

  const fetchPaymentData = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/paymentHistory");

      if (response.status === 200) { 
        const sortedDataDescending = response.data.sort((a, b) => b.id - a.id);
        setPayments(sortedDataDescending);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in Fetching Payment ");
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "UserName",
      selector: (row) => `${row.user_name} [${row.user_id}]`,
      sortable: true,
    },
    {
      name: "Father Name",
      selector: (row) => row.father_name,
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.user_type,
      sortable: true,
      wrap: true,
    },
    {
      name: "Current Balance",
      selector: (row) => row.reference || "N/A",
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home / Payment-management"} />
        <FormButton title="Upload Sheet" func={handleButtonClick} />
      </div>

      <main>
        {isLoading ? (
          <Loader />
        ) : (
          <AllPaymentTable
            paymentPermission={paymentPermission}
            columns={columns}
            data={payments}
            onItemAdded={fetchPaymentData}
            customers={customers}
          />
        )}
      </main>

      {/* MUI Modal for File Upload */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Upload Payment Sheet
          </Typography>
          <TextField
            type="file"
            fullWidth
            onChange={handleFileChange}
            inputProps={{ accept: ".csv, .xlsx" }}
            sx={{ marginBottom: 2 }}
          />
          <div className="flex justify-end gap-4">
            <Button
              variant="contained"
              color="primary"
              onClick={handleFileUpload}
            >
              Upload
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PaymentManagement;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import CategoryIcon from "@mui/icons-material/Category";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loader from "../../loader/Loading";
import { incomeSchema } from "../../../utils/validation";
import FormButton from "../../Button";
import { toast } from "react-toastify";
import { getReq, postReq, putReq } from "../../../apis/api";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            fontSize: "1vw",
          },
          "& .MuiInputLabel-root": {
            fontSize: "1vw",
          },
        },
      },
    },
  },
});

const AddOtherIncome = ({
  open,
  customers,
  handleClose,
  onItemAdded,
  selectedItemId,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(incomeSchema),
    defaultValues: {
      user_id: "",
      income_type: "",
      amount: "",
      date: "",
      description: "",
    },
  });

  useEffect(() => {
    const fetchIncomeData = async () => {
      console.log("selectedItemId", selectedItemId);
      if (!selectedItemId) return;
      try {
        setIsLoading(true);
        const response = await getReq(`/fetchSingleIncome/${selectedItemId}`);
        const data = response.data[0];
        if (response.status === 200) {
          setValue("user_id", data.user_id || "");
          setValue("income_type", data.income_type || "");
          setValue("amount", data.amount || "");
          setValue(
            "date",
            data.date ? format(new Date(data.date), "yyyy-MM-dd") : ""
          );
          setValue("description", data.description || "");
        }
      } catch (error) {
        toast.error("Failed to fetch income details.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchIncomeData();
  }, [selectedItemId, setValue]);

  const onSubmit = async (data) => {
    const formattedData = {
      ...data,
      date: data.date ? format(new Date(data.date), "yyyy-MM-dd") : "",
      id: selectedItemId,
    };

    try {
      setIsLoading(true);
      const apiCall = selectedItemId
        ? postReq(formattedData, `/updateIncome`)
        : postReq(formattedData, "/addIncome");

      const response = await apiCall;

      if (response.status === 200 || response.status === 201) {
        toast.success(
          `Income ${selectedItemId ? "Updated" : "Added"} Successfully!`
        );
        reset();
        handleClose();
        setTimeout(() => {
          onItemAdded();
        }, 1000);
      } else {
        toast.error(response.data.message || "Operation Failed");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiDialogContent-root": {
            padding: "24px !important",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Typography variant="h6">
              {selectedItemId ? "Edit Income" : "Add Income"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth required variant="outlined">
                  <Controller
                    name="user_id"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Customer Name"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CategoryIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.user_id}
                        helperText={errors.user_id?.message}
                      >
                        <MenuItem value="" disabled>
                          Select a Customer
                        </MenuItem>
                        {customers.map((customer) => (
                          <MenuItem key={customer.id} value={customer.id}>
                            {customer.user_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="income_type"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Income Type"
                      placeholder="Enter Income Type"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CategoryIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.income_type}
                      helperText={errors.income_type?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Amount"
                      placeholder="Enter Amount"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.amount}
                      helperText={errors.amount?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Description"
                      placeholder="Enter Description"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DescriptionIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.description}
                      helperText={errors.description?.message}
                    />
                  )}
                />
              </Grid>

              {/*  Date */}
              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Date"
                      placeholder="Select Date"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ReceiptIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.date}
                      helperText={errors.date?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <FormButton
              func={handleClose}
              title="Cancel"
              customcls="bg-[#d0412e33] text-[#D0412E]"
            />
            <FormButton
              type="submit"
              title={selectedItemId ? "Update Income" : "Add Income"}
            />
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddOtherIncome;

import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa"; // Import the checkbox icon
import { getReq } from "../../apis/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CheckboxApproval = () => {
  const [approvals, setApprovals] = useState([]);
  const navigate = useNavigate();

  const fetchApprovals = async () => {
    try {
      const response = await getReq("/fetchApproval");

      if (response.status === 200) {
        setApprovals(response?.data);
      } else {
        setApprovals([]);
      }
    } catch (err) {
      console.log("err", err);
      toast.error("error in fetching the all approvals");
    }
  };

  function handleapprovals(module_type) {
    navigate(`/dashboard/approvals/?module_type=${module_type}`);
  }

  useEffect(() => {
    fetchApprovals();
  }, []);

  return (
    <div className="relative group">
      {/* Checkbox Icon */}
      <div className="flex items-center justify-center bg-white border rounded-full shadow-md cursor-pointer w-[2.3vw] h-[2.3vw]">
        <FaCheck className="text-gray-500 md:text-[1.3vw] text-[6vw]" />
      </div>

      {/* Approval Count Badge */}
      {approvals.length > 0 && (
        <div className="text-xs font-bold rounded-full flex items-center justify-center absolute  w-[1.5vw] h-[1.5vw] -top-[0.26vw] -right-[0.26vw] bg-green-500 text-white">
          {approvals?.length > 99 ? "99+" : approvals?.length}
        </div>
      )}

      {/* Approval List Modal */}
      <div className="absolute right-0 bg-white shadow-lg rounded-lg p-[1vw] z-10 w-[23vw] mt-[0.3vw] hidden group-hover:block">
        <div className="flex items-center justify-between mb-[0.83vw]">
          <h2 className="font-bold text-[1.25vw] text-[#343e53]">
            Approval List
          </h2>
          <span className="text-16px-vw text-gray-500">
            {approvals.length > 0
              ? `You have ${approvals?.length} pending approvals`
              : "No pending approvals"}
          </span>
        </div>

        {approvals.length > 0 ? (
          <div className="min-h-[10vw] max-h-[20vw] overflow-y-auto space-y-[1vw] scroll-smooth custom-scrollbar pr-[0.5vw]">
            {approvals.map((approval) => (
              <div
                key={approval.id}
                className="flex items-center justify-between p-[1vw] border rounded-lg hover:bg-gray-50"
                onClick={() => handleapprovals(approval?.module_type)}
              >
                <div className="flex items-center space-x-[0.5vw]">
                  <div className="flex items-center justify-center bg-gray-100 rounded-full p-[0.7vw]">
                    <FaCheck className="text-green-500 text-[1vw]" />
                  </div>
                  <div>
                    <h3 className="font-semibold text-[1vw]">
                      {approval?.module_type}
                    </h3>
                    <div className="flex items-center text-gray-400 text-[0.73vw]">
                      <span
                        className={`${
                          approval?.status === "pending"
                            ? "text-yellow-500"
                            : approval?.status === "approved"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {approval.status}
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => {
                    // Handle approval action (e.g., approve/reject)
                    console.log("Approval action for:", approval.id);
                  }}
                >
                  <FaCheck className="hover:text-green-500 text-[1vw] text-gray-400" />
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500 text-[0.83vw]">
            No approvals available
          </p>
        )}
      </div>
    </div>
  );
};

export default CheckboxApproval;

import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../Modal/confirmationmodal/ConfirmationModal";
import { logoutSuccess } from "../../slices/authSlice";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { deleteReq, getReq, postReqLogout, searchReq } from "../../apis/api";
import debounce from "lodash.debounce";
import Add from "../Modal/notification/Add";
import { format } from "date-fns";
import HorizontalSidebar from "./Horizontal";
import VerticalSidebar from "./Vertical";
import SearchBar from "./SearchBar";
import NotificationBell from "./NotificationBell";
import ProfileMenu from "./ProfileMenu";
import CheckboxApproval from "./CheckboxApproval";

const Header = ({ permissions }) => {
  const [dropdownData, setDropdownData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const { roleName } = useSelector((state) => state.role);
  const notificationPermission =
    permissions?.[roleName]?.modules?.["Notifications"]?.pages?.[
      "Notifications"
    ]?.sections?.["Notifications"];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userCookie = Cookies.get("user");
  const user = userCookie ? JSON.parse(userCookie) : null;
 
  
  const roleId = user?.role_id;
  const username = user?.username;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const fetchNotification = async () => {
    try {
      const response = await getReq("/fetchNotifications");
      if (response.status === 200) {
        const sortedDataDescending = response.data.sort((a, b) => b.id - a.id);
        setNotifications(sortedDataDescending);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  const handleLogout = async () => {
    const token = Cookies.get("token");
    if (!token) {
      toast.error("No token found. Please log in again.");
      navigate("/login");
      return;
    }
    try {
      const response = await postReqLogout("/logout", token);

      if (response.status === 200) {
        setConfirmationModal(null);
        dispatch(logoutSuccess());
        toast.success("You have successfully logged out!");
        navigate("/login");
      } else {
        toast.error(`Logout failed: ${response.message || "Unknown error"}`);
      }
    } catch (error) {
      toast.error(
        `Error during logout: ${
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred."
        }`
      );
    }
  };

  const handleSearch = async (term) => {
    if (!term.trim()) {
      setDropdownData([]);
      return;
    }

    setLoading(true);
    try {
      const result = await searchReq(`search?term=${term}`);
      const { data, status } = result || {};

      if (status !== 200 || !data) {
        setDropdownData([]);
        return;
      }
      setDropdownData(data);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setDropdownData([]);
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useRef(debounce(handleSearch, 500)).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good Morning!";
    } else if (currentHour < 16) {
      return "Good Afternoon!";
    } else if (currentHour < 18) {
      return "Good Evening!";
    } else {
      return "Good Night!";
    }
  };

  const handleNavigate = (id) => {
    if (id.startsWith("SINV")) {
      navigate("/sales-service");
    } else if (id.startsWith("SGEWU")) {
      navigate("/users");
    } else if (id.startsWith("POI")) {
      navigate("/purchases");
    } else {
      console.error("Unknown ID:", id);
    }
  };

  const handleCloseNotification = async (id) => {
    try {
      const response = await deleteReq(`/deleteNotification/${id}`);
      if (response.status === 200) {
        toast.success("Notification Deleted Successfully");
        setNotifications(
          notifications.filter((notification) => notification.id !== id)
        );
      } else {
        toast.error("Failed to delete notification");
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <div className="flex flex-col relative">
      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
      <div className="fixed top-0 z-30 border-[1px] bg-[#f9f9fb] border-b-gray-300 flex flex-row items-center justify-between px-[1vw] h-[70px] w-full">
        <div className="md:w-[8vw] w-[20vw] border-r-[2px] md:border-r-[1px] h-full flex items-center justify-center">
          <Link to="/">
            <div className="w-[15vw] md:w-[4vw]">
              <img
                src={logo}
                alt="logo"
                className="w-full h-auto object-cover"
              />
            </div>
          </Link>
        </div>
        <div className="w-[85vw] flex px-[1vw] justify-between">
          <div className="md:block hidden">
            <HorizontalSidebar />
          </div>
          <div className="flex items-center justify-center">
            <div className="md:hidden block">
              <button
                onClick={toggleMenu}
                className="flex flex-col justify-center items-center w-8 h-8 space-y-[1.5vw] focus:outline-none"
              >
                <div
                  className={`${
                    isOpen ? "hidden" : "block"
                  } w-6 h-[.4vw] bg-black transition-all duration-300`}
                ></div>
                <div
                  className={`${
                    isOpen ? "hidden" : "block"
                  } w-6 h-[.4vw] bg-black transition-all duration-300`}
                ></div>
                <div
                  className={`${
                    isOpen ? "hidden" : "block"
                  } w-6 h-[.4vw] bg-black transition-all duration-300`}
                ></div>
                <div
                  className={`${
                    isOpen ? "block" : "hidden"
                  } w-6 h-6 border-t-2 border-r-2 scale-75 border-black transform rotate-45 transition-all duration-300`}
                ></div>
              </button>
            </div>
          </div>
          <div className="flex gap-x-2 items-center justify-center">
            <SearchBar
              onSearch={debouncedSearch}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              dropdownData={dropdownData}
              loading={loading}
              onNavigate={handleNavigate}
            />
            <NotificationBell
              notificationPermission={notificationPermission}
              notifications={notifications}
              onClose={handleCloseNotification}
            />
            <CheckboxApproval />
            <ProfileMenu
              notificationPermission={notificationPermission}
              username={username}
              roleId={roleId}
              getGreeting={getGreeting}
              onLogout={handleLogout}
            />
          </div>
        </div>
      </div>

      <div className="md:block hidden">
        <HorizontalSidebar />
      </div>
      <div
        className={`fixed top-0 left-0 h-full w-[250px] bg-gray-300 text-white transition-transform duration-500 ease-in-out transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <VerticalSidebar />
      </div>
    </div>
  );
};

export default Header;

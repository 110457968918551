// import React, { useState, useMemo } from "react";
// import DataTable from "react-data-table-component";
// import { HiX } from "react-icons/hi";
// import { useNavigate } from "react-router-dom";

// const Estimatedreporttable = ({ columns, data, itemstock, itemtable }) => {
//   const [filterText, setFilterText] = useState("");
//   const [globalSearchText, setGlobalSearchText] = useState("");
//   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
//   const navigate = useNavigate();
//   const [selectedColumn, setSelectedColumn] = useState("name");

//   const allowedFilterColumns = [
//     { name: "Item Name", selector: "name" },
//     { name: "Quantity", selector: "quantity" },
//   ];

//   const getColumnValue = (row, selector) => {
//     if (typeof selector === "function") {
//       return selector(row);
//     }
//     return row[selector] || 0;
//   };

//   const filteredData = data.filter((item) => {
//     const column = allowedFilterColumns.find(
//       (col) => col.name === selectedColumn
//     );

//     if (!column) {
//       return true;
//     }

//     const valueToFilter = getColumnValue(item, column.selector)
//       .toString()
//       .toLowerCase();
//     return valueToFilter.includes(filterText.toLowerCase());
//   });

//   const handleClear = () => {
//     if (filterText) {
//       setResetPaginationToggle(!resetPaginationToggle);
//       setFilterText("");
//     }
//   };

//   const globallyFilteredData = filteredData.filter((item) => {
//     const ItemsName = item.product_name?.toLowerCase() || "";
//     return ItemsName.includes(globalSearchText.toLowerCase());
//   });

//   const subHeaderComponent = (
//     <div className="flex justify-between bg-white text-[#666666] items-center my-2 relative">
//       <div className="relative">
//         <input
//           className="border p-2 pr-10 bg-white rounded focus:outline-none placeholder:text-sm mr-4"
//           type="text"
//           placeholder="Search Name..."
//           value={globalSearchText}
//           onChange={(e) => setGlobalSearchText(e.target.value)}
//         />
//         {globalSearchText && (
//           <HiX
//             className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer h-6 w-6 text-gray-500"
//             onClick={() => setGlobalSearchText("")}
//           />
//         )}
//       </div>

//       <select
//         className="border bg-white focus:outline-none p-2 rounded text-sm mr-4"
//         value={selectedColumn}
//         onChange={(e) => {
//           setSelectedColumn(e.target.value);
//           setFilterText("");
//         }}
//       >
//         {allowedFilterColumns.map((col) => (
//           <option key={col.selector} value={col.name}>
//             {col.name}
//           </option>
//         ))}
//       </select>

//       <div className="relative w-full">
//         <input
//           className="border bg-white p-2 pr-10 focus:outline-none rounded placeholder:text-sm w-full"
//           type="text"
//           placeholder={`Filter by ${selectedColumn}`}
//           value={filterText}
//           onChange={(e) => setFilterText(e.target.value)}
//         />
//         {filterText && (
//           <HiX
//             className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer h-6 w-6 text-gray-500"
//             onClick={handleClear}
//           />
//         )}
//       </div>
//     </div>
//   );

//   return (
//     <div className="font-poppins">
//       {itemstock && (
//         <div className="text-start py-3 flex items-start gap-20px-vw rounded-md my-5 px-5">
//           <h3 className="font-semibold text-lg">
//             {itemtable
//               ? `Item Stock :${itemstock.current_item_stock}`
//               : `Subitem Stock :${itemstock.current_subitem_stock}`}
//           </h3>
//           <div className="font-semibold text-lg">Requirement:N/A</div>
//         </div>
//       )}
//       <div className="overflow-hidden bg-white rounded-md">
//         <DataTable
//           columns={columns}
//           data={globallyFilteredData}
//           pagination
//           subHeader
//           subHeaderComponent={subHeaderComponent}
//           paginationResetDefaultPage={resetPaginationToggle}
//           persistTableHead
//           customStyles={{
//             rows: {
//               style: {
//                 minHeight: "60px",
//                 fontSize: "0.9vw",
//               },
//             },
//             headCells: {
//               style: {
//                 fontWeight: 600,
//                 textAlign: "center",
//                 whiteSpace: "normal",
//                 fontSize: "1vw",
//                 color: "#10131e",
//               },
//             },
//           }}
//         />
//         {/* Total row at the bottom */}
//         <div className="flex items-end justify-end gap-x-60px-vw p-4 border-t text-18px-vw  text-gray-800 font-semibold">
//           <span>
//             {itemtable
//               ? `Total Item :${itemstock?.total_item}`
//               : `Total Subitem :${itemstock?.total_subitem}`}
//           </span>
//           <span>
//             Total Quantity:
//             {itemtable
//               ? itemstock?.total_quantity
//               : itemstock?.total_subquantity}{" "}
//           </span>
//           <span>
//             Total Weight Per Unit:{" "}
//             {itemtable ? itemstock?.total_weight : itemstock?.total_subweight}{" "}
//           </span>
//           <span>
//             Total SGEW Price:{" "}
//             {itemtable
//               ? itemstock?.total_sgew_price
//               : itemstock?.total_subsgew_price}
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Estimatedreporttable;
import React, { useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { HiX } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const EstimatedReportTable = ({
  columns,
  data,
  itemstock,
  itemtable,
  requirement,
}) => {
  const [filterText, setFilterText] = useState("");
  const [globalSearchText, setGlobalSearchText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const navigate = useNavigate();
  const [selectedColumn, setSelectedColumn] = useState("name");

  const allowedFilterColumns = [
    { name: "Item Name", selector: "name" },
    { name: "Quantity", selector: "quantity" },
  ];

  const getColumnValue = (row, selector) => {
    return typeof selector === "function" ? selector(row) : row[selector] || 0;
  };

  const filteredData = data.filter((item) => {
    const column = allowedFilterColumns.find(
      (col) => col.name === selectedColumn
    );
    if (!column) return true;
    const valueToFilter = getColumnValue(item, column.selector)
      .toString()
      .toLowerCase();
    return valueToFilter.includes(filterText.toLowerCase());
  });

  const globallyFilteredData = filteredData.filter((item) => {
    const ItemsName = item.product_name?.toLowerCase() || "";
    return ItemsName.includes(globalSearchText.toLowerCase());
  });

  const handleClear = () => {
    setFilterText("");
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const itemtotal = requirement * itemstock?.total_item;
  const subitemtotal = requirement * itemstock?.total_subitem;

  const subHeaderComponent = (
    <div className="flex flex-wrap justify-between bg-gray-100 text-gray-700 p-4 rounded-lg shadow-md">
      <div className="relative w-1/3">
        <input
          className="border p-2 pr-10 bg-white rounded focus:outline-none placeholder:text-sm w-full shadow-sm"
          type="text"
          placeholder="Search Name..."
          value={globalSearchText}
          onChange={(e) => setGlobalSearchText(e.target.value)}
        />
        {globalSearchText && (
          <HiX
            className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer h-6 w-6 text-gray-500 hover:text-gray-700"
            onClick={() => setGlobalSearchText("")}
          />
        )}
      </div>

      <select
        className="border bg-white focus:outline-none p-2 rounded text-sm shadow-sm"
        value={selectedColumn}
        onChange={(e) => {
          setSelectedColumn(e.target.value);
          setFilterText("");
        }}
      >
        {allowedFilterColumns.map((col) => (
          <option key={col.selector} value={col.name}>
            {col.name}
          </option>
        ))}
      </select>

      <div className="relative w-1/3">
        <input
          className="border bg-white p-2 pr-10 focus:outline-none rounded placeholder:text-sm w-full shadow-sm"
          type="text"
          placeholder={`Filter by ${selectedColumn}`}
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        {filterText && (
          <HiX
            className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer h-6 w-6 text-gray-500 hover:text-gray-700"
            onClick={handleClear}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="font-poppins p-6 bg-gray-50 rounded-xl shadow-lg">
      {itemstock && (
        <div className="text-start py-3 flex items-center gap-4 bg-white rounded-md shadow-sm p-4">
          <h3 className="font-semibold text-lg text-gray-800">
            {itemtable
              ? `Item Stock: ${itemstock.current_item_stock}`
              : `Subitem Stock: ${itemstock.current_subitem_stock}`}
          </h3>
          <div className="font-semibold text-lg text-gray-600">
            Requirement: {itemtable ? itemtotal : subitemtotal}{" "}
          </div>
        </div>
      )}
      <div className="overflow-hidden bg-white rounded-lg shadow-lg">
        <DataTable
          columns={columns}
          data={globallyFilteredData}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponent}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
          customStyles={{
            rows: {
              style: {
                minHeight: "60px",
                fontSize: "1rem",
                textAlign: "center",
              },
            },
            headCells: {
              style: {
                fontWeight: 600,
                fontSize: "1.1rem",
                color: "#333",
                backgroundColor: "#f3f4f6",
                padding: "12px",
              },
            },
          }}
        />
        {/* Total row at the bottom */}
        <div className="flex justify-between p-4 border-t bg-gray-100 text-gray-900 font-semibold text-lg rounded-b-lg">
          <span>
            {itemtable
              ? `Total Item: ${itemstock?.total_item}`
              : `Total Subitem: ${itemstock?.total_subitem}`}
          </span>
          <span>
            Total Quantity:{" "}
            {itemtable
              ? itemstock?.total_quantity
              : itemstock?.total_subquantity}
          </span>
          <span>
            Total Weight Per Unit:{" "}
            {itemtable ? itemstock?.total_weight : itemstock?.total_subweight}
          </span>
          <span>
            Total SGEW Price:{" "}
            {itemtable
              ? itemstock?.total_sgew_price
              : itemstock?.total_subsgew_price}
          </span>
        </div>
      </div>
    </div>
  );
};

export default EstimatedReportTable;

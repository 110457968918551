import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import FormButton from "../../components/Button";
import { useNavigate, useOutletContext } from "react-router-dom";
import AddCategory from "../../components/Modal/productmodal/AddCategory";
import AddDealer from "../../components/Modal/ordermodal/AddDealer";
import { toast } from "react-toastify";
import { getReq } from "../../apis/api";
import Loader from "../../components/loader/Loading";
import AllDealersTable from "../../components/DataTable/AllDealersTable";
import { useSelector } from "react-redux";

const DealerManagement = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const { permissions } = useOutletContext();
  const { roleName } = useSelector((state) => state.role);
  const dealerPermission =
    permissions?.[roleName]?.modules?.["Sales and Service Management"]?.pages?.[
      "Dealer Order Requests"
    ]?.sections?.["Dealer Order Requests"];

  console.log("warrentypermission", dealerPermission);

  const fetchOrderData = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/fetchDealerOrders");

      if (response.status === 200) {
        const sortedDataDescending = response.data.sort((a, b) => b.id - a.id);
        setOrders(sortedDataDescending);
      }
    } catch (error) {
      console.error("Error:", error);

      // Handle the error response and display the backend message
      const errorMessage =
        error.response?.data?.message || // Backend message
        "Something went wrong. Please try again."; // Fallback message

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Date",
      selector: (row) => row.date || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.dealer_name || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Products",
      selector: (row) => row.product_name,
      sortable: true,
    },

    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.note || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Status",
      selector: (row) => row.status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.status === "Approved"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.status === "Pending"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.status || "N/A"}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
  ];

  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Sales-Services / Dealer Orders"} />
        {dealerPermission?.create && (
          <FormButton title="Create" func={handleButtonClick} />
        )}
      </div>

      {openModal && (
        <AddDealer
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={"Add Dealer Order"}
          onItemAdded={fetchOrderData}
        />
      )}

      <main>
        {isLoading ? (
          <Loader />
        ) : dealerPermission?.read ? (
          <AllDealersTable
            columns={columns}
            data={orders}
            onItemAdded={fetchOrderData}
          />
        ) : (
          <p className="text-gray-500 md:py-20px-vw md:text-[1.2vw] text-center font-bold">
            You do not have permission to view this data.
          </p>
        )}
      </main>
    </div>
  );
};

export default DealerManagement;

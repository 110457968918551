import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import FormButton from "../../components/Button";
import Loader from "../../components/loader/Loading";
import AllPaymentTable from "../../components/DataTable/AllPaymentTable";
import { getReq } from "../../apis/api";
import { toast } from "react-toastify";
import Otherincometable from "../../components/DataTable/Otherincometable";
import AddOtherIncome from "../../components/Modal/paymentmodal/AddOtherIncome";

const Otherincome = () => {
  const [openModal, setOpenModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);

  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };

  const fetchCustomers = async () => {
    try {
      const res = await getReq("/fetchAllUsers");
      setCustomers(res.data || []);
    } catch (e) {
      console.error("Error fetching categories:", e);
      toast.error("Error in fetching categories");
    }
  };

  useEffect(() => {
    fetchCustomers();
    fetchPaymentData();
  }, []);

  const fetchPaymentData = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/fetchIncome");

      if (response.status === 200) { 
        const sortedDataDescending = response.data.sort((a, b) => b.id - a.id);
        setPayments(sortedDataDescending);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in Fetching Payment ");
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },

    {
      name: "User Id",
      selector: (row) => row.user_id,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Income Type",
      selector: (row) => row.income_type,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      wrap: true,
    },
  ];
  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home / Other Income"} />
        <FormButton title="Add Income" func={handleButtonClick} />
      </div>

      <main>
        {isLoading ? (
          <Loader />
        ) : (
          <Otherincometable
            columns={columns} 
            data={payments}
            onItemAdded={fetchPaymentData}
            customers={customers}
          />
        )}
      </main>

      {openModal && (
        <AddOtherIncome
          open={openModal}
          handleClose={handleButtonClick}
          customers={customers}
          onItemAdded={fetchPaymentData}
        />
      )}
    </div>
  );
};

export default Otherincome;

import ProtectedRoute from "./ProtectedRoute"; // Assuming it's in the same directory
import Login from "../pages/login";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "../pages/home/Home"; // Make sure this path is correct
import Dashboard from "../pages/dashboard";
import PageNotFound from "../pages/pageNotFound/Index";
import User from "../pages/userManagement";
import Landingpage from "../pages/landingpage/Landingpage";
import Profile from "../pages/profile/Profile";
import UserDetails from "../pages/userManagement/UserDetails";
import UserTable from "../pages/userManagement/UserTable";
import Access from "../pages/access/Index";
import AllRoleAccess from "../pages/access/AllRoleAccess";
import Setting from "../pages/profile/Setting";
import Cookies from "js-cookie";
import Items from "../pages/items/index";
import Products from "../pages/products/index";
import ManageCategory from "../pages/items/ManageCategory";
import AllItems from "../pages/items/AllItems";
import AllSubItems from "../pages/subItems/AllSubItems";

import SessionTimeout from "../components/sessionScreen/SessionTimeout";
import Index from "../pages/purchase";
import AllPurchaseOrders from "../pages/purchase/AllPurchaseOrders";
import PurchaseOrderForm from "../pages/purchase/ViewAndEdit";
import Category from "../pages/products/Category";
import Management from "../pages/products/Management";
import CreateProduct from "../pages/products/CreateProduct";
import Orders from "../pages/orders/index";
import Payment from "../pages/payment/index";
import OrderManagement from "../pages/orders/OrderManagement";
import CreateOrder from "../pages/orders/CreateOrder";
import DealerManagement from "../pages/orders/DealerManagement";
import PaymentManagement from "../pages/payment/PaymentManagement";
import Attendance from "../pages/attendence";
import AttendanceTable from "../pages/attendence/AttendanceTable";
import TransactionMain from "../components/DataTable/TransactionHistoryTable";
import Otherincome from "../pages/payment/Otherincome";
import ServiceManagement from "../pages/orders/ServiceManagement";
import CreateService from "../pages/orders/CreateService";
import WarrantyManagement from "../pages/orders/WarrantyManagement";
import Assets from "../pages/assets";
import AssetsTable from "../pages/assets/AssetsTable";
import Allreporting from "../pages/reporting/Allreporting";
import MainDashboard from "../pages/dashboard/MainDashboard";
import Productperformance from "../pages/reporting/Productperformance";
import Estimatedreport from "../pages/reporting/Estimatedreport";
import Purchaseperformance from "../pages/reporting/Purchaseperformance";
import Paymentdue from "../pages/reporting/Paymentdue";
import Allapprovals from "../pages/approvals/Allapprovals";
import EmployeesReport from "../pages/employeereport/EmployeesReport";

const AppRoutes = () => {
  const token = Cookies.get("token");

  return (
    <div className="m-0 p-0 bg-[#f9f9fb]">
      <ToastContainer />
      <Routes>
        <Route path="/" element={token ? <HomePage /> : <Landingpage />} />
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<ProtectedRoute element={<HomePage />} />}>
          <Route path="dashboard" element={<Dashboard />}>
            {/* Nested route */}
            <Route path="" element={<MainDashboard />} />

            <Route path="reporting" element={<Allreporting />} /> 
            <Route path="approvals" element={<Allapprovals />} />
            <Route
              path="reporting/product-performance"
              element={<Productperformance />}
            />

            <Route
              path="reporting/product-report"
              element={<Estimatedreport />}
            />
            <Route
              path="reporting/purchase-performance"
              element={<Purchaseperformance />}
            />
            <Route
              path="reporting/payment-due-report"
              element={<Paymentdue />}
            />

            <Route path="missed-delivery" element={<PageNotFound />} />
            <Route path="latest-orders" element={<PageNotFound />} />
          </Route>
          <Route path="users" element={<User />}>
            {/* Nested route */}
            <Route path="" element={<UserTable />} />
            <Route path="users-list" element={<UserTable />} />

            <Route path="users-profile" element={<PageNotFound />} />
          </Route>
          <Route path="access-management" element={<Access />}>
            {/* Nested route */}
            <Route path="" element={<AllRoleAccess />} />
          </Route>

          <Route path="purchases" element={<Index />}>
            {/* Nested route */}
            <Route path="" element={<AllPurchaseOrders />} />
            <Route path="purchase-order" element={<AllPurchaseOrders />} />
            <Route
              path="edit-purchase-order/:id"
              element={<PurchaseOrderForm />}
            />
          </Route>
          <Route path="attendance" element={<Attendance />}>
            {/* Nested route */}
            <Route path="" element={<AttendanceTable />} />  
            <Route path="employee-report" element={<EmployeesReport />} /> 


            {/* <Route
              path="edit-purchase-order/:id"
              element={<PurchaseOrderForm />}
            /> */}
          </Route>

          <Route path="items" element={<Items />}>
            {/* Nested route */}
            <Route path="" element={<AllItems />} />
            <Route path="assets" element={<Assets />}>
              {/* Nested route */}
              <Route path="" element={<AssetsTable />} />
              <Route
                path="edit-purchase-order/:id"
                element={<PurchaseOrderForm />}
              />
            </Route>
            <Route path="categories" element={<ManageCategory />} />
            <Route path="subitems" element={<AllSubItems />} />
          </Route>

          {/* product route */}
          <Route path="manufacturing" element={<Products />}>
            {/* Nested route */}
            <Route path="" element={<Management />} />
            <Route path="add-product" element={<Category />} />
            <Route path="products-management" element={<Management />} />
            <Route path="create-product" element={<CreateProduct />} />
            <Route path="edit-product/:productId" element={<CreateProduct />} />
          </Route>

          {/* order routes  */}
          <Route path="sales-service" element={<Orders />}>
            {/* Nested route */}
            <Route path="" element={<OrderManagement />} />
            <Route path="dealer-order" element={<DealerManagement />} />
            <Route path="manage-orders" element={<OrderManagement />} />
            <Route path="create-order" element={<CreateOrder />} />
            <Route path="edit-order/:orderId" element={<CreateOrder />} />
            <Route path="create-quotation/:orderId" element={<CreateOrder />} />
            <Route path="product-service" element={<ServiceManagement />} />
            <Route path="create-service" element={<CreateService />} />
            <Route path="edit-service/:serviceId" element={<CreateService />} />
            <Route path="manage-warranty" element={<WarrantyManagement />} />
          </Route>

          <Route path="payment" element={<Payment />}>
            {/* Nested route */}
            <Route path="" element={<PaymentManagement />} />

            <Route
              path="payment-history/:paymentId"
              element={<TransactionMain />}
            />

            <Route path="income-management" element={<Otherincome />} />
            {/* <Route path="products-management" element={<Management />} /> */}
            {/* <Route path="create-product" element={<CreateProduct />} /> */}
            {/* <Route path="edit-product/:productId" element={<CreateProduct />} /> */}
          </Route>

          {/* profile Route  */}
          <Route path="users/user-detail/:id" element={<UserDetails />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/settings/:id" element={<Setting />} />
        </Route>

        {/* profile route  */}
        <Route path="/session-timeout" element={<SessionTimeout />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};
export default AppRoutes;

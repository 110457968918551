import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Grid,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import InventoryIcon from "@mui/icons-material/Inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../Button";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { postReq, getReq } from "../../../apis/api";

// Validation schema
const schema = yup.object({
  employee_id: yup.string().required("Employee is required."),
  assets: yup
    .array()
    .of(
      yup.object().shape({
        asset_id: yup.string().required("Asset is required."),
        quantity: yup
          .number()
          .required("Quantity is required.")
          .positive("Quantity must be positive.")
          .integer("Quantity must be an integer."),
      })
    )
    .min(1, "At least one asset-quantity pair is required."),
  date: yup.date().required("Date is required."),
});

export const EditAssetModal = ({ open, handleClose, userId }) => {
  const [employees, setEmployees] = useState([]);
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      employee_id: "",
      assets: [],
      date: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "assets",
  });

  // Fetch employees
  const fetchEmployees = async () => {
    try {
      const res = await getReq("/vendorEmployee");
      setEmployees(res.data || []);
    } catch (error) {
      toast.error("Error fetching employees.");
    }
  };

  // Fetch assets
  const fetchAssets = async () => {
    try {
      const res = await getReq("/assetsItem");
      setAssets(res.data || []);
    } catch (error) {
      toast.error("Error fetching assets.");
    }
  };

  // Fetch existing data for autofill
  const fetchData = async () => {
    try {
      const res = await getReq(`/userAssets/${userId}`);
      const fetchedData = res.data || [];

      if (fetchedData.length > 0) {
        const { user_name, date } = fetchedData[0];
        const transformedAssets = fetchedData.map((item) => ({
          asset_id: item.assets_id,
          quantity: parseInt(item.assets_qty, 10),
        }));

        // Reset the form with fetched data
        reset({
          employee_id: user_name,
          date: date,
          assets: transformedAssets,
        });
      } else {
        reset();
      }
    } catch (error) {
      toast.error("Error fetching user assets data.");
    }
  };

  useEffect(() => {
    if (open) {
      fetchEmployees();
      fetchAssets();
      fetchData();
    }
  }, [open]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const formattedDate = new Date(data.date)
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-");
      const payload = {
        user_id: data.employee_id,
        assets_id: data.assets.map((asset) => asset.asset_id).join(","),
        assets_qty: data.assets.map((asset) => asset.quantity).join(","),
        date: formattedDate,
      };

      const response = await postReq(payload, "/assignAssets");

      if (response.status === 200) {
        setIsLoading(false);
        toast.success("Assets assign request sent successfully to admin");
        reset();
        handleClose();
      }
    } catch (error) {
      toast.error("Error assigning assets.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      sx={{
        "& .MuiDialogContent-root": {
          padding: "24px !important",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h6">Edit Asset Assignment</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          {/* Employee */}
          <Grid item xs={12} md={5}>
            <Controller
              name="employee_id"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Select Employee"
                  fullWidth
                  error={!!errors.employee_id}
                  helperText={errors.employee_id?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                >
                  {employees.map((employee) => (
                    <MenuItem key={employee.id} value={employee.user_name}>
                      {employee.user_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          {/* Date */}
          <Grid item xs={12} md={5}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  label="Date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.date}
                  helperText={errors.date?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InventoryIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          {/* Assets */}
          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <Grid item xs={12} md={5}>
                <Controller
                  name={`assets.${index}.asset_id`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Select Asset"
                      fullWidth
                      error={!!errors.assets?.[index]?.asset_id}
                      helperText={errors.assets?.[index]?.asset_id?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <InventoryIcon />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {assets.map((asset) => (
                        <MenuItem key={asset.id} value={asset.id}>
                          {asset.item_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Controller
                  name={`assets.${index}.quantity`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      label="Quantity"
                      fullWidth
                      error={!!errors.assets?.[index]?.quantity}
                      helperText={errors.assets?.[index]?.quantity?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <InventoryIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <IconButton
                  aria-label="delete"
                  onClick={() => remove(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <Button
              func={() => append({ asset_id: "", quantity: "" })}
              title="Add Asset"
              customcls="bg-[#1976D2] text-white"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          func={handleClose}
          title="Cancel"
          customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
        />
        <Button
          func={handleSubmit(onSubmit)}
          title="Update"
          isLoading={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditAssetModal;

import React from "react";

const ViewImages = ({ open, handleClose, imageUrls }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-3xl w-full">
        <h2 className="text-lg font-bold mb-4 text-center">Images</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {imageUrls.map((url, index) => (
            <div key={index} className="flex items-center justify-center">
              <img
                src={url}
                alt={`Issue Img ${index + 1}`}
                className="w-full max-w-[400px] h-auto object-cover rounded shadow-lg"
              />
            </div>
          ))}
        </div>
        <button
          onClick={handleClose}
          className="mt-6 px-6 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ViewImages;

import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Dialog,
  DialogTitle,
  IconButton,
  FormControl,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
// MUI Icons
import {
  Close as CloseIcon,
  AccountCircle,
  Code,
  Category,
  Warning,
  Straighten,
  Scale,
  PriceChange,
  AttachMoney,
} from "@mui/icons-material";
import { postReq } from "../../../apis/api";
import CustomButton from "../../button/CustomButton";
import Button from "../../Button";

// Validation schema using Yup
const validationSchema = yup.object().shape({
  subitem_name: yup.string().required("Subitem name is required"),

  hsn_code: yup.string().required("HSN code is required"),

  min_stock_alert: yup.number().required("Min stock alert is required"),
  default_length_mm: yup.number().required("Length is required"),
  default_width_mm: yup.number().required("Width is required"),
  default_thickness_mm: yup.number().required("Thickness is required"),
  default_weight_kg: yup.number().required("Weight is required"),

  processing_fees: yup.number().required("Processing fees are required"),

  scrap_weight_kg: yup.number().required("Scrap weight is required"),
});

const AddSubItemModal = ({ open, handleClose, categories }) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      subitem_name: "",
      hsn_code: "",
      min_stock_alert: "",
      default_length_mm: "",
      default_width_mm: "",
      default_thickness_mm: "",
      default_weight_kg: "",
      processing_fees: "",
      scrap_weight_kg: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await postReq(data, "/subitems");
      console.log("Response:", response.data);

      // Close the modal on success
      if (response.status === 201) {
        toast.success("SubItem Created successfully!");

        reset();
        handleClose();
      } else {
        // Show error message for non-200 responses
        toast.error(response.data.message || "User Not Created", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      // Handling errors and displaying the appropriate error message
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages
      ) {
        const errorMsg = error.response.data.messages;

        // Display error message in toast
        Object.values(errorMsg).forEach((msg) => {
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
          });
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              fontSize: "1vw",
            },
            "& .MuiInputLabel-root": {
              fontSize: "1vw",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={(_, reason) => reason !== "backdropClick" && handleClose()}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="border-b border-gray-300">
          Create SubItem
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ padding: "16px" }}
          className="mt-[3vh]"
        >
          <Grid container spacing={3.5}>
            {/* Subitem Name */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="subitem_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Subitem Name"
                    placeholder="Enter subitem name"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.subitem_name}
                    helperText={errors.subitem_name?.message}
                  />
                )}
              />
            </Grid>

            {/* HSN Code */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="hsn_code"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="HSN Code"
                    placeholder="Enter HSN code"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Category />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.hsn_code}
                    helperText={errors.hsn_code?.message}
                  />
                )}
              />
            </Grid>

            {/* Item Category */}
            {/* <Grid item xs={12} sm={6} md={3}>
  <FormControl fullWidth required variant="outlined">
    <Controller
      name="item_category_id"
      control={control}
      defaultValue="" // Default value for item_category_id
      render={({ field }) => (
        <TextField
          {...field}
          select
          label="Item Category" // Floating label
          placeholder="Select item category"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Category className="md:mb-2 text-center" />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            sx: {
              fontSize: "1vw", // Change font size for the label
            },
          }}
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "1vw", // Change font size for the input text
            },
          }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  maxHeight: 250, // Adjust the max height of the dropdown
                  "& .MuiMenuItem-root": {
                    typography: "body2", // Adjust text size
                    fontSize: "1vw", // Change font size
                  },
                },
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null, // Ensure dropdown opens from top
            },
          }}
          error={!!errors.item_category_id} // Validation error
          helperText={errors.item_category_id?.message} // Error message
        >
          <MenuItem value="" disabled>
            Select a category
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  </FormControl>
</Grid> */}

            {/* Min Stock Alert */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="min_stock_alert"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Min Stock Alert"
                    placeholder="Enter min stock alert"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Warning />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.min_stock_alert}
                    helperText={errors.min_stock_alert?.message}
                  />
                )}
              />
            </Grid>

            {/* Default Length (mm) */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="default_length_mm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Default Length (mm)"
                    placeholder="Enter length in mm"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Straighten />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.default_length_mm}
                    helperText={errors.default_length_mm?.message}
                  />
                )}
              />
            </Grid>

            {/* Default Width (mm) */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="default_width_mm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Default Width (mm)"
                    placeholder="Enter width in mm"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Straighten />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.default_width_mm}
                    helperText={errors.default_width_mm?.message}
                  />
                )}
              />
            </Grid>

            {/* Default Thickness (mm) */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="default_thickness_mm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Default Thickness (mm)"
                    placeholder="Enter thickness in mm"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.default_thickness_mm}
                    helperText={errors.default_thickness_mm?.message}
                  />
                )}
              />
            </Grid>

            {/* Add remaining fields similarly (default_weight_kg, sgew_price, etc.) */}
            {/* Default Weight (kg) */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="default_weight_kg"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Default Weight (kg)"
                    placeholder="Enter weight in kg"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.default_weight_kg}
                    helperText={errors.default_weight_kg?.message}
                  />
                )}
              />
            </Grid>

            {/* SGEW Price */}
            {/* <Grid item xs={12} sm={6} md={3}>
  <Controller
    name="sgew_price"
    control={control}
    render={({ field }) => (
      <TextField
        {...field}
        fullWidth
        label="SGEW Price"
        placeholder="Enter SGEW price"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoney />
            </InputAdornment>
          ),
        }}
        error={!!errors.sgew_price}
        helperText={errors.sgew_price?.message}
      />
    )}
  />
</Grid> */}

            {/* Processing Fees */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="processing_fees"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Processing Fees"
                    placeholder="Enter processing fees"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.processing_fees}
                    helperText={errors.processing_fees?.message}
                  />
                )}
              />
            </Grid>

            {/* Scrap Weight (kg) */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="scrap_weight_kg"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Scrap Weight (kg)"
                    placeholder="Enter scrap weight in kg"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.scrap_weight_kg}
                    helperText={errors.scrap_weight_kg?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <div className="flex gap-2 w-full justify-end">
                <button type="submit">
                  <Button
                    func={handleClose}
                    title={"Cancel"}
                    customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
                  />
                </button>
                <button type="submit">
                  <Button title={"submit"} isLoading={isLoading} />
                </button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddSubItemModal;

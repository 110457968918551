// accessManagementSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  permissions: [],
  loading: false,
  error: null,
};

// Slice
export const accessManagementSlice = createSlice({
  name: "access",
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload; // Manually set permissions
    },
    clearPermissions: (state) => {
      state.permissions = null; // Clear permissions
      state.error = null;
      state.loading = false;
    },
  },
 
});

// Export actions
export const { setPermissions, clearPermissions } = accessManagementSlice.actions;

// Reducer
export default accessManagementSlice.reducer;

import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import Button from "../../components/Button";
import AddItemModal from "../../components/Modal/additemmodal/AddItemModal";
import { getReq } from "../../apis/api";
import AllItemsTable from "../../components/DataTable/AllItemsTable";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loading";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";

const AllItems = () => {
  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [itemsData, setItemsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { permissions } = useOutletContext();
  const { roleName } = useSelector((state) => state.role);
  const createItemRecordPermissions =
    permissions?.[roleName]?.modules?.["Item and Subitem Management"]?.pages?.["Manage Item Records"]?.sections?.["Manage Item records"];

  const splitItemPermission =
    permissions?.[roleName]?.modules?.["Item and Subitem Management"]?.pages?.["Split Items"]?.sections?.["Split Items"];

  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };
  const fetchItemCategories = async () => {
    try {
      const response = await getReq("/item-categories");

      if (response.status === 200) {
        setCategories(response.data);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in fetching items categories");
    }
  };

  const fetchAllItemsData = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/fetchItems");
      if (response.status === 200) {
        const sortedDataDescending = response.data.sort(
          (a, b) => b.item_id - a.item_id
        );
        setItemsData(sortedDataDescending);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Error in fetching items data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllItemsData();
    fetchItemCategories();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "5.21vw", // Converted from 100px
      center: true,
    },
    {
      name: "ID",
      selector: (row) => row.item_id,
      sortable: true,
      width: "5.21vw", // Converted from 100px
    },
    {
      name: "Name",
      selector: (row) => row.item_name,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.item_category,
      sortable: true,
      wrap: true,
      minWidth: "7.81vw", // Converted from 150px
    },
    {
      name: "HSN",
      selector: (row) => row.hsn,
      sortable: true,
      width: "7.81vw", // Converted from 150px
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Usage Type",
      selector: (row) => row.item_tag,
      sortable: true,
      wrap: true,
      minWidth: "7.81vw", // Converted from 150px
    },
    {
      name: "Stock",
      selector: (row) => row.stock,
      cell: (row) => {
        const isStockHigh = row.stock > 100; // Determine if stock is high or low
        return (
          <div
            className={`px-4 py-1 rounded-[0.42vw] ${
              isStockHigh
                ? "bg-[#d8fff8] text-[#01bd9b]"
                : "bg-[#faebe9] text-[#D0412E]"
            }`}
          >
            {isStockHigh ? "High" : "Low"}{" "}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "SGEW Price",
      selector: (row) => row.unit_price || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "10.42vw", // Converted from 200px
    },
    {
      name: "Sell Price",
      selector: (row) => row.sell_price || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "7.81vw", // Converted from 150px
    },
    {
      name: "Delivery",
      selector: (row) => row.expected_delivery_party || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "7.81vw", // Converted from 150px
    },
    {
      name: "Stock Alert",
      selector: (row) => row.minimum_stock_alert || "N/A",
      sortable: true,
      wrap: true,
      minWidth: "7.81vw", // Converted from 150px
    },
    {
      name: "Status",
      selector: (row) => row.status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.status === "Enable"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.status === "Disable"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.status || "N/A"}
        </div>
      ),
      sortable: true,
      wrap: true,
      minWidth: "7.81vw", // Converted from 150px
    },
  ];

  const onItemAdded = () => {
    fetchAllItemsData();
  }; 


  console.log("split item permission",splitItemPermission)

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Items "} />
        {createItemRecordPermissions?.create && (
          <Button title="Add Item" func={handleButtonClick} />
        )}
      </div>

      <main>
        {isLoading ? (
          <Loader />
        ) : createItemRecordPermissions?.read ? (
          <AllItemsTable
            splitItemPermission={splitItemPermission?.create}
            updateItemRecordPermissions={createItemRecordPermissions?.update}
            columns={columns}
            data={itemsData}
            categories={categories}
            onItemAdded={onItemAdded}
          />
        ) : (
          <p className="text-gray-500 md:py-20px-vw md:text-[1.2vw] text-center font-bold">
            You do not have permission to view this data.
          </p>
        )}
      </main>

      {openModal && (
        <AddItemModal
          open={openModal}
          handleClose={handleButtonClick}
          categories={categories}
          onItemAdded={onItemAdded}
        />
      )}
    </div>
  );
};

export default AllItems;

import React from 'react'

const MainDashboard = () => {
  return (
    <div>
       <h1 className="font-medium uppercase flex items-center justify-center text-center w-full h-screen text-blue-600 text-4xl">
        Welcome To Dashboard
      </h1>
    </div>
  )
}

export default MainDashboard

import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import Button from "../../components/Button";
import AddCategoryModal from "../../components/Modal/categorymodal/AddCategoryModal";
import CategoriesTable from "../../components/DataTable/CategoriesTable";
import { toast } from "react-toastify";
import { getReq } from "../../apis/api";
import Loader from "../../components/loader/Loading";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";

const ManageCategory = () => {
  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState([]); // Ensure this is always an array
  const [isLoading, setIsLoading] = useState(false);
  const { permissions } = useOutletContext();

  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };

  const { roleName } = useSelector((state) => state.role);
  const createCategoryPermissions =
    permissions?.[roleName]?.modules?.["Item and Subitem Management"]?.pages?.[
      "Item Category"
    ]?.sections?.["Create Item Category"];


  const fetchItemCategories = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/item-categories");

      // Ensure the response is an array before updating state
      if (response.status === 200 && Array.isArray(response.data)) { 
        const sortedCategories = response.data.sort((a, b) => b.id - a.id);
        setCategories(sortedCategories);
      } else {
        setCategories([]); // Set categories to an empty array if not an array or error response
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in fetching item categories");
      setCategories([]); 
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchItemCategories();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Category ID",
      selector: (row) => row.item_category_id || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Description",
      selector: (row) => row.description || "N/A",
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.status === "Enable"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.status === "Disable"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.status || "N/A"}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Items / Categories"} />
        {createCategoryPermissions?.create && (
          <Button title="Add Category" func={handleButtonClick} />
        )}
      </div>

      <main>
        {isLoading ? (
          <Loader />
        ) : createCategoryPermissions?.read ? (
          Array.isArray(categories) && categories.length > 0 ? (
            <CategoriesTable 
              isUpdateCategorypermission={createCategoryPermissions?.update}
              columns={columns}
              data={categories}
              onItemAdded={fetchItemCategories}
            />
          ) : (
            <p className="text-center text-gray-500 font-bold">
              No Item Categories Found.
            </p>
          )
        ) : (
          <p className="text-gray-500 md:py-20px-vw md:text-[1.2vw] text-center font-bold">
            You do not have permission to view this data.
          </p>
        )}
      </main>

      {openModal && (
        <AddCategoryModal 
        fetchItemCategories={fetchItemCategories}
          open={openModal}
          handleClose={() => setOpenModal(false)}
          title={"Add Category"}
        />
      )}
    </div>
  );
};

export default ManageCategory;

import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { jsonpostReq, postReq } from "../../apis/api"; // Use postReq for POST requests
import Loader from "../../components/loader/Loading";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaCheck, FaTimes } from "react-icons/fa";

const Allapprovals = () => {
  const [approvals, setApprovals] = useState([]); // State for approvals data
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("items"); // Default to "items"
  const [selectedRows, setSelectedRows] = useState([]); // State for selected rows
  const [toggleCleared, setToggleCleared] = useState(false); // State to clear selected rows
  const { permissions } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const { roleName } = useSelector((state) => state.role);
  const readApprovalsPermissions =
    permissions?.[roleName]?.modules?.["Approvals"]?.pages?.["All Approvals"]
      ?.sections?.["Read Approvals"];

  // List of module types for tabs
  const moduleTypes = [
    "items",
    "subitems",
    "users",
    "split",
    "products",
    "assets",
    "orders",
    "dealer_orders",
    "product_services",
    "warranty_services",
    "products_stock",
  ];

  // Fetch approvals data for the selected module type
  const fetchApprovals = async (moduleType) => {
    try {
      setIsLoading(true);
      const response = await postReq(
        {
          module_type: moduleType,
        },
        "/approvals/fetchPendingRequest"
      );
      if (response.status === 200 && Array.isArray(response.data.data)) {
        setApprovals(response.data.data);
      } else {
        setApprovals([]); // Set to empty array if no data or error
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in fetching approvals");
      setApprovals([]); // Set to empty array in case of an error
    } finally {
      setIsLoading(false);
    }
  };

  // Handle tab change
  const handleTabChange = (moduleType) => {
    setActiveTab(moduleType);
    fetchApprovals(moduleType);
    setSearchParams({ module_type: moduleType });
  };

  useEffect(() => {
    const moduleType = searchParams.get("module_type");
    if (moduleType && moduleTypes.includes(moduleType)) {
      setActiveTab(moduleType);
      fetchApprovals(moduleType);
    } else {
      fetchApprovals(activeTab);
    }
  }, [searchParams]);

  // Handle approve action for a single row
  const handleApprove = async (id) => {
    try {
      const response = await postReq(
        {
          status: "approved",
        },
        `/approvals/${id}`
      );
      if (response.status === 200) {
        const successmsg = response?.data?.message || "Approval successful!";
        toast.success(successmsg);
        fetchApprovals(activeTab);
      } else {
        toast.error(response.data.message || "Failed To Approve", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = error.response?.data?.message || "Failed to approve";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  // Handle reject action for a single row
  const handleReject = async (id) => {
    try {
      const response = await postReq(
        {
          status: "rejected",
        },
        `/approvals/${id}`
      );
      if (response.status === 200) {
        const successmsg = response?.data?.message || "Rejection successful!";
        toast.success(successmsg);
        fetchApprovals(activeTab);
      } else {
        toast.error(response.data.message || "Failed To Reject", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      const errorMessage = error.response?.data?.message || "Failed to reject";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  // Handle bulk approve action
  const handleBulkApprove = async () => {
    try {
      const requestData = selectedRows.map((row) => ({
        id: row.id,
        status: "approved",
      }));

      const response = await jsonpostReq(requestData, "/bulkUpdateApproval");
      if (response.status === 200) {
        // const successmsg =
        //   response?.data?.message?.map((obj)=>obj.message) || "Bulk approval successful!";
        toast.success("Congratulation! Request Has Been Approved Successfully");
        setTimeout(() => {
          fetchApprovals(activeTab);
        }, 2000);

        setToggleCleared(!toggleCleared); // Clear selected rows
      } else {
        toast.error(response.data.message || "Failed To Bulk Approve", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      const errorMessage =
        error.response?.data?.message || "Failed to bulk approve";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  // Handle bulk reject action
  const handleBulkReject = async () => {
    try {
      const requestData = selectedRows.map((row) => ({
        id: row.id,
        status: "approved",
      }));
      const response = await jsonpostReq(requestData, "/bulkUpdateApproval");
      if (response.status === 200) {
        toast.success("Congratulation! Request Has Been Rejected Successfully");
        setTimeout(() => {
          fetchApprovals(activeTab);
        }, 2000);

        setToggleCleared(!toggleCleared); // Clear selected rows
      } else {
        toast.error(response.data.message || "Failed To Bulk Reject", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      const errorMessage =
        error.response?.data?.message || "Failed to bulk reject";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  // Table columns
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "Module Type",
      selector: (row) => row.module_type,
      sortable: true,
      wrap: true,
    },
    {
      name: "Module ID",
      selector: (row) => row.module_id,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.status === "pending"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.status === "approved"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.status || "N/A"}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Requested By",
      selector: (row) => row.requested_by,
      sortable: true,
      wrap: true,
    },
    {
      name: "Approved By",
      selector: (row) => row.approved_by || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-2">
          <button
            onClick={() => handleApprove(row.id)}
            className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 flex items-center justify-center"
          >
            <FaCheck className="text-white" /> {/* Approve icon */}
          </button>
          <button
            onClick={() => handleReject(row.id)}
            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 flex items-center justify-center"
          >
            <FaTimes className="text-white" /> {/* Reject icon */}
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  // Fetch data for the default module type on component mount
  useEffect(() => {
    fetchApprovals(activeTab);
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Dashboard / Approvals "} />
      </div>

      {/* Tab Navigation */}
      <div className="flex space-x-35px-vw w-full items-center mb-6 overflow-x-auto">
        {moduleTypes.map((moduleType) => (
          <button
            key={moduleType}
            onClick={() => handleTabChange(moduleType)}
            className={`px-20px-vw py-12px-vw rounded-lg text-[1vw] font-medium transition-all duration-300 ${
              activeTab === moduleType
                ? "bg-blue-500 text-white shadow-lg"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            {moduleType.charAt(0).toUpperCase() + moduleType.slice(1)}
          </button>
        ))}
      </div>

      {/* Bulk Actions */}
      {selectedRows.length > 0 && (
        <div className="flex space-x-4 mb-4">
          <button
            onClick={handleBulkApprove}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center justify-center"
          >
            <FaCheck className="text-white mr-2" /> Bulk Approve
          </button>
          <button
            onClick={handleBulkReject}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 flex items-center justify-center"
          >
            <FaTimes className="text-white mr-2" /> Bulk Reject
          </button>
        </div>
      )}

      {/* Table */}
      <main>
        {isLoading ? (
          <Loader />
        ) : Array.isArray(approvals) && approvals.length > 0 ? (
          <div className="overflow-hidden !bg-white rounded-[0.42vw]">
            <DataTable
              columns={columns}
              data={approvals}
              pagination
              highlightOnHover
              selectableRows // Enable row selection
              selectableRowsHighlight // Highlight selected rows
              onSelectedRowsChange={({ selectedRows }) =>
                setSelectedRows(selectedRows)
              }
              clearSelectedRows={toggleCleared} // Clear selected rows
              customStyles={{
                rows: {
                  style: {
                    minHeight: "60px",
                    fontSize: "0.9vw",
                  },
                },
                headCells: {
                  style: {
                    fontWeight: 600,
                    textAlign: "center",
                    whiteSpace: "normal",
                    fontSize: "1vw",
                    color: "#10131e",
                  },
                },
              }}
            />
          </div>
        ) : (
          <p className="text-center text-[1.2vw] my-[1vw] text-gray-500 font-bold">
            No Approvals Found for {activeTab}.
          </p>
        )}
      </main>
    </div>
  );
};

export default Allapprovals;

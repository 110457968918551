import React from 'react'
import { Outlet } from 'react-router-dom'

export const Attendance = () => {
  return (
    <div>
       <Outlet/>
    </div>
  )
}

export default Attendance

import { MdDeleteForever } from "react-icons/md";
import { BsPlusCircleDotted } from "react-icons/bs";
import React, { useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import {
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Dialog,
  DialogTitle,
  IconButton,
  FormControl,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import {
  Close as CloseIcon,
  AttachMoney,
  Assignment,
  DateRange,
  Category,
  Add,
  Remove,
} from "@mui/icons-material";
import { postReq } from "../../apis/api";
import Button from "../Button";
import CreateUser from "../Modal/user/Create";

// Validation schema using Yup
const validationSchema = yup.object().shape({
  supplier_id: yup.string().required("Supplier ID is required"),
  order_date: yup.date().required("Order Date is required"),
  expected_delivery_date: yup.date().required("Expected Delivery Date is required"),
  advance_payment: yup.number().required("Advance Payment is required"),
  payment_mode: yup.string().required("Payment Mode is required"),
  comment: yup.string(),
  items: yup.array().of(
    yup.object().shape({
      item_id: yup.string().required("Item ID is required"),
      quantity: yup.number().required("Quantity is required"),
    })
  ),
});


export const OrderCreateModal = ({ open, handleClose, suppliers, itemsList }) => {
  const [openSupplier,setOpenSuplier]= useState(false);
  const currentDate = new Date().toISOString().slice(0, 10);
const expectedDate = new Date();
expectedDate.setDate(expectedDate.getDate() + 3);
const expectedDeliveryDate = expectedDate.toISOString().slice(0, 10);
  const { watch, control, reset, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      supplier_id: "",
      order_date: currentDate, // Set to today's date
      expected_delivery_date: expectedDeliveryDate, // Set to 3 days later
      advance_payment: "",
      payment_mode: "",
      status: "due",
      payment_comment: "",
      comment: "",
      items: [{ item_id: "", quantity: "" }],
    },
  });
  const handleSupplier=()=>{
    setOpenSuplier(!openSupplier);
  }
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const onSubmit = async (data) => {
    try {
      const itemsData = data.items || [];

      // Format dates to "DD-MM-YYYY"
      const formattedOrderDate = format(new Date(data.order_date), "dd-MM-yyyy");
      const formattedExpectedDeliveryDate = format(new Date(data.expected_delivery_date), "dd-MM-yyyy");

      // Construct the payload with mapped item properties
      const payload = {
        supplier_id: data.supplier_id,
        order_date: formattedOrderDate,
        expected_delivery_date: formattedExpectedDeliveryDate,
        item_id: itemsData.map(item => item.item_id).join(","),
        item_hsn: itemsData.map(item => {
          const selectedItem = itemsList.find(itm => itm.id === item.item_id);
          return selectedItem ? selectedItem.hsn : "";
        }).join(","),
        item_qty: itemsData.map(item => item.quantity).join(","),
        status: data.status === "due" ? "0" : "1",
        comment: data.comment || "",
        sgew_price: itemsData.map(item => {
          const selectedItem = itemsList.find(itm => itm.id === item.item_id);
          return selectedItem ? selectedItem.unit_price : "";
        }).join(","),
        length_mm: itemsData.map(item => {
          const selectedItem = itemsList.find(itm => itm.id === item.item_id);
          return selectedItem ? selectedItem.length_mm : "";
        }).join(","),
        width_mm: itemsData.map(item => {
          const selectedItem = itemsList.find(itm => itm.id === item.item_id);
          return selectedItem ? selectedItem.width_mm : "";
        }).join(","),
        thickness_mm: itemsData.map(item => {
          const selectedItem = itemsList.find(itm => itm.id === item.item_id);
          return selectedItem ? selectedItem.thickness_mm : "";
        }).join(","),
        weight_kg: itemsData.map(item => {
          const selectedItem = itemsList.find(itm => itm.id === item.item_id);
          return selectedItem ? selectedItem.weight_kg : "";
        }).join(","),
      };

      const response = await postReq(payload, "/createPurchase");

      if (response.status === 201) {
        toast.success("Order Created successfully!");
        reset();
        handleClose();
      } else {
        toast.error(response.data.message || "Order Not Created", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": { fontSize: "1vw" },
            "& .MuiInputLabel-root": { fontSize: "1vw" },
          },
        },
      },
    },
  });

  return (
    <div>
  <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={(_, reason) => reason !== "backdropClick" && handleClose()}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="border-b border-gray-300">
          Create PurchaseOrder
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "16px" }}>
          <Grid container spacing={3.5}>
            {/* Supplier Selection */}
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="supplier_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label="Supplier ID"
                    variant="outlined"
                    error={!!errors.supplier_id}
                    helperText={errors.supplier_id?.message}
                  >
                    <MenuItem value="" disabled>Select a supplier</MenuItem>
                    {suppliers.map(supplier => (
                      <MenuItem key={supplier.id} value={supplier.id}>{supplier.user_name}</MenuItem>
                    ))}
                  </TextField>
                )}
              />
             <div className="pl-2 text-red-700 cursor-pointer" onClick={handleSupplier}>
             create supplier 
             </div>
            </Grid>
          
            {/* Order Date */}
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="order_date"
                control={control}
                render={({ field }) => (
                  <TextField {...field} fullWidth label="Order Date" type="date" variant="outlined" InputLabelProps={{ shrink: true }} error={!!errors.order_date} helperText={errors.order_date?.message} />
                )}
              />
            </Grid>

            {/* Expected Delivery Date */}
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="expected_delivery_date"
                control={control}
                render={({ field }) => (
                  <TextField {...field} fullWidth label="Expected Delivery Date" type="date" variant="outlined" InputLabelProps={{ shrink: true }} error={!!errors.expected_delivery_date} helperText={errors.expected_delivery_date?.message} />
                )}
              />
            </Grid>

            {/* Dynamic Item Section */}
            <Grid item xs={12} className="mt-3">
              <Grid container spacing={3.5}>
                {fields.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name={`items.${index}.item_id`}
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} select fullWidth label="Item Name" variant="outlined" error={!!errors.items?.[index]?.item_id} helperText={errors.items?.[index]?.item_id?.message}>
                            {itemsList?.map(itm => (
                              <MenuItem key={itm.id} value={itm.id}>{itm.name}</MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name={`items.${index}.quantity`}
                        control={control}
                        render={({ field }) => (
                          <TextField {...field} fullWidth label="Quantity" variant="outlined" error={!!errors.items?.[index]?.quantity} helperText={errors.items?.[index]?.quantity?.message} />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} className="flex items-center gap-3">
                      <IconButton onClick={() => remove(index)}>
                        <MdDeleteForever size={30} style={{ color: "#d0412e" }} />
                      </IconButton>
                      {index === fields.length - 1 && (
                        <IconButton onClick={() => append({ item_id: "", quantity: "" })} color="primary">
                          <BsPlusCircleDotted size={30} />
                        </IconButton>
                      )}
                    </Grid>
                  
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="advance_payment"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Advance Payment"
                    placeholder="Enter advance payment"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.advance_payment}
                    helperText={errors.advance_payment?.message}
                  />
                )}
              />
            </Grid>

            {/* Payment Mode */}
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="payment_mode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label="Payment Mode"
                    placeholder="Select payment mode"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Assignment />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.payment_mode}
                    helperText={errors.payment_mode?.message}
                  >
                    <MenuItem value="" disabled>
                      Select payment mode
                    </MenuItem>
                    <MenuItem value="credit">Credit</MenuItem>
                    <MenuItem value="debit">Debit</MenuItem>
                    <MenuItem value="cash">Cash</MenuItem>
                  </TextField>
                )}
              />
            </Grid>


            {/* <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label="Status"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Assignment />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.status}
                    helperText={errors.status?.message}
                  >
                    <MenuItem value="received">Received</MenuItem>
                    <MenuItem value="due">Due</MenuItem>
                  </TextField>
                )}
              />
            </Grid> */}
              <Grid item xs={12} sm={6} md={4}>
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            select
            fullWidth
            label="Status"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Assignment />
                </InputAdornment>
              ),
            }}
            error={!!errors.status}
            helperText={errors.status?.message}
          >
            <MenuItem value="received">Received</MenuItem>
            <MenuItem value="due">Due</MenuItem>
          </TextField>
        )}
      />
    </Grid>

            <Grid item xs={12} sm={6} md={12}>
              <Controller
                name="payment_comment"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Payment Comment/Details"
                    placeholder="Enter payment details or comments"
                    variant="outlined"
                    multiline
                    rows={1}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.payment_comment}
                    helperText={errors.payment_comment?.message}
                  />
                )}
              />
            </Grid>

            {/* Comments */}
            <Grid item xs={12}>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Comments"
                    placeholder="Enter comments"
                    variant="outlined"
                    multiline
                    rows={1}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <div className="flex gap-2 w-full justify-end">
                <Button
                  func={handleClose}
                  title="Cancel"
                  customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
                />
                <Button type="submit" title="Submit" />
              </div>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    
    </ThemeProvider>

    <CreateUser open={openSupplier} handleClose={handleSupplier}  onUserCreated={handleSupplier}/>
    </div>
  
  );
};

export default OrderCreateModal;
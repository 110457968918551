import React from "react";
import { reactIcons } from "../utils/icons";

export const FormButton = ({
  type,
  func,
  title,
  customcls,
  isLoading,
  spinner,
}) => {
  return (
    <button
      type={type}
      onClick={func}
      disabled={isLoading}
      className={`flex items-center text-18px-vw justify-center px-4 py-[0.5vw] font-semibold rounded-[0.24vw] transition duration-300 ease-in-out transform focus:outline-none ${
        customcls ? customcls : "bg-[#0074ff] hover:bg-blue-700 text-white"
      }`}
    >
      {title}
      {isLoading && (
        <span className="animate-spin inline-block px-[0.7vw] text-20px-vw">
          {reactIcons.spinLoader}
        </span>
      )}
    </button>
  );
};

export default FormButton;

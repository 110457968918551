import React, { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

const Index = () => {
  const { permissions } = useOutletContext();

  return (
    <div className="w-full ">
      <Outlet context={{ permissions }} />
    </div>
  );
};

export default Index;

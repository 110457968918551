import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { HiDotsVertical } from "react-icons/hi";
import { Menu, Transition } from "@headlessui/react";
import AddPaymentModal from "../Modal/paymentmodal/AddPaymentModal";

const TransactionHistoryTable = ({
  transactionData,
  userData,
  onItemAdded,
  customers,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);

  const columns = [
    { name: "Payment ID", selector: (row) => row.payment_id, sortable: true },
    { name: "User ID", selector: (row) => row.user_id, sortable: true },
    {
      name: "Payment Date",
      selector: (row) => row.payment_date,
      sortable: true,
    },
    { name: "Debit Bank", selector: (row) => row.debit_bank, sortable: true },
    { name: "Reference", selector: (row) => row.reference_id, sortable: false },
    {
      name: "Payment Comment",
      selector: (row) => row.payment_comment,
      sortable: false,
    },
    {
      name: "Screenshot",
      cell: (row) =>
        row.payment_screenshot ? (
          <a
            href={row.payment_screenshot}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            View
          </a>
        ) : (
          "N/A"
        ),
      sortable: false,
    },
    { name: "Amount", selector: (row) => row.amount, sortable: true },
    { name: "Type", selector: (row) => row.type, sortable: true },
    { name: "Received By", selector: (row) => row.received_by, sortable: true },
    {
      name: "Refund Payment",
      selector: (row) => row.refund_payment,
      sortable: true,
    },
    {
      name: "Next Due Date",
      selector: (row) => row.next_due_date,
      sortable: true,
    },
  ];

  const filteredData = transactionData.filter((item) => {
    const paymentDate = new Date(item.payment_date);
    const from = fromDate ? new Date(fromDate) : null;
    const to = toDate ? new Date(toDate) : null;

    if (from && paymentDate < from) return false;
    if (to && paymentDate > to) return false;
    return true;
  });

  const handleClearFilters = () => {
    setFromDate("");
    setToDate("");
    setResetPaginationToggle(!resetPaginationToggle);
  };

  const actionColumn = {
    name: "Actions",
    cell: (row) => (
      <Menu as="div" className="relative">
        <Menu.Button>
          <HiDotsVertical className="w-6 h-6 text-gray-500 cursor-pointer" />
        </Menu.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items className="absolute top-0 right-0 w-48 bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-sm`}
                  onClick={() => {
                    setOpenModal(true);
                    setSelectedPaymentId(row.id);
                  }}
                >
                  Edit
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    right: true,
  };

  const enhancedColumns = [...columns, actionColumn];

  const subHeaderComponent = (
    <div className="flex justify-between items-center bg-white py-4 px-6 w-full shadow-md rounded-md">
      <div className="flex items-center gap-6">
        <h4 className="text-xl font-bold text-gray-800">Transaction List</h4>
        {userData.map((user) => (
          <div
            className="text-sm font-medium flex flex-wrap gap-4 text-gray-700"
            key={user.id}
          >
            <p className="text-blue-600">{`${user.user_name} [${user.id}]`}</p>
            <p className="text-red-600">{user.user_type}</p>
            <p className="text-blue-600">
              {`Opening Balance: ${user.opening_balance}`}
            </p>
            <p className="text-green-600">
              {`Current Balance: ${user.current_balance}`}
            </p>
            <p className="text-red-600">
              {`Closing Balance: ${user.closing_balance}`}
            </p>
          </div>
        ))}
      </div>
      <div className="flex gap-4 items-center">
        <div className="flex items-center gap-4">
          <label className="text-sm font-medium">From:</label>
          <input
            type="date"
            className="border border-gray-300 p-2 rounded-md bg-gray-50 focus:ring-2 focus:ring-blue-400 focus:outline-none"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </div>
        <div className="flex items-center gap-4">
          <label className="text-sm font-medium">To:</label>
          <input
            type="date"
            className="border border-gray-300 p-2 rounded-md bg-gray-50 focus:ring-2 focus:ring-blue-400 focus:outline-none"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
        <button
          className="px-4 py-2 bg-[#0074ff] text-white rounded-md "
          onClick={handleClearFilters}
        >
          Clear Filters
        </button>
      </div>
    </div>
  );

  return (
    <div className="font-poppins">
      <div>
        <DataTable
          columns={enhancedColumns}
          data={filteredData}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponent}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
          customStyles={{
            rows: {
              style: {
                minHeight: "50px",
                fontSize: "0.9rem",
                "&:hover": { backgroundColor: "#f9f9f9" },
              },
            },
            headCells: {
              style: {
                fontWeight: 600,
                textAlign: "center",
                fontSize: "1rem",
                color: "#10131e",
              },
            },
          }}
        />
      </div>

      {openModal && (
        <AddPaymentModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          customers={customers}
          onItemAdded={onItemAdded}
          selectedPaymentId={selectedPaymentId}
        />
      )}
    </div>
  );
};

export default TransactionHistoryTable;

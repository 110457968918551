import React, { useEffect, useState } from "react";
import {
  Modal,
  TextField,
  FormControl,
  MenuItem,
  Grid,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import Button from "../../Button";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import CategoryIcon from "@mui/icons-material/Category";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import HttpsIcon from "@mui/icons-material/Https";
import StraightenIcon from "@mui/icons-material/Straighten";
import ScaleIcon from "@mui/icons-material/Scale";
import PercentIcon from "@mui/icons-material/Percent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import UsageIcon from "@mui/icons-material/Build";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Category } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { editItemSchema, itemSchema } from "../../../utils/validation";
import { getReq, postReq, postReqSecure, putReq } from "../../../apis/api"; // Assuming you have getReq for fetching data
import { toast } from "react-toastify";
import Loader from "../../loader/Loading";
import CustomButton from "../../button/CustomButton";

const ViewCategoryModal = ({
  isUpdateCategorypermission,
  open,
  handleClose,
  ItemId,
  categories,
  onItemAdded,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [itemData, setItemData] = useState("");

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      item_category_id: "",
      name: "",
      description: "",
      status: "",
    },
  });

  // Fetch the item details when the modal opens
  useEffect(() => {
    if (ItemId && open) {
      fetchItemDetails();
    }
  }, [ItemId, open]);

  const fetchItemDetails = async () => {
    setIsLoading(true);
    try {
      const response = await getReq(`/item-categories/${ItemId}`);
      console.log("response", response);
      if (response.status === 200) {
        const data = response.data;

        setItemData(data);
        reset(data);
      } else {
        toast.error("Failed to fetch item details.");
      }
    } catch (error) {
      toast.error("Error fetching item details.");
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    console.log("Updated Data:", data);

    try {
      setIsLoading(true);
      const response = await putReq(data, `/item-categories/${ItemId}`);

      if (response.status === 200) {
        toast.success("Item Category updated successfully!", {
          position: "top-right",
          autoClose: 5000,
        });

        reset();
        handleClose();
        setTimeout(() => {
          onItemAdded();
        }, 1000);
      } else {
        toast.error(response.data.message || "User Not Created", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages
      ) {
        const errorMsg = error.response.data.messages;

        // Display error message in toast
        Object.values(errorMsg).forEach((msg) => {
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
          });
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              fontSize: "1vw",
            },
            "& .MuiInputLabel-root": {
              fontSize: "1vw",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        sx={{
          "& .MuiDialogContent-root": {
            padding: "28px !important",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            className="border-b border-gray-300 flex items-center  justify-between"
            sx={{ position: "relative" }}
          >
            {/* Title Section */}
            <Typography variant="h6" className="mr-auto">
              View / Edit Categories
            </Typography>

            {/* Edit/Cancel Button */}
            {isUpdateCategorypermission && (
              <CustomButton
                variant="outlined"
                onClick={() => setIsEditMode(!isEditMode)}
                isEditing={isEditMode}
                text={isEditMode ? "Cancel" : "Edit"}
                bgColor={isEditMode ? "#0074ff1a" : "#0074ff1a"}
                textColor={isEditMode ? "#0074ff" : "#0074ff"}
                fontWeight={600}
                borderRadius="8px"
                border="none"
                padding="8px 20px"
                // Add margin to separate it from the title
              />
            )}

            {/* Close Button */}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            ></IconButton>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={4}>
              {/* Render all fields with the 'disabled' prop if not in edit mode */}

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="item_category_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="ID"
                      placeholder="Enter Category ID"
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CategoryIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.item_name}
                      helperText={errors.item_name?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Name"
                      disabled={!isEditMode}
                      placeholder="Enter Name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HttpsIcon />
                          </InputAdornment>
                        ),
                      }}
                      //   error={!!errors.hsn}
                      //   helperText={errors.hsn?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Description"
                      disabled={!isEditMode}
                      placeholder="Enter Description"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HttpsIcon />
                          </InputAdornment>
                        ),
                      }}
                      //   error={!!errors.hsn}
                      //   helperText={errors.hsn?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth variant="outlined">
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label="Status"
                        variant="outlined"
                        disabled={!isEditMode}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <UsageIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors.status}
                        helperText={errors.status?.message}
                      >
                        <MenuItem value="Enable">Enable</MenuItem>
                        <MenuItem value="Disable">Disable</MenuItem>
                      </TextField>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions className=" md:mr-14px-vw md:mb-8px-vw ">
            <Button
              func={handleClose}
              title={"Cancel"}
              customcls="bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
            />
            {isEditMode && (
              <Button
                type="submit"
                func={handleSubmit(onSubmit)}
                title={"Save Changes"} 
                isLoading={isLoading}
              />
            )}
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default ViewCategoryModal;

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import CustomButton from "../../components/button/CustomButton";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { FaUserCircle } from "react-icons/fa";
import {
  FaPhoneAlt,
  FaMobileAlt,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import {
  Fingerprint as FingerprintIcon,
  Lock,
  ToggleOff,
  ToggleOn,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EmailIcon from "@mui/icons-material/Email";
import IconBtn from "../../components/Modal/confirmationmodal/IconBtn";
import DataTable from "react-data-table-component";
import { getReq, logoutReq, postReq, postReqLogout } from "../../apis/api";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loading";
import UserPermission from "./UserPermission";
import ConfirmationModal from "../../components/Modal/confirmationmodal/ConfirmationModal";

const initialUserData = {
  id: "",
  userType: "",
  user_name: "",
  father_name: "",
  phone: "",
  mobile: "",
  email: "",
  password: "",
  confirm_password: "",
  address_line_1: "",
  address_line_2: "",
  village: "",
  district: "",
  state: " ",
  country: "",
  bank_account_number: "",
  bank_acc_holder_name: "",
  IFSC: "",
  gstin_number: "",
  employee_monthly_salary: "",
  overtime_per_hour_salary: "",
  employee_type: "",
  preffered_terms: "",
  role_id: "",
  payment_sms_reminder: "",
  status: "",
  start_login_time: "",
  end_login_time: "",
};

const UserDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [permission, setPermission] = useState(false);
  // const { user } = location.state || {}; // Access the passed user data
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword((prev) => !prev);
  const { id } = useParams();
  const [userData, setUserData] = useState(initialUserData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    if (userData.password !== userData.confirm_password) {
      toast.error("Password and Confirm Password Do Not Match");
      return;
    }
    e.preventDefault();

    const { confirm_password, userType, ...userDataWithoutConfirm } = userData;
    console.log("Updated user data:", userDataWithoutConfirm);
    // Call the API to update the user data
    try {
      setIsLoading(true);
      const response = await postReq(userDataWithoutConfirm, "/updateUser");

      if (response.status === 200) {
        toast.success("user Updated Successfully");
        setIsEditing(false);
      } else {
        toast.error(response.data.message || "User Not Updated", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      // Handling errors and displaying the appropriate error message
      if (error.response && error.response.data && error.response.data.msg) {
        const errorMsg = error.response.data.msg;

        // Display error message in toast
        Object.values(errorMsg).forEach((msg) => {
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
          });
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setIsLoading(true);
        const response = await getReq(`/fetchUsers/${id}`);

        if (response.data) {
          setUserData(response.data);
        }
      } catch (error) {
        toast.error("error in fetching userdetails");
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserData();
  }, [id]);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleClearToken = async (userData) => {
    console.log("userdata token", userData.token_1);

    if (!userData.token_1) {
      toast.error("User is already logged out");
      return;
    }

    try {
      const response = await postReqLogout("/logout", userData.token_1);
      console.log("response", response);

      if (response.status === 200 && response.message !== "Invalid auth") {
        setConfirmationModal(null);
        toast.success(response.message || "Token cleared successfully");
      } else {
        // Handle cases where the response status is not 200 or message indicates failure
        toast.error(`Token clearance failed: ${response.message}`);
      }
    } catch (error) {
      // Handle unexpected errors
      toast.error(
        `Error during token clearance: ${
          error.message || "An unexpected error occurred."
        }`
      );
    }
  };
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "Purchase ID",
      selector: (row) => row.purchase_id,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: (row) => row.product_name,
      sortable: true,
      wrap: true, // ensures long text wraps
    },
    {
      name: "Purchase Date",
      selector: (row) => row.purchase_date,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
      center: true,
    },
    {
      name: "Unit Price",
      selector: (row) => `$${row.unit_price}`,
      sortable: true,
      center: true,
    },
    {
      name: "Total Price",
      selector: (row) => `$${row.total_price}`,
      sortable: true,
      center: true,
    },
    {
      name: "Payment Method",
      selector: (row) => row.payment_method || "N/A",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.purchase_status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.purchase_status === "Completed"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.purchase_status === "Pending"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.purchase_status || "N/A"}
        </div>
      ),
      sortable: true,
    },
  ];
  const columnsOrder = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "Order ID",
      selector: (row) => row.order_id,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customer_name,
      sortable: true,
      wrap: true, // allows text wrapping for long names
    },
    {
      name: "Order Date",
      selector: (row) => row.order_date,
      sortable: true,
    },
    {
      name: "Item Quantity",
      selector: (row) => row.item_quantity,
      sortable: true,
      center: true,
    },
    {
      name: "Total Amount",
      selector: (row) => `$${row.total_amount}`,
      sortable: true,
      center: true,
    },
    {
      name: "Payment Method",
      selector: (row) => row.payment_method || "N/A",
      sortable: true,
    },
    {
      name: "Delivery Status",
      selector: (row) => row.delivery_status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.delivery_status === "Delivered"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.delivery_status === "Pending"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.delivery_status || "N/A"}
        </div>
      ),
      sortable: true,
    },
  ];
  const assetsColumns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "Asset ID",
      selector: (row) => row.asset_id,
      sortable: true,
    },
    {
      name: "Asset Name",
      selector: (row) => row.asset_name,
      sortable: true,
      wrap: true, // ensures long text wraps
    },
    {
      name: "Assigned To",
      selector: (row) => row.assigned_to,
      sortable: true,
    },
    {
      name: "Assigned Date",
      selector: (row) => row.assigned_date,
      sortable: true,
    },
    {
      name: "Asset Status",
      selector: (row) => row.asset_status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.asset_status === "Active"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.asset_status === "Inactive"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.asset_status || "N/A"}
        </div>
      ),
      sortable: true,
    },
  ];
  const paymentsColumns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "Payment ID",
      selector: (row) => row.payment_id,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customer_name,
      sortable: true,
      wrap: true, // ensures long text wraps
    },
    {
      name: "Payment Date",
      selector: (row) => row.payment_date,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => `$${row.amount}`,
      sortable: true,
      center: true,
    },
    {
      name: "Payment Method",
      selector: (row) => row.payment_method || "N/A",
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => row.payment_status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.payment_status === "Completed"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.payment_status === "Pending"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.payment_status || "N/A"}
        </div>
      ),
      sortable: true,
    },
  ];
  const handlePermission = () => {
    // console.log("clickkked");
    setPermission(!permission);
  };

  return (
    <div className=" font-poppins">
      {confirmationModal && <ConfirmationModal modalData={confirmationModal} />}
      {isLoading && <Loader />}
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[2vw] ">
        <BreadCrumbs title={"Home / Users / User-Detail"} />
        <div className="flex items-center gap-x-20px-vw">
          <CustomButton
            variant="outlined"
            onClick={toggleEdit}
            isEditing={isEditing}
            text={isEditing ? "Cancel" : "Edit"}
            bgColor={isEditing ? "#0074ff1a" : "#0074ff1a"}
            textColor={isEditing ? "#0074ff" : "#0074ff"}
            fontWeight={600}
            borderRadius="8px"
            border="none"
            padding="8px 20px"
          />

          {userData.role_id !== 1 && (
            <IconBtn
              onclick={() =>
                setConfirmationModal({
                  text1: "Are you sure?",
                  text2: `You want to clear token for the ${userData.user_name}?`,
                  btn1Text: "Yes",
                  btn2Text: "Cancel",
                  btn1Handler: () => handleClearToken(userData),
                  btn2Handler: () => setConfirmationModal(null),
                })
              }
              text={"Clear Token"}
              customClasses="flex-1 !bg-[#d0412e33] !text-[#D0412E] hover:bg-[#D0412E] hover:text-white" // Equal width for both buttons
            />
          )}

          <CustomButton
            onClick={handlePermission}
            variant="contained"
            type="text"
            text={!permission ? "Permissions" : "Cancel"}
            bgColor={permission ? "#d0412e33" : "#0074ff"}
            textColor={permission ? "#D0412E" : "#ffffff"}
            fontWeight={600}
            borderRadius="8px"
            padding="8px 20px"
          />
        </div>
      </div>

      {permission ? (
        <div> 
          <UserPermission id={id} />
        </div>
      ) : (
        <div className="flex font-poppins h-full !overflow-x-hidden ">
          {/* Left Sidebar */}
          <div className="w-1/4 p-60px-vw bg-white   ">
            <div className="flex flex-col items-center mb-6 ">
              <div className=" md:p-10px-vw md:pb-20px-vw  flex justify-center items-center">
                <FaUserCircle
                  className="text-blue-500"
                  style={{ fontSize: "8vw" }}
                />
              </div>
              <Typography
                variant="h5"
                className="mt-20px-vw font-bold text-gray-800"
              >
                {userData.user_name}
              </Typography>
              <Typography variant="body2" className="text-gray-600">
                {` ${userData.userType}`}
              </Typography>
            </div>
            <div className="space-y-4 text-lg text-gray-600">
              {/* Additional sidebar content can go here */}
            </div>

            <div className="border mt-40px-vw bg-white border-gray-300 rounded-[1.5vw] flex flex-col items-center justify-center p-6   ">
              <Typography
                variant="h6"
                className="font-bold text-center text-[#10131e] mb-2"
              >
                Outstanding Balance Amount
              </Typography>
              <Typography variant="h4" className="text-[#00796b] font-bold">
                <span className="text-[1.1em]">₹</span> 500,000
              </Typography>
            </div>

            <div className="border mt-20px-vw border-gray-300 rounded-[1.5vw] flex flex-col items-center justify-center p-20px-vw bg-white">
              <Typography
                variant="h6"
                className="font-bold text-[#10131e] md:mb-24px-vw"
              >
                Connect
              </Typography>

              <div className="flex flex-col md:mt-10px-vw space-y-4">
                {/* Mobile Number */}
                <div className="flex items-center space-x-2">
                  <FaMobileAlt className="mr-2 text-gray-500 text-[1.2vw] w-6" />
                  <a
                    href={`tel:+${userData.mobile}`}
                    className="text-[1vw] hover:text-blue-600 cursor-pointer"
                  >
                    {userData.mobile || "N/A"}
                  </a>
                </div>

                {/* Phone Number */}
                <div className="flex items-center space-x-2">
                  <FaPhoneAlt className="mr-2 text-gray-500 text-[1.2vw] w-6" />
                  <a
                    href={`tel:+${userData.phone}`}
                    className="text-[1vw] hover:text-blue-600 cursor-pointer"
                  >
                    {userData.phone || "N/A"}
                  </a>
                </div>

                {/* Email */}
                <div className="flex items-center space-x-2">
                  <FaEnvelope className="mr-2 text-gray-500 text-[1.2vw] w-6" />
                  <a
                    href={`mailto:${userData.email}`}
                    className="text-[1vw] hover:text-blue-600 cursor-pointer"
                  >
                    {userData.email || "N/A"}
                  </a>
                </div>

                {/* Address */}
                <div className="flex items-start space-x-2">
                  <FaMapMarkerAlt className="mr-2 text-gray-500 text-[1.2vw] w-6" />
                  <a
                    href={`https://www.google.com/maps?q=${encodeURIComponent(
                      userData.address_line_1 || "N/A"
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[1vw] hover:text-blue-600 cursor-pointer"
                  >
                    {userData.address_line_1 || "N/A"}
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1   pl-20px-vw">
            <Card className=" rounded-[1vw]">
              <CardContent>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <Typography
                    variant="h6"
                    className="font-semibold text-gray-800 border-b-2 border-gray-300 pb-2"
                  >
                    User Details
                  </Typography>
                  {/* User Type and User Name */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <TextField
                        id="id"
                        name="id"
                        type="text"
                        label="User Id"
                        value={userData.id}
                        onChange={handleInputChange}
                        disabled
                        variant="outlined"
                        fullWidth
                        className={`rounded-md ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <FingerprintIcon className="mr-2 text-gray-500 " />
                          ),
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        id="userType"
                        name="userType"
                        label="User Type"
                        value={userData.userType}
                        variant="outlined"
                        fullWidth
                        disabled // Make this field non-editable
                        className={`rounded-md ${
                          isEditing ? "border-[#10131e]" : ""
                        }`}
                        InputProps={{
                          startAdornment: (
                            <PersonIcon className="mr-2 text-gray-500" />
                          ),
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        id="user_name"
                        name="user_name"
                        label="User Name"
                        value={userData.user_name}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md  ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <PersonIcon className="mr-2 text-gray-500" />
                          ),
                        }}
                      />
                    </div>

                    <div>
                      <TextField
                        id="village"
                        name="village"
                        type="text"
                        label="Address"
                        value={userData.village}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <LocationOnIcon className="mr-2 text-gray-500 " />
                          ),
                        }}
                      />
                    </div>
                  </div>

                  {/* Father Name, Phone, and Mobile */}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <TextField
                        id="father_name"
                        name="father_name"
                        label="Father Name"
                        value={userData.father_name}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                      />
                    </div>
                    <div>
                      <TextField
                        id="phone"
                        name="phone"
                        label="Phone"
                        value={userData.phone}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md  ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <PhoneIcon className="mr-2 text-gray-500" />
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className="rounded-md"
                      >
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                          id="status"
                          name="status"
                          label="Status"
                          value={userData.status}
                          onChange={handleInputChange}
                          disabled={!isEditing}
                          startAdornment={
                            userData.status === "Enable" ? (
                              <ToggleOn className="mr-2 text-green-500" />
                            ) : (
                              <ToggleOff className="mr-2 text-gray-500" />
                            )
                          }
                        >
                          <MenuItem value="Enable">Enable</MenuItem>
                          <MenuItem value="Disable">Disable</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <TextField
                        id="email"
                        name="email"
                        label="Email"
                        value={userData.email}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md  ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <EmailIcon className="mr-2 text-gray-500" />
                          ),
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label="Password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={userData.password}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        required
                        className={`rounded-md  ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Lock /> {/* Icon for Password */}
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={togglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <TextField
                      fullWidth
                      id="confirm_password"
                      label="Confirm Password"
                      name="confirm_password"
                      disabled={!isEditing}
                      type={showConfirmPassword ? "text" : "password"}
                      value={userData.confirmPassword}
                      onChange={handleInputChange}
                      required
                      className={`rounded-md  ${
                        !isEditing ? "" : "border-[#10131e]"
                      }`}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock /> {/* Icon for Confirm Password */}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={toggleConfirmPasswordVisibility}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <Typography
                    variant="h6"
                    className="font-semibold text-gray-800 border-b-2 border-gray-300 pb-2"
                  >
                    Bank Details
                  </Typography>
                  {/* User Type and User Name */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <TextField
                        id="bank_account_number"
                        name="bank_account_number"
                        type="text"
                        label="Account Number"
                        value={userData.bank_account_number}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <FingerprintIcon className="mr-2 text-gray-500 " />
                          ),
                        }}
                      />
                    </div>

                    <div>
                      <TextField
                        id="bank_acc_holder_name"
                        name="bank_acc_holder_name"
                        type="text"
                        label="Account Holder Name"
                        value={userData.bank_acc_holder_name}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <FingerprintIcon className="mr-2 text-gray-500 " />
                          ),
                        }}
                      />
                    </div>

                    {/* pending in api  */}
                    {/* <div>
                <TextField
                  id="preffered_terms"
                  name="preffered_terms"
                  type="text"
                  label="Preffered Payment Terms"
                  value={userData.preffered_terms}
                  onChange={handleInputChange}
                  disabled
                  variant="outlined"
                  fullWidth
                  className={`rounded-md ${
                    !isEditing ? "" : "border-[#10131e]"
                  }`}
                  InputProps={{
                    startAdornment: (
                      <FingerprintIcon className="mr-2 text-gray-500 " />
                    ),
                  }}
                />
              </div> */}

                    <div>
                      <TextField
                        id="gstin_number"
                        name="gstin_number"
                        label="GSTIN Number"
                        value={userData.gstin_number}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth
                        disabled={!isEditing}
                        className={`rounded-md ${
                          isEditing ? "border-[#10131e]" : ""
                        }`}
                        InputProps={{
                          startAdornment: (
                            <PersonIcon className="mr-2 text-gray-500" />
                          ),
                        }}
                      />
                    </div>

                    <div>
                      <TextField
                        id="IFSC"
                        name="IFSC"
                        type="text"
                        label="IFSC Number"
                        value={userData.IFSC}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <AccountBalanceIcon className="mr-2 text-gray-500 " />
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <Typography
                    variant="h6"
                    className="font-semibold text-gray-800 border-b-2 border-gray-300 pb-2"
                  >
                    Employee Details
                  </Typography>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className={`rounded-md ${
                          isEditing ? "border-[#10131e]" : ""
                        }`}
                      >
                        <InputLabel id="employee_type-label">
                          Employee Type
                        </InputLabel>
                        <Select
                          id="employee_type"
                          name="employee_type"
                          label="Employee Type"
                          value={userData.employee_type}
                          onChange={handleInputChange}
                          disabled={!isEditing}
                          startAdornment={
                            <PersonIcon className="mr-2 text-gray-500" />
                          }
                        >
                          <MenuItem value="Permanent">Permanent</MenuItem>
                          <MenuItem value="Vendor">Vendor</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div>
                      <TextField
                        id="employee_monthly_salary"
                        name="employee_monthly_salary"
                        type="text"
                        label="Employee Monthly Number"
                        value={userData.employee_monthly_salary}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <FingerprintIcon className="mr-2 text-gray-500 " />
                          ),
                        }}
                      />
                    </div>

                    <div>
                      <TextField
                        id="overtime_per_hour_salary"
                        name="overtime_per_hour_salary"
                        type="text"
                        label="Overtime Per Hour Salry"
                        value={userData.overtime_per_hour_salary}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <FingerprintIcon className="mr-2 text-gray-500 " />
                          ),
                        }}
                      />
                    </div>

                    <div>
                      <div>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className={`rounded-md ${
                            isEditing ? "border-[#10131e]" : ""
                          }`}
                        >
                          <InputLabel id="employee_type-label">
                            Payment SMS Reminder
                          </InputLabel>
                          <Select
                            id="payment_sms_reminder"
                            name="payment_sms_reminder"
                            label="Payment SMS Reminder"
                            value={userData.payment_sms_reminder}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                            startAdornment={
                              <PersonIcon className="mr-2 text-gray-500" />
                            }
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <Typography
                    variant="h6"
                    className="font-semibold text-gray-800 border-b-2 border-gray-300 pb-2"
                  >
                    Session Details
                  </Typography>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <TextField
                        id="start_login_time"
                        name="start_login_time"
                        label="Login Time"
                        value={userData.start_login_time}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md  ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <PersonIcon className="mr-2 text-gray-500" />
                          ),
                        }}
                      />
                    </div>

                    <div>
                      <TextField
                        id="end_login_time"
                        name="end_login_time"
                        type="text"
                        label="Logout Time"
                        value={userData.end_login_time}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        variant="outlined"
                        fullWidth
                        className={`rounded-md ${
                          !isEditing ? "" : "border-[#10131e]"
                        }`}
                        InputProps={{
                          startAdornment: (
                            <PersonIcon className="mr-2 text-gray-500" />
                          ),
                        }}
                      />
                    </div>
                  </div>

                  {/* Control Buttons */}
                  <div className="flex justify-between w-full items-end mt-6">
                    {isEditing && (
                      <CustomButton
                        onClick={handleSubmit}
                        variant="contained"
                        type="submit"
                        text="Save"
                        disabled={!isEditing}
                        bgColor="#0074ff"
                        textColor="#ffffff"
                        fontWeight={600}
                        borderRadius="8px"
                        padding="10px 20px"
                      />
                    )}
                  </div>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      )}

      <div className="flex-1 my-20px-vw bg-white px-20px-vw">
        <h2 className="text-[1.5vw] font-semibold text-gray-500  text-center py-28px-vw">
          Recent Purchase
        </h2>

        <DataTable
          columns={columns}
          pagination
          subHeader
          persistTableHead
          customStyles={{
            rows: {
              style: {
                minHeight: "60px",
                fontSize: "0.9vw",
              },
            },
            headCells: {
              style: {
                fontWeight: 600,
                textAlign: "center",
                whiteSpace: "normal",
                fontSize: "1vw",
                color: "#10131e",
              },
            },
          }}
        />
      </div>

      <div className="flex-1 my-20px-vw bg-white px-20px-vw">
        <h2 className="text-[1.5vw] font-semibold text-gray-500 text-center py-28px-vw">
          Recent Orders
        </h2>

        <DataTable
          columns={columnsOrder}
          pagination
          subHeader
          persistTableHead
          customStyles={{
            rows: {
              style: {
                minHeight: "60px",
                fontSize: "0.9vw",
              },
            },
            headCells: {
              style: {
                fontWeight: 600,
                textAlign: "center",
                whiteSpace: "normal",
                fontSize: "1vw",
                color: "#10131e",
              },
            },
          }}
        />
      </div>
      <div className="flex-1 my-20px-vw bg-white px-20px-vw">
        <h2 className="text-[1.5vw] font-semibold text-gray-500 text-center py-28px-vw">
          Assets Assigned
        </h2>

        <DataTable
          columns={assetsColumns}
          pagination
          subHeader
          persistTableHead
          customStyles={{
            rows: {
              style: {
                minHeight: "60px",
                fontSize: "0.9vw",
              },
            },
            headCells: {
              style: {
                fontWeight: 600,
                textAlign: "center",
                whiteSpace: "normal",
                fontSize: "1vw",
                color: "#10131e",
              },
            },
          }}
        />
      </div>
      <div className="flex-1 my-20px-vw bg-white px-20px-vw">
        <h2 className="text-[1.5vw] font-semibold text-gray-500 text-center py-28px-vw">
          Recent Payments
        </h2>

        <DataTable
          columns={paymentsColumns}
          pagination
          subHeader
          persistTableHead
          customStyles={{
            rows: {
              style: {
                minHeight: "60px",
                fontSize: "0.9vw",
              },
            },
            headCells: {
              style: {
                fontWeight: 600,
                textAlign: "center",
                whiteSpace: "normal",
                fontSize: "1vw",
                color: "#10131e",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default UserDetails;

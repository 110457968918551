import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { HiX, HiDotsVertical } from "react-icons/hi";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

// Table Component
const AllVendorTable = ({ attendanceData }) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const navigate = useNavigate();

  // Employee Type Cell with color styling
  const renderEmployeeType = (row) => {
    const typeStyle =
      row.employee_type === "permanent" ? "text-blue-600" : "text-green-600";
    return (
      <span className={`font-semibold ${typeStyle}`}>
        {row.employee_type || "N/A"}
      </span>
    );
  };

  // Actions Dropdown Menu
  const actionColumn = {
    name: "Actions",
    cell: (row) => (
      <Menu as="div" className="relative inline-block">
        <Menu.Button>
          <HiDotsVertical className="w-5 h-5 text-gray-500 cursor-pointer" />
        </Menu.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          {/* <Menu.Items className="absolute top-[-1vw] right-4 w-36 bg-white shadow-md rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-700"
                  } group flex items-center w-full px-4 py-2 text-sm`}
                  onClick={() => {
                    navigate(`/attendance/view/${row.id}`);
                  }}
                >
                  View
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-700"
                  } group flex items-center w-full px-4 py-2 text-sm`}
                  onClick={() => {
                    navigate(`/attendance/edit/${row.id}`);
                  }}
                >
                  Edit
                </button>
              )}
            </Menu.Item>
          </Menu.Items> */}
        </Transition>
      </Menu>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  };

  // Columns configuration
  const columns = [
    {
      name: "Vendor ID",
      selector: (row) => row.vendor_id || "N/A",
      sortable: true,
    },
    {
      name: "Vendor Type",
      cell: (row) => renderEmployeeType(row),
      sortable: true,
    },
    {
      name: "Vendor Name",
      selector: (row) => row.employee_name || "N/A",
      sortable: true,
    },
    {
      name: "Date From",
      selector: (row) => row.date_from || "N/A",
      sortable: true,
    },
    {
      name: "Date To",
      selector: (row) => row.date_To || "N/A",
      sortable: true,
    },
    {
      name: "Products",
      selector: (row) => row.products || "N/A",
      sortable: true,
    },
    {
      name: "Subitems",
      selector: (row) => row.subitems || "N/A",
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity || "N/A",
      sortable: true,
    },
    {
      name: "Per Amount",
      selector: (row) => row.per_amount || "N/A",
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.total_amount || "N/A",
      sortable: true,
    },
    actionColumn, // Actions column
  ];

  // Filter data based on input
  const filteredData = attendanceData.filter((item) => {
    const employeeName = item.employee_name || ""; // Default to empty string if undefined
    const employeeId = item.employee_id || ""; // Default to empty string if undefined

    return (
      employeeName.toLowerCase().includes(filterText.toLowerCase()) ||
      employeeId.toString().includes(filterText)
    );
  });

  const handleClear = () => {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText("");
  };

  // Sub-header search bar
  const subHeaderComponent = (
    <div className="flex justify-between items-center bg-white py-2 px-4">
      <div className="relative">
        <input
          className="border p-2 pr-8 bg-white rounded-md focus:outline-none"
          type="text"
          placeholder="Search by Employee Name/ID"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        {filterText && (
          <HiX
            className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer h-5 w-5 text-gray-500"
            onClick={handleClear}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="font-poppins">
      <div className="overflow-hidden bg-white rounded-md shadow-lg">
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponent}
          paginationResetDefaultPage={resetPaginationToggle}
          persistTableHead
          customStyles={{
            rows: {
              style: {
                minHeight: "50px",
                fontSize: "0.9rem",
                "&:hover": { backgroundColor: "#f9f9f9" },
              },
            },
            headCells: {
              style: {
                fontWeight: 600,
                textAlign: "center",
                fontSize: "1rem",
                color: "#10131e",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default AllVendorTable;

import React from "react";
import { FaBell, FaClock, FaRupeeSign, FaTimes } from "react-icons/fa";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const NotificationBell = ({
  notifications,
  onClose,
  notificationPermission,
}) => {
  const navigate = useNavigate();
  return (
    <div className="relative group">
      {/* Notification Bell Icon */}
      <div className="flex items-center justify-center bg-white border rounded-full shadow-md cursor-pointer w-[2.3vw] h-[2.3vw]">
        <FaBell className="text-gray-500 md:text-[1.2vw] text-[6vw]" />
      </div>

      {/* Notification Count Badge */}
      {notifications.length > 0 && (
        <div className="text-xs font-bold rounded-full flex items-center justify-center absolute w-[1.3vw] h-[1.3vw] -top-[0.26vw] -right-[0.26vw] bg-red-500 text-white">
          {notifications.length}
        </div>
      )}

      {/* Notification Panel (Visible on Hover) */}
      <div className="absolute right-0 bg-white shadow-lg rounded-lg p-[1vw] hidden group-hover:block z-10 w-[23vw] mt-[0.3vw]">
        <div className="flex items-center justify-between mb-[0.83vw]">
          <h2 className="font-bold text-[1.25vw] text-[#343e53]">
            Notifications
          </h2>
          <span className="text-16px-vw text-gray-500">
            {notifications.length > 0
              ? `You have ${notifications.length} unread notifications`
              : "No unread notifications"}
          </span>
        </div>

        {/* Conditional Rendering Based on Permissions and Notifications */}
        {notificationPermission?.read ? (
          notifications.length > 0 ? (
            <div className="min-h-[10vw] max-h-[20vw] overflow-y-auto space-y-[1vw] scroll-smooth custom-scrollbar pr-[0.5vw]">
              {notifications.map((notification) => {
                let formattedDate = "Invalid Date";
                if (notification.schedule) {
                  const date = new Date(notification.schedule);
                  if (!isNaN(date.getTime())) {
                    formattedDate = format(date, "dd MMM yyyy HH:mm");
                  }
                }

                return (
                  <div
                    key={notification.id}
                    className="flex items-center justify-between p-[1vw] border rounded-lg hover:bg-gray-50"
                    onClick={() => navigate("/payment")}
                  >
                    <div className="flex items-center space-x-[0.5vw]">
                      <div className="flex items-center justify-center bg-gray-100 rounded-full p-[0.7vw]">
                        <FaRupeeSign className="text-blue-500 text-[1vw] transition-all duration-300 ease-in-out transform hover:scale-110 hover:text-yellow-500 hover:shadow-lg hover:rotate-12" />
                      </div>
                      <div>
                        <h3 className="font-semibold text-[1vw]">
                          {notification.title}
                          <span className="font-normal text-gray-500 text-[0.83vw]">
                            {" "}
                            {notification.description}
                          </span>
                        </h3>
                        <div className="flex items-center text-gray-400 text-[0.73vw]">
                          <FaClock className="mr-[0.26vw]" />
                          {formattedDate}
                        </div>
                      </div>
                    </div>
                    {notificationPermission?.delete && (
                      <button onClick={() => onClose(notification.id)}>
                        <FaTimes className="hover:text-red-500 text-[1vw] text-gray-400" />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="text-center text-gray-500 text-[0.83vw]">
              No notifications available.
            </p>
          )
        ) : (
          <p className="text-center text-gray-500 text-[0.83vw]">
            You do not have permission to view this data.
          </p>
        )}
      </div>
    </div>
  );
};

export default NotificationBell;

import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import FormButton from "../../components/Button";
import { useOutletContext } from "react-router-dom";
import { getReq } from "../../apis/api";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loading";
import WarrentyTable from "../../components/DataTable/WarrentyTable";
import AddWarrenty from "../../components/Modal/ordermodal/AddWarrenty";
import ViewImages from "../../components/Modal/ordermodal/ViewImages";
import { useSelector } from "react-redux";

const WarrantyManagement = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [warranty, setWarranty] = useState([]);
  const [customers, setCustomers] = useState([]);
  const { permissions } = useOutletContext();
  const { roleName } = useSelector((state) => state.role);
  const warrantyPermission =
    permissions?.[roleName]?.modules?.["Sales and Service Management"]?.pages?.[
      "Warranty Management"
    ]?.sections?.["Warranty claims"];

  console.log("warrentypermission", warrantyPermission);

  const BASE_URL = "https://sgewbackend.reflomsolutions.com/uploads/";

  const fetchWarranty = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/fetchWarranty");

      if (response.status === 200) {
        const sortedDataDescending = response.data.sort((a, b) => b.id - a.id);
        setWarranty(sortedDataDescending);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in Fetching Warrenties");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOrders = async () => {
    try {
      const res = await getReq("/fetchOrders");
      const ordersData = res.data || [];

      // Extract product names from orders
      const allProductNames = ordersData.flatMap((order) => [
        ...(order.products || []).map((p) => p.product_name),
        ...(order.items || []).map((i) => i.product_name),
        ...(order.sub_items || []).map((s) => s.product_name),
      ]);

      // Remove duplicates and update state
      const uniqueProductNames = [...new Set(allProductNames)];
      console.log(uniqueProductNames, "uniqueProductNames");
      setOrders(ordersData);
      setProducts(
        uniqueProductNames.map((name, index) => ({
          id: index,
          product_id: index,
          product_name: name,
        }))
      );
    } catch (e) {
      console.error("Error fetching Orders:", e);
      toast.error("Error in fetching Orders");
    }
  };

  useEffect(() => {
    fetchWarranty();
    fetchOrders();
  }, []);

  const handleButtonClick = () => {
    setOpenModal(!openModal);
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "Claim ID",
      selector: (row) => row.claim_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Order ID",
      selector: (row) => row.order_id || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Customer",
      selector: (row) => row.customer_name || "N/A",
      sortable: true,
    },
    {
      name: "Products",
      selector: (row) =>
        Array.isArray(row.products)
          ? row.products.map((product) => product.product_name).join(", ")
          : "N/A",
      sortable: true,
    },
    {
      name: "Issue",
      cell: (row) => {
        // Check if 'products' exists and is an array
        if (Array.isArray(row.products) && row.products.length > 0) {
          // Extract all issue descriptions from the products array
          const allIssues = row.products.flatMap(
            (product) => product.issue_description || []
          );

          // If there are any issues, display the 'View' button
          return allIssues.length > 0 ? (
            <button
              onClick={() => handleViewImages(allIssues)}
              className="text-blue-500 underline"
            >
              View ({allIssues.length})
            </button>
          ) : (
            "N/A"
          );
        }

        // Fallback for no products
        return "N/A";
      },
      sortable: false,
    },
    {
      name: "Status",
      selector: (row) => row.claim_status,
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.claim_status === "success"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.claim_status === "Pending"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.claim_status || "N/A"}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
  ];

  const [openviewModal, setOpenViewModal] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);

  const handleViewImages = (issueDescription) => {
    try {
      // Ensure issueDescription is an array
      if (Array.isArray(issueDescription)) {
        const urls = issueDescription.map(
          (fileName) => `${BASE_URL}${fileName}`
        );
        setImageUrls(urls);
        setOpenViewModal(true); // Open the modal
      } else {
        console.error("Invalid issueDescription format:", issueDescription);
      }
    } catch (error) {
      console.error("Error processing issue_description:", error);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Sales-Services / Warranties"} />
        {warrantyPermission?.create && (
          <FormButton title="Add" func={handleButtonClick} />
        )}
      </div>

      <main>
        {isLoading ? (
          <Loader />
        ) : warrantyPermission?.read ? (
          <WarrentyTable
            warrentyPermissionUpdate={warrantyPermission?.update}
            columns={columns}
            data={warranty}
            onItemAdded={fetchWarranty}
            orders={orders}
            customers={customers}
            products={products}
          />
        ) : (
          <p className="text-gray-500 md:py-20px-vw md:text-[1.2vw] text-center font-bold">
            You do not have permission to view this data.
          </p>
        )}
      </main>
      {openModal && (
        <AddWarrenty
          open={openModal}
          handleClose={() => setOpenModal(false)}
          onItemAdded={fetchWarranty}
          orders={orders}
          products={products}
        />
      )}
      {openviewModal && (
        <ViewImages
          open={openviewModal}
          handleClose={() => setOpenViewModal(false)}
          imageUrls={imageUrls}
        />
      )}
    </div>
  );
};

export default WarrantyManagement;

import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_API_URL;

export const checkUser = (data) => {
  const formData = new FormData();

  formData.append("phone", data);
  return axios.post(`${BASE_URL}/checkuser`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postReq = (data, endPoint) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
}; 


export const postReqfileupload = (formData, endPoint) => {
  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`, // Ensure token is correctly passed
      "Content-Type": "multipart/form-data",
    },
  });
};

export const purchaseEditpostReq = (endPoint, formData) => {
  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postReqwithbilling = (data, endPoint) => {
  const formData = new FormData();

  // Helper function to append nested keys to FormData
  const appendFormData = (formData, data, parentKey = "") => {
    if (data && typeof data === "object" && !Array.isArray(data)) {
      Object.keys(data).forEach((key) => {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;
        appendFormData(formData, data[key], fullKey);
      });
    } else if (Array.isArray(data)) {
      data.forEach((item, index) => {
        const arrayKey = `${parentKey}[${index}]`;
        appendFormData(formData, item, arrayKey);
      });
    } else {
      formData.append(parentKey, data);
    }
  };

  // Populate FormData with data
  appendFormData(formData, data);

  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};
export const postReqVendor = async (endPoint, data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      data[key].forEach((item, index) => {
        Object.keys(item).forEach((subKey) => {
          formData.append(`${key}[${index}].${subKey}`, item[subKey]);
        });
      });
    } else {
      formData.append(key, data[key]);
    }
  });

  try {
    const response = await axios.post(`${BASE_URL}${endPoint}`, formData, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const postReqwithFile = (data, paymentScreenshot, endPoint) => {
  const formData = new FormData();

  // Append other form data fields, excluding 'payment_screenshot'
  Object.keys(data).forEach((key) => {
    if (key !== "payment_screenshot") {
      formData.append(key, data[key]);
    }
  });

  // Append the file explicitly
  if (paymentScreenshot) {
    formData.append(
      "payment_screenshot",
      paymentScreenshot,
      paymentScreenshot.name
    );
  }

  // Make the API request with FormData
  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data", // Ensure correct content-type
    },
  });
};

export const jsonpostReq = (data, endPoint) => {
  return axios.post(`${BASE_URL}${endPoint}`, data, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/json",
    },
  });
};

export const postReqwithIssueDesc = (data, endPoint) => {
  const formData = new FormData();

  // Handle top-level keys
  Object.keys(data).forEach((key) => {
    if (key === "products") {
      // Handle products array
      data.products.forEach((product, index) => {
        Object.keys(product).forEach((productKey) => {
          if (productKey === "issue_description") {
            // Handle issue_description as an array of files
            product[productKey].forEach((file, fileIndex) => {
              formData.append(`products[${index}][${productKey}][]`, file);
            });
          } else {
            // Handle other product fields
            formData.append(
              `products[${index}][${productKey}]`,
              product[productKey]
            );
          }
        });
      });
    } else {
      // Handle other top-level fields
      formData.append(key, data[key]);
    }
  });

  return axios
    .post(`${BASE_URL}${endPoint}`, formData, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((error) => {
      console.error("API Error:", error.response || error.message);
      throw error;
    });
};

export const nestedPostReq = (data, endPoint) => {
  const formData = new FormData();

  // Function to append items to FormData, supporting nested structures
  const appendFormData = (formData, data, parentKey = "") => {
    if (data && typeof data === "object" && !Array.isArray(data)) {
      Object.keys(data).forEach((key) => {
        appendFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else if (Array.isArray(data)) {
      data.forEach((item, index) => {
        appendFormData(formData, item, `${parentKey}[${index}]`);
      });
    } else {
      formData.append(parentKey, data);
    }
  };

  // Append all fields from the main data object
  appendFormData(formData, data);

  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
}; 

export const deleteReq = (endPoint) => {
  return axios.delete(`${BASE_URL}${endPoint}`, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/json",
    },
  });
};

export const rawPostReq = (data, endPoint) => {
  const formData = new FormData();

  // Function to append nested array items as separate fields
  const appendNestedArray = (array, key) => {
    array.forEach((item, index) => {
      Object.keys(item).forEach((subKey) => {
        formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
      });
    });
  };

  Object.keys(data).forEach((key) => {
    if (key === "product_images" && Array.isArray(data.product_images)) {
      // Append each image file in `product_images`
      data.product_images.forEach((file, index) => {
        if (file instanceof File) {
          formData.append(`product_images[${index}]`, file);
        }
      });
    } else if (key === "item_details") {
      // Append `item_details` array in the required format
      appendNestedArray(data.item_details, "item_details");
    } else if (key === "subitems") {
      // Append `subitems` array in the required format
      appendNestedArray(data.subitems, "subitems");
    } else {
      // Append other fields directly
      formData.append(key, data[key]);
    }
  });

  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postReqSecure = (data, endPoint) => {
  const urlSearchParams = new URLSearchParams();
  Object.keys(data).forEach((key) => {
    urlSearchParams.append(key, data[key]);
  });

  return axios.post(`${BASE_URL}${endPoint}`, urlSearchParams, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const putReq = (data, endPoint) => {
  const urlSearchParams = new URLSearchParams();
  Object.keys(data).forEach((key) => {
    urlSearchParams.append(key, data[key]);
  });

  return axios.put(`${BASE_URL}${endPoint}`, urlSearchParams, {
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const postReqLogin = (data, endPoint) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return axios.post(`${BASE_URL}${endPoint}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postReqLogout = (endPoint, token) => {
  return axios.post(
    `${BASE_URL}${endPoint}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getReq = async (endpoint) => {
  try {
    const response = await axios.get(`${BASE_URL}/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const searchReq = async (endpoint) => {
  try {
    const response = await axios.post(`${BASE_URL}/${endpoint}`, null, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getReq1 = async (endpoint) => {
  try {
    const response = await axios.get(`${BASE_URL}/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}  `,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

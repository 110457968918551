import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// Map role IDs to role names
const roleMap = {
  1: "Admin",
  2: "Sub Admin",
  3: "Accounts",
  4: "Purchase/Item Stock Mgr",
  5: "Supervisor",
  6: "Sales Manager",
  7: "Supplier",
  8: "Dealer",
  9: "Customer",
  10: "Employee",
};

// Extract the user's role from the cookie
const getRoleFromCookie = () => {
  try {
    const userCookie = Cookies.get("user");
    if (!userCookie) {
      throw new Error("User session not found. Please log in.");
    }
    const userData = JSON.parse(decodeURIComponent(userCookie));
    const roleId = userData.role_id;
    return roleMap[roleId] || null; 
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

const initialState = {
  roleName: getRoleFromCookie(), 
  permissions: null, 
};

// Create a slice for role management
export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.roleName = action.payload.roleName;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload.permissions;
    },
    resetRole: (state) => {
      state.roleName = null;
      state.permissions = null;
    },
  },
});

export const { setRole, setPermissions, resetRole } = roleSlice.actions;
export default roleSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import Button from "../../components/Button";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { BsPlusCircleDotted } from "react-icons/bs";
import { getReq, postReq } from "../../apis/api";
import { toast } from "react-toastify";
const AddVendorPayment = ({ open, handleClose }) => {
  const [employees, setEmployees] = useState([]); // Employee data from API
  const { control, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: {
      employee_id: "",
      employee_type: "Vendor",
      date_from: "",
      date_to: "",
      products: [],
      subitems: [],
      total_payment: 0,
    },
  });

  const {
    fields: productFields,
    append: appendProduct,
    remove: removeProduct,
  } = useFieldArray({ control, name: "products" });

  const {
    fields: subitemFields,
    append: appendSubitem,
    remove: removeSubitem,
  } = useFieldArray({ control, name: "subitems" });

  const watchEmployeeId = watch("employee_id");
  const watchDateFrom = watch("date_from");
  const watchDateTo = watch("date_to");
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await getReq("vendorEmployee");
        setEmployees(response.data);
      } catch (error) {
        console.error("Failed to fetch employees:", error);
      }
    };
    fetchEmployees();
  }, []);
  useEffect(() => {
    const fetchVendorPayments = async () => {
      if (watchEmployeeId && watchDateFrom && watchDateTo) {
        try {
          const payload = {
            employee_id: watchEmployeeId,
            date_from: watchDateFrom,
            date_to: watchDateTo,
          };
          const response = await postReq(payload, "/vendorPayment");
          console.log("API Response:", response);

          const { products = [], sub_items = [] } = response.data.data || {};

          // Debugging logs
          console.log(
            "Products before reset:",
            products,
            "Type:",
            typeof products,
            "Is Array:",
            Array.isArray(products)
          );
          console.log(
            "Sub Items before reset:",
            sub_items,
            "Type:",
            typeof sub_items,
            "Is Array:",
            Array.isArray(sub_items)
          );

          const safeProducts = Array.isArray(products) ? products : [];
          const safeSubItems = Array.isArray(sub_items) ? sub_items : [];

          console.log("Safe Productssssss:", safeProducts);
          console.log("Safe Sub Items:", safeSubItems);

          reset({
            employee_id: watchEmployeeId,
            employee_type: "Vendor",
            date_from: watchDateFrom,
            date_to: watchDateTo,
            products: safeProducts.map((p) => ({
              product_id: p.id || "",
              product_name: p.name || "",
              quantity: p.quantity || 0,
              date: "",
              per_product_amount: 0,
             
            })),
            subitems: safeSubItems.map((s) => ({
              subitem_id: s.id || "",
              subitem_name: s.name || "",
              quantity: s.quantity || 0,
              date: "",
              per_subitem_amount: 0,
              
            })),
       
          });

          // Log the reset payload
          console.log("Reset Payload:", {
            employee_id: watchEmployeeId,
            employee_type: "Vendor",
            date_from: watchDateFrom,
            date_to: watchDateTo,
            products: safeProducts,
            subitems: safeSubItems,
            
          });
        } catch (error) {
          console.error("Failed to fetch vendor payments:", error);
        }
      }
    };

    fetchVendorPayments();
  }, [watchEmployeeId, watchDateFrom, watchDateTo, reset]);

 
  const onSubmit = async (data) => {
    console.log("Form Data:", data);
  
    // Transform and sanitize data for the API payload
    const transformedData = {
      employee_id: String(data.employee_id), // Convert to string as required
      employee_type: data.employee_type,
      date_from: data.date_from,
      date_to: data.date_to,
      products: data.products.map((p) => ({
        id: String(p.product_id), // Ensure ID is a string
        name: p.product_name || "", // Default to an empty string if name is missing
        quantity: String(p.quantity || 0), // Ensure quantity is a string
        per_amount: parseFloat(p.per_product_amount || 0), // Convert to a float
        // total_amount: parseFloat(p.total_product_amount || 0),
      })),
      subitems: data.subitems.map((s) => ({
        id: String(s.subitem_id), // Ensure ID is a string
        name: s.subitem_name || "", // Default to an empty string if name is missing
        quantity: String(s.quantity || 0), // Ensure quantity is a string
        per_amount: parseFloat(s.per_subitem_amount || 0), // Convert to a float
        // total_amount: parseFloat(s.total_subitem_amount || 0),
      })),
      // total_payment: parseFloat(data.total_payment || 0), 
    };
  
    // Debug the transformed payload
    console.log("Transformed Payload:", JSON.stringify(transformedData, null, 2));
  
    try {
      const response = await postReq(`/addVendor`, transformedData, {
       
      });
      if(response.status === 200){
        toast.success(`Vendor Total payment is  ${response.total_amount}`);
      }
   
    } catch (error) {
      console.error("Error during submission:", error);
      toast.error(error);
      alert("An error occurred while submitting the form.");
    } finally {
      // Close modal only after successful request if applicable
      if (data.products.length > 0 || data.subitems.length > 0) {
        handleClose();
      }
    }
  };
  

  

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-[0.2vw]">
          <DialogTitle>
            <Typography variant="h6">Vendor Payment</Typography>
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent className="">
          <Grid container spacing={3}>
            {/* Employee Details */}
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="employee_id"
                control={control}
                rules={{ required: "Employee ID is required" }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Employee ID"
                    select
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  >
                    {employees.map((emp) => (
                      <MenuItem key={emp.id} value={emp.id}>
                        {emp.user_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="employee_type"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Employee Type"
                    fullWidth
                    disabled
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="date_from"
                control={control}
                rules={{ required: "Start date is required" }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Date From"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name="date_to"
                control={control}
                rules={{ required: "End date is required" }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Date To"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            {/* Total Payment */}
            {/* <Grid item xs={12} md={3}>
              <Controller
                name="total_payment"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Total Payment"
                    type="number"
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                )}
              />
            </Grid> */}
           <div className="pt-2">

           </div>
            {/* Product Section */}
            <Grid item xs={12}>
              <Typography variant="h6">Products</Typography>
            </Grid>
            {productFields.map((field, index) => (
              <React.Fragment key={field.id}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label="Product Name"
                    value={field.product_name}
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name={`products.${index}.quantity`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Quantity"
                        type="number"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.5}>
                  <Controller
                    name={`products.${index}.per_product_amount`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Per Product Amount"
                        type="number"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={2.5}>
                  <Controller
                    name={`products.${index}.total_product_amount`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Total Product Amount"
                        type="number"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        value={
                          (field.value =
                            productFields[index].quantity *
                              productFields[index].per_product_amount || 0)
                        }
                      />
                    )}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={1}>
                  <IconButton
                    onClick={() => removeProduct(index)}
                    color="error"
                    size="small"
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12} sm={6} md={2}>
              <IconButton
                className="text-blue-700"
                onClick={() =>
                  appendProduct({
                    product_id: "",
                    product_name: "",
                    quantity: 0,
                    per_product_amount: 0,
                  
                  })
                }
                color="success"
                size="medium"
              >
                <BsPlusCircleDotted className="text-blue-600" />
              </IconButton>
            </Grid>

            {/* Subitems Section */}
            <Grid item xs={12}>
              <Typography variant="h6">Subitems</Typography>
            </Grid>
            {subitemFields.map((field, index) => (
              <React.Fragment key={field.id}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label="Subitem Name"
                    value={field.subitem_name}
                    fullWidth
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name={`subitems.${index}.quantity`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Quantity"
                        type="number"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2.5}>
                  <Controller
                    name={`subitems.${index}.per_subitem_amount`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Per Subitem Amount"
                        type="number"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={2.5}>
                  <Controller
                    name={`subitems.${index}.total_subitem_amount`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Total Subitem Amount"
                        type="number"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        value={
                          (field.value =
                            subitemFields[index].quantity *
                              subitemFields[index].per_subitem_amount || 0)
                        }
                      />
                    )}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={1}>
                  <IconButton
                    onClick={() => removeSubitem(index)}
                    color="error"
                    size="small"
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12} sm={6} md={12}>
              <IconButton
                className="text-blue-700"
                onClick={() =>
                  appendSubitem({
                    subitem_id: "",
                    subitem_name: "",
                    quantity: 0,
                    per_subitem_amount: 0,
                  })
                }
                color="success"
                size="medium"
              >
                <BsPlusCircleDotted className="text-blue-600" />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            title="Cancel"
            func={handleClose}
            customcls={
              "bg-[#d0412e33] text-[#D0412E] border border-[#d0412e33]"
            }
          />
          <button type="submit">
            <Button title="Add" type="submit" />
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default AddVendorPayment;

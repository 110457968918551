import {
  FiTrendingUp,
  FiBarChart2,
  FiShoppingCart,
  FiLayers,
  FiDollarSign,
  FiClipboard,
  FiClock,
  FiBell,
  FiList,
  FiDownload,
} from "react-icons/fi"; 

export const sections = [
  {
    title: "Product Performance",
    description:
      "Analyze product sales trends, customer preferences, and revenue insights.",
    icon: <FiTrendingUp className="text-blue-600 text-3xl" />,
    link: "/dashboard/reporting/product-performance",
  },
  {
    title: "Estimated Product Report",
    description:
      "Get predictive analytics on product demand, stock levels, and future sales.",
    icon: <FiBarChart2 className="text-green-600 text-3xl" />,
    link: "/dashboard/reporting/product-report",
  },
  {
    title: "Purchase Performance",
    description:
      "Evaluate supplier efficiency, cost effectiveness, and procurement trends.",
    icon: <FiShoppingCart className="text-red-600 text-3xl" />,
    link: "/dashboard/reporting/purchase-performance",
  },
  {
    title: "Item / Sub-item Performance",
    description:
      "Deep dive into category-wise performance for better inventory management.",
    icon: <FiLayers className="text-purple-600 text-3xl" />,
    link: "/dashboard/reporting/item-subitem-performance",
  },
  {
    title: "Turnover Report",
    description:
      "Monitor cash flow, revenue, and expense summaries for financial clarity.",
    icon: <FiDollarSign className="text-yellow-600 text-3xl" />,
    modal: true,
  },
  {
    title: "Orders Performance",
    description:
      "Track order trends, fulfillment rates, and customer satisfaction levels.",
    icon: <FiClipboard className="text-cyan-600 text-3xl" />,
    modal: true,
  },
  {
    title: "Payment Due",
    description:
      "Keep track of pending invoices, due amounts, and customer payments.",
    icon: <FiClock className="text-orange-600 text-3xl" />,
    link: "/dashboard/reporting/payment-due-report",
  },
  // {
  //   title: "Stock Alerts",
  //   description:
  //     "Receive alerts on low-stock items to ensure seamless inventory management.",
  //   icon: <FiBell className="text-pink-600 text-3xl" />,
  //   link: "/dashboard/reporting/stock-alert",
  // },
  // {
  //   title: "Reminder",
  //   description:
  //     "Set up alerts for payments, deliveries, and important business tasks.",
  //   icon: <FiList className="text-indigo-600 text-3xl" />,
  //   link: "/dashboard/reporting/reminder",
  // },
  {
    title: "Price List",
    description:
      "Maintain an updated catalog of product prices for easy reference.",
    icon: <FiList className="text-green-700 text-3xl" />,
    link: "/dashboard/reporting/price-list",
  },
  {
    title: "Extract Data",
    description:
      "Download detailed reports on sales, purchases, payments, and users.",
    icon: <FiDownload className="text-gray-700 text-3xl" />,
    link: "/dashboard/reporting/extract-data",
  },
];

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Divider,
  Grid,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  FormControl,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { addDays, format } from "date-fns";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { Controller } from "react-hook-form";
import {
  CalendarToday,
  Receipt,
  Assignment,
  LocalShipping,
  FormatListNumbered,
  GpsFixed,
  Phone,
  Email,
  LocationOn,
  Public,
  Description,
  Business,
  Person,
  CurrencyRupee,
  Percent,
  Category,
  Discount,
  Scale,
} from "@mui/icons-material";
import PercentIcon from "@mui/icons-material/Percent";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AdjustIcon from "@mui/icons-material/Adjust";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import NoteIcon from "@mui/icons-material/Note";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  getReq,
  jsonpostReq,
  nestedPostReq,
  postReq,
  postReqwithbilling,
  rawPostReq,
} from "../../apis/api";
import { toast } from "react-toastify";
import { BsPlusCircleDotted } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import FormButton from "../../components/Button";
import Loader from "../../components/loader/Loading";
import { orderSchema } from "../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { toWords } from "number-to-words";

const CreateService = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { serviceId } = useParams();
  const [customers, setCustomers] = useState([]);
  const [claimId, setClaimIds] = useState([]);
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [subItems, setSubitems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const todayDate = format(new Date(), "yyyy-MM-dd");
  const estimatedDeliveryDate = format(addDays(new Date(), 3), "yyyy-MM-dd");

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: todayDate,
      claim_id: "",
      customer: {
        billing: {
          id: "",
          company: "",
          gst: "",
          address1: "",
          address2: "",
          city: "",
          postcode: "",
          country: "",
          state: "",
          email: "",
          phone: "",
        },
        shipping: {
          id: "",
          company: "",
          gst: "",
          address1: "",
          address2: "",
          city: "",
          postcode: "",
          country: "",
          state: "",
          email: "",
          phone: "",
        },
      },
      products: [
        {
          product_name: "",
          product_quantity: "",
          product_description: "",
        },
      ],
      items: [
        {
          serial_number: "",
          id: "",
          hsn_code: "",
          quantity: "",
          sell_price: "",
          gst_amount: "",
          gst_percent: "",
          discount: "",
          amount: "",
        },
      ],
      sub_items: [
        {
          serial_number: "",
          id: "",
          hsn_code: "",
          quantity: "",
          sell_price: "",
          gst_amount: "",
          gst_percent: "",
          discount: "",
          amount: "",
        },
      ],
      discount: "",
      service_labour_charge: "",
      igst: "",
      cgst: "",
      sgst: "",
      shipping_amount: "",
      rounded_off_amount: "",
      total_amount: "",
      order_note: "Handle with care",
      estimated_delivery_date: estimatedDeliveryDate,
    },
  });

  // const {
  //   fields: productFields,
  //   append: addProduct,
  //   remove: removeProduct,
  // } = useFieldArray({
  //   control,
  //   name: "orderSummary",
  // });

  const fetchCustomers = async () => {
    try {
      const res = await getReq("/fetchCustomer");
      setCustomers(res.data || []);
    } catch (e) {
      console.error("Error fetching categories:", e);
      toast.error("Error in fetching categories");
    }
  };

  const fetchItems = async () => {
    try {
      const res = await getReq("/fetchItems");
      setItems(res.data || []);
    } catch (e) {
      console.error("Error fetching Items:", e);
      toast.error("Error in fetching Items");
    }
  };

  const fetchSubitems = async () => {
    try {
      const res = await getReq("/subitems");
      setSubitems(res.data || []);
    } catch (e) {
      console.error("Error fetching Items:", e);
      toast.error("Error in fetching Items");
    }
  };

  const setClaimIdValues = (selectedClaimId) => {
    const selectedClaim = claimId.find((i) => i.claim_id === selectedClaimId); // Find the claim by ID

    if (selectedClaim && Array.isArray(selectedClaim.products)) {
      // Manually reset the products array
      setValue("products", []); // Clear all existing product fields

      // Add each product to the products array
      selectedClaim.products.forEach((product, index) => {
        setValue(`products.${index}.product_name`, product.product_name || "");
        setValue(
          `products.${index}.product_quantity`,
          product.product_quantity || 0
        );
        setValue(
          `products.${index}.product_description`,
          product.product_description || ""
        );
      });

      // setValue("customer.billing.id", selectedCustomer.id || "");
      setValue("customer.billing.id", selectedClaim.customer_name || "");
      setValue("customer.billing.email", selectedClaim.email || "");
      setValue("customer.billing.company", selectedClaim.company || "");
      setValue("customer.billing.gst", selectedClaim.gstin_number || "");
      setValue("customer.billing.address1", selectedClaim.address_line_1 || "");
      setValue("customer.billing.address2", selectedClaim.address_line_2 || "");
      setValue("customer.billing.city", selectedClaim.village || "");
      setValue("customer.billing.postcode", selectedClaim.district || "");
      setValue("customer.billing.country", selectedClaim.country || "");
      setValue("customer.billing.state", selectedClaim.state || "");
      setValue("customer.billing.email", selectedClaim.email || "");
      setValue(
        "customer.billing.phone",
        selectedClaim.phone || selectedClaim.mobile || ""
      );
      setValue("customer.shipping.id", selectedClaim.customer_name || "");
      setValue("customer.shipping.email", selectedClaim.email || "");
      setValue("customer.shipping.company", selectedClaim.company || "");
      setValue("customer.shipping.gst", selectedClaim.gstin_number || "");
      setValue(
        "customer.shipping.address1",
        selectedClaim.address_line_1 || ""
      );
      setValue(
        "customer.shipping.address2",
        selectedClaim.address_line_2 || ""
      );
      setValue("customer.shipping.city", selectedClaim.village || "");
      setValue("customer.shipping.postcode", selectedClaim.district || "");
      setValue("customer.shipping.country", selectedClaim.country || "");
      setValue("customer.shipping.state", selectedClaim.state || "");
      setValue("customer.shipping.email", selectedClaim.email || "");
      setValue(
        "customer.shipping.phone",
        selectedClaim.phone || selectedClaim.mobile || ""
      );
    } else {
      console.error("No products found or invalid data structure.");
    }
  };

  const calculateGstAmount = (amount, gstPercentage) => {
    return ((amount * gstPercentage) / 100).toFixed(2);
  };

  console.log("productId", serviceId);
  const fetchOrderData = async () => {
    try {
      const res = await getReq(`/service/${serviceId}`);
      const productData = res.data;
  
      if (!productData) {
        throw new Error("No product data found in the response.");
      }
  
      // Parse `customer`, `products`, `items`, and `sub_items` fields safely
      let customerData = {};
      if (productData.customer) {
        try {
          customerData = JSON.parse(productData.customer);
        } catch (err) {
          console.error("Error parsing customer data:", err);
        }
      }
  
      let orderSummaryData = [];
      if (productData.products) {
        try {
          orderSummaryData = JSON.parse(productData.products);
        } catch (err) {
          console.error("Error parsing products data:", err);
        }
      }
  
      let itemsData = [];
      if (productData.items) {
        try {
          itemsData = JSON.parse(productData.items);
        } catch (err) {
          console.error("Error parsing items data:", err);
        }
      }
  
      let subItemsData = [];
      if (productData.sub_items) {
        try {
          subItemsData = JSON.parse(productData.sub_items);
        } catch (err) {
          console.error("Error parsing sub-items data:", err);
        }
      }
  
      // Prepare the form data
      const formData = {
        date: productData.date || todayDate,
        claim_id: productData.claim_id || "",
        customer: {
          billing: {
            id: customerData.billing?.id || "",
            company: customerData.billing?.company || "",
            gst: customerData.billing?.gst || "",
            address1: customerData.billing?.address1 || "",
            address2: customerData.billing?.address2 || "",
            city: customerData.billing?.city || "",
            postcode: customerData.billing?.postcode || "",
            country: customerData.billing?.country || "",
            state: customerData.billing?.state || "",
            email: customerData.billing?.email || "",
            phone: customerData.billing?.phone || "",
          },
          shipping: {
            id: customerData.shipping?.id || "",
            company: customerData.shipping?.company || "",
            gst: customerData.shipping?.gst || "",
            address1: customerData.shipping?.address1 || "",
            address2: customerData.shipping?.address2 || "",
            city: customerData.shipping?.city || "",
            postcode: customerData.shipping?.postcode || "",
            country: customerData.shipping?.country || "",
            state: customerData.shipping?.state || "",
            email: customerData.shipping?.email || "",
            phone: customerData.shipping?.phone || "",
          },
        },
        products: orderSummaryData.map((product) => ({
          product_name: product.product_name || "",
          product_quantity: product.product_quantity || "",
          product_description: product.product_description || "",
        })),
        items: itemsData.map((item) => ({
          serial_number: item.serial_number || "",
          id: item.id || "",
          hsn_code: item.hsn_code || "",
          quantity: item.quantity || "",
          sell_price: item.sell_price || "",
          gst_amount: item.gst_amount || "",
          gst_percent: item.gst_percent || "",
          discount: item.discount || "",
          amount: item.amount || "",
        })),
        sub_items: subItemsData.map((subItem) => ({
          serial_number: subItem.serial_number || "",
          id: subItem.id || "",
          hsn_code: subItem.hsn_code || "",
          quantity: subItem.quantity || "",
          sell_price: subItem.sell_price || "",
          gst_amount: subItem.gst_amount || "",
          gst_percent: subItem.gst_percent || "",
          discount: subItem.discount || "",
          amount: subItem.amount || "",
          sgew_price: subItem.sgew_price || "",
        })),
        discount: productData.discount || "",
        service_labour_charge: productData.service_labour_charge || "",
        igst: productData.igst || "",
        cgst: productData.cgst || "",
        sgst: productData.sgst || "",
        shipping_amount: productData.shipping_amount || "",
        rounded_off_amount: productData.rounded_off_amount || "",
        total_amount: productData.total_amount || "",
        order_note: productData.order_note || "Handle with care",
        estimated_delivery_date:
          productData.estimated_delivery_date || estimatedDeliveryDate,
      };
  
      // Reset the form with the structured data
      reset(formData);
      // console.log("Form data set:", formData);
    } catch (error) {
      console.error("Error fetching order data:", error);
      toast.error("Failed to load order data");
    }
  };
  

  const setCustomerValues = (selectedCustomerId, customers, setValue) => {
    const selectedCustomer = customers.find(
      (customer) => customer.id === selectedCustomerId
    );

    if (selectedCustomer) {
      // Set billing address fields
      setValue("customer.shipping.id", selectedCustomer.id || "");
      setValue("customer.billing.company", selectedCustomer.company || "");
      setValue("customer.billing.gst", selectedCustomer.gstin_number || "");
      setValue(
        "customer.billing.address1",
        selectedCustomer.address_line_1 || ""
      );
      setValue(
        "customer.billing.address2",
        selectedCustomer.address_line_2 || ""
      );
      setValue("customer.billing.city", selectedCustomer.village || "");
      setValue("customer.billing.postcode", selectedCustomer.postcode || "");
      setValue("customer.billing.country", selectedCustomer.country || "");
      setValue("customer.billing.state", selectedCustomer.state || "");
      setValue("customer.billing.email", selectedCustomer.email || "");
      setValue("customer.billing.phone", selectedCustomer.phone || "");

      // Set shipping address fields
      setValue("customer.shipping.company", selectedCustomer.company || "");
      setValue("customer.shipping.gst", selectedCustomer.gstin_number || "");
      setValue(
        "customer.shipping.address1",
        selectedCustomer.address_line_1 || ""
      );
      setValue(
        "customer.shipping.address2",
        selectedCustomer.address_line_2 || ""
      );
      setValue("customer.shipping.city", selectedCustomer.village || "");
      setValue("customer.shipping.postcode", selectedCustomer.postcode || "");
      setValue("customer.shipping.country", selectedCustomer.country || "");
      setValue("customer.shipping.state", selectedCustomer.state || "");
      setValue("customer.shipping.email", selectedCustomer.email || "");
      setValue("customer.shipping.phone", selectedCustomer.phone || "");
    }
  };

  useEffect(() => {
    fetchCustomers();
    fetchClaimIds();
    fetchItems();
    fetchSubitems();
    if (serviceId) {
      fetchOrderData();
    }
  }, [serviceId]);

  // const handleDiscountChange = (index, discountValue) => {
  //   const sgewPrice = parseFloat(watch(`orderSummary.${index}.rate`)) || 0; // Original price
  //   const gstPercentage =
  //     parseFloat(watch(`orderSummary.${index}.gstPercentage`)) || 0; // GST %

  //   // Parse and validate the discount value
  //   const discount = parseFloat(discountValue) || 0;

  //   // Calculate net price after discount
  //   const netPrice = sgewPrice - discount;

  //   // Calculate GST amount
  //   const gstAmount = calculateGstAmount(netPrice, gstPercentage);
  //   // Calculate total amount (net price + GST)
  //   const totalAmount = netPrice + parseFloat(gstAmount);

  //   // Update the values in the form state
  //   setValue(`orderSummary.${index}.gst`, gstAmount);
  //   setValue(`orderSummary.${index}.amount`, totalAmount);
  // };

  const fetchClaimIds = async () => {
    try {
      const res = await getReq("/fetchWarranty");
      setClaimIds(res.data || []);
    } catch (e) {
      console.error("Error fetching categories:", e);
      toast.error("Error in fetching categories");
    }
  };

  const {
    fields: itemFields,
    append: addItem,
    remove: removeItem,
  } = useFieldArray({
    control,
    name: "items",
  });

  const { fields, append } = useFieldArray({
    control,
    name: "products", // Bind the products array
  });

  const {
    fields: subItemFields,
    append: addSubItem,
    remove: removeSubItem,
  } = useFieldArray({
    control,
    name: "sub_items",
  });

  const calculateValues = (index) => {
    const quantity = watch(`items.${index}.quantity`) || 1; // Default to 1
    const discount = watch(`items.${index}.discount`) || 0; // Default to 0
    const sellPrice = watch(`items.${index}.sell_price`) || 200; // Use backend value or fallback
    const gstPercent = watch(`items.${index}.gst_percent`) || 18; // Use backend value or fallback

    const totalSellPrice = sellPrice * quantity;
    const gstAmount = ((totalSellPrice - discount) * gstPercent) / 100;
    const amount = totalSellPrice - discount + gstAmount;

    setValue(`items.${index}.gst_amount`, gstAmount);
    setValue(`items.${index}.amount`, amount);
  };

  const calculateSubItemValues = (index) => {
    const quantity = watch(`sub_items.${index}.quantity`) || 1; // Default to 1
    const discount = watch(`sub_items.${index}.discount`) || 0; // Default to 0
    const sellPrice = watch(`sub_items.${index}.sell_price`) || 200; // Use backend value or fallback
    const gstPercent = watch(`sub_items.${index}.gst_percent`) || 18; // Use backend value or fallback

    const totalSellPrice = sellPrice * quantity;
    const gstAmount = ((totalSellPrice - discount) * gstPercent) / 100;
    const amount = totalSellPrice - discount + gstAmount;

    // Update form values dynamically
    setValue(`sub_items.${index}.gst_amount`, gstAmount);
    setValue(`sub_items.${index}.amount`, amount);
  };

  const handleItemChange = (index, selectedItem) => {
    const item = items.find((i) => i.item_id === selectedItem);

    if (item) {
      const sellPrice = parseFloat(item.sell_price) || 200; // Use backend sell price or fallback to 200
      const gstPercent = parseFloat(item.default_tax_percentage) || 10; // Use backend GST or fallback to 18
      const defaultQuantity = 1;
      const defaultDiscount = 0;

      // Set initial values
      setValue(`items.${index}.id`, Number(item.item_id));
      setValue(`items.${index}.hsn_code`, item.hsn);

      setValue(`items.${index}.quantity`, defaultQuantity);
      setValue(`items.${index}.sell_price`, sellPrice);
      setValue(`items.${index}.discount`, defaultDiscount);

      // Calculate initial GST and amount
      const gstAmount = (sellPrice * gstPercent) / 100;
      const amount = sellPrice + gstAmount;

      setValue(`items.${index}.gst_percent`, gstPercent);
      setValue(`items.${index}.gst_amount`, gstAmount);
      setValue(`items.${index}.amount`, amount);
    }
  };

  const handleSubItemChange = (index, selectedSubItem) => {
    const subItem = subItems.find((i) => i.id === selectedSubItem);

    if (subItem) {
      const sellPrice = parseFloat(subItem.sell_price) || 150; // Use backend sell price or fallback to 150
      const gstPercent = parseFloat(subItem.default_tax_percentage) || 12; // Use backend GST or fallback to 12
      const defaultQuantity = 1;
      const defaultDiscount = 0;

      // Calculate initial GST and amount
      const gstAmount = (sellPrice * gstPercent) / 100;
      const amount = sellPrice + gstAmount;

      // Set values for each required field in the form
      setValue(`sub_items.${index}.id`, subItem.id);
      setValue(`sub_items.${index}.hsn_code`, subItem.hsn_code || subItem.hsn); // Use `hsn_code` if available, else fallback to `hsn`
      setValue(`sub_items.${index}.quantity`, defaultQuantity);
      setValue(`sub_items.${index}.sell_price`, sellPrice);
      setValue(`sub_items.${index}.discount`, defaultDiscount);
      setValue(`sub_items.${index}.gst_percent`, gstPercent);
      setValue(`sub_items.${index}.gst_amount`, gstAmount);
      setValue(`sub_items.${index}.amount`, amount);
      setValue(
        `sub_items.${index}.sgew_price`,
        parseFloat(subItem.sgew_price) || 0
      ); // Fallback to 0 if `sgew_price` is unavailable
    }
  };

  // Watch the form fields
  // const productsuse = watch("orderSummary");
  const shippingAmountuse = watch("shipping_amount");
  const itemsuse = watch("items");
  const subItemsuse = watch("sub_items");
  const serviceLabourCharge = watch("service_labour_charge");
  const igst = watch("igst");
  const cgst = watch("cgst");
  const sgst = watch("sgst");

  useEffect(() => {
    const calculateTotals = () => {
      // Calculate total discount across all products, items, and sub-items
      const totalDiscount = [
        ...itemsuse.map((i) => parseFloat(i.discount) || 0),
        ...subItemsuse.map((s) => parseFloat(s.discount) || 0),
      ].reduce((acc, discount) => acc + discount, 0);

      // Calculate total amount before adding additional charges
      const totalAmountBeforeCharges = [
        ...itemsuse.map((i) => parseFloat(i.discount) || 0),
        ...subItemsuse.map((s) => parseFloat(s.discount) || 0),
      ].reduce((acc, amount) => acc + amount, 0);

      // Calculate the total amount including all charges and shipping
      const totalAmount =
        totalAmountBeforeCharges +
        parseFloat(shippingAmountuse || 0) +
        parseFloat(serviceLabourCharge || 0) +
        parseFloat(igst || 0) +
        parseFloat(cgst || 0) +
        parseFloat(sgst || 0);
      // Round off total amount to nearest integer
      const roundedOffAmount = Math.round(totalAmount);

      // Set values to the form fields
      setValue("discount", totalDiscount);
      setValue("total_amount", totalAmount);
      setValue("rounded_off_amount", roundedOffAmount);
    };

    calculateTotals();
  }, [
    items,
    subItemsuse,
    shippingAmountuse,
    serviceLabourCharge,
    igst,
    cgst,
    sgst,
  ]);

  const onSubmit = async (data) => {
    console.log("service data in normal form");
    console.log(" string", data);
    setIsLoading(true);

    const apiUrl = serviceId
      ? `/service/update/${serviceId}`
      : "/createService"; // Determine API endpoint

    try {
      const response = await postReqwithbilling(data, apiUrl);

      // Handle successful responses
      if (response.status === 200 || response.status === 201) {
        toast.success(
          serviceId
            ? "Service Order Updated Successfully"
            : "Service Order Created successfully"
        );

        reset(); // Assuming you have a form reset function
        navigate(-1); // Go back to the previous page
      } else {
        toast.error(response.data.message || "Operation failed.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response?.data?.messages) {
        Object.values(error.response.data.messages).forEach((msg) => {
          toast.error(msg, {
            position: "top-right",
            autoClose: 5000,
          });
        });
      } else {
        toast.error("Something went wrong. Please try again.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        width: "100%",
        margin: "0 auto",
        padding: "20px",
        background: "#f9f9f9",
        borderRadius: "8px",
      }}
    >
      <Grid container spacing={3}>
        {isLoading && <Loader />}
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "28px",
            padding: "20px",
          }}
        >
          {/* Back Icon Button */}
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => navigate(-1)} // Go back to the previous page
            aria-label="back"
            sx={{ position: "absolute", left: "20px", ml: "20px" }} // Add margin-left here
          >
            <ArrowBackIcon />
          </IconButton>

          {/* Product Information Text */}
          <Typography variant="h5" gutterBottom sx={{ flexGrow: 1 }}>
            Service Information
          </Typography>
          <Divider />
        </Grid>

        {/* Order Date */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                type="date"
                label="Date"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarToday />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.date}
                // helperText={errors.date?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth required variant="outlined">
            <Controller
              name="claim_id"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Claim ID"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FormatListNumbered />
                      </InputAdornment>
                    ),
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          maxHeight: 250,
                        },
                      },
                    },
                  }}
                  onChange={(event) => {
                    const selectedClaimId = event.target.value;
                    field.onChange(selectedClaimId); // Update field value in react-hook-form
                    setClaimIdValues(selectedClaimId); // Populate form fields dynamically
                  }}
                >
                  <MenuItem value="" disabled>
                    Select a Claim Id
                  </MenuItem>
                  {claimId.map((customer) => (
                    <MenuItem key={customer.id} value={customer.claim_id}>
                      {customer.claim_id}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Serviceable Items
          </Typography>
          <Divider />
        </Grid>

        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            {/* Product Name */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={`products.${index}.product_name`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Product Name"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            {/* Product Quantity */}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name={`products.${index}.product_quantity`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Quantity"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      min: 0,
                    }}
                  />
                )}
              />
            </Grid>

            {/* Product Description */}
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                name={`products.${index}.product_description`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Product Description"
                    multiline
                    rows={2}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Billing Details
          </Typography>
          <Divider />
        </Grid>

        {/* customer name dropdown  */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.billing.id"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Customer Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FormatListNumbered />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        {/* Company */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.billing.company"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Company"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.billing?.company}
                // helperText={errors.customer?.billing?.company?.message}
              />
            )}
          />
        </Grid>

        {/* GST Number */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.billing.gst"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="GST Number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Description />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.billing?.gst}
                // helperText={errors.customer.billing.gst?.message}
              />
            )}
          />
        </Grid>

        {/* Address Line 1 */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.billing.address1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Address Line 1"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.billing?.address1}
                // helperText={errors.customer?.billing?.address1?.message}
              />
            )}
          />
        </Grid>

        {/* Address Line 2 */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.billing.address2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Address Line 2"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.billing?.address2}
                // helperText={errors.customer?.billing.address2?.message}
              />
            )}
          />
        </Grid>

        {/* City */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.billing.city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="City"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.billing?.city}
                // helperText={errors.customer?.billing?.city?.message}
              />
            )}
          />
        </Grid>

        {/* Postal Code */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.billing.postcode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Postal Code"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GpsFixed />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.billing?.postcode}
                // helperText={errors.customer?.billing?.postcode?.message}
              />
            )}
          />
        </Grid>

        {/* Country */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.billing.country"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Country"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Public />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.billing?.country}
                // helperText={errors.customer?.billing?.country?.message}
              />
            )}
          />
        </Grid>

        {/* State */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.billing.state"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="State"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.billing?.state}
                // helperText={errors.customer?.billing?.state?.message}
              />
            )}
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.billing.email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.billing?.email}
                // helperText={errors.customer?.billing?.email?.message}
              />
            )}
          />
        </Grid>

        {/* Phone */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.billing.phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Phone"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.billing?.phone}
                // helperText={errors.customer?.billing?.phone?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Items Detail
          </Typography>
          <Divider />
        </Grid>

        {itemFields?.map((item, index) => (
          <Grid container item spacing={2} key={item.id}>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth required variant="outlined">
                <Controller
                  name={`items.${index}.id`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Item Name"
                      placeholder="Select Item"
                      type="number"
                      onChange={(e) => {
                        field.onChange(e);
                        handleItemChange(index, e.target.value); // Auto-fill weight on selection
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              maxHeight: 250,
                            },
                          },
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Category />
                          </InputAdornment>
                        ),
                      }}
                      // error={!!errors.items?.[index]?.id}
                      // helperText={errors.items?.[index]?.id?.message}
                    >
                      <MenuItem value="" disabled>
                        Select an Item
                      </MenuItem>
                      {items?.map((category) => (
                        <MenuItem key={category?.id} value={category?.item_id}>
                          {category?.item_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={1.5}>
              <Controller
                name={`items.${index}.hsn_code`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="HSN Code"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                      readOnly: true,
                    }}
                    // error={!!errors.items?.[index]?.hsn_code}
                    // helperText={errors.items?.[index]?.hsn_code?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`items.${index}.sell_price`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Sell Price"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    // error={!!errors.items?.[index]?.sell_price}
                    // helperText={errors.items?.[index]?.sell_price?.message}
                    inputProps={{
                      readOnly: true,
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Controller
                name={`items.${index}.quantity`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Quantity"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Scale />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      calculateValues(index); // Trigger dynamic calculation
                    }}
                    // error={!!errors.items?.[index]?.quantity}
                    // helperText={errors.items?.[index]?.quantity?.message}
                    inputProps={{
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Controller
                name={`items.${index}.discount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Discount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Discount />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      calculateValues(index); // Trigger dynamic calculation
                    }}
                    // error={!!errors.items?.[index]?.discount}
                    // helperText={errors.items?.[index]?.discount?.message}
                    inputProps={{
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1.5}>
              <Controller
                name={`items.${index}.gst_amount`}
                control={control}
                render={({ field }) => {
                  // Watch gst_percent dynamically
                  const gstPercent = watch(`items.${index}.gst_percent`) || 0;

                  return (
                    <TextField
                      {...field}
                      fullWidth
                      label={`GST Amount (${gstPercent}%)`}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Percent />
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                      // error={!!errors.items?.[index]?.gst_amount}
                      // helperText={errors.items?.[index]?.gst_amount?.message}
                      inputProps={{
                        min: 0, // Ensure the value can't go below 0
                      }}
                      onInput={(e) => {
                        // Prevent negative numbers
                        if (parseInt(e.target.value) < 0) {
                          e.preventDefault();
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`items.${index}.amount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Amount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    // error={!!errors.items?.[index]?.amount}
                    // helperText={errors.items?.[index]?.amount?.message}
                    inputProps={{
                      readOnly: true,
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              className="flex items-center gap-3"
            >
              <IconButton onClick={() => removeItem(index)}>
                <MdDeleteForever size={30} style={{ color: "#d0412e" }} />
              </IconButton>
              {index === itemFields.length - 1 && (
                <IconButton
                  onClick={() =>
                    addItem({
                      serial_number: "",
                      id: "",
                      hsn_code: "",
                      quantity: "",
                      sell_price: "",
                      gst_amount: "",
                      gst_percent: "",
                      discount: "",
                      amount: "",
                    })
                  }
                  color="primary"
                >
                  <BsPlusCircleDotted size={30} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            SubItems Detail
          </Typography>
          <Divider />
        </Grid>

        {subItemFields?.map((subItem, index) => (
          <Grid container item spacing={2} key={subItem.id}>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth required variant="outlined">
                <Controller
                  name={`sub_items.${index}.id`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Sub Item Name"
                      onChange={(e) => {
                        field.onChange(e);
                        handleSubItemChange(index, e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Category />
                          </InputAdornment>
                        ),
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: { sx: { maxHeight: 250 } },
                        },
                      }}
                      // error={!!errors.sub_items?.[index]?.id}
                      // helperText={errors.sub_items?.[index]?.id?.message}
                    >
                      <MenuItem value="" disabled>
                        Select a Sub Item
                      </MenuItem>
                      {subItems?.map((subCategory) => (
                        <MenuItem key={subCategory?.id} value={subCategory?.id}>
                          {subCategory?.subitem_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={1.5}>
              <Controller
                name={`sub_items.${index}.hsn_code`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="HSN Code"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                      readOnly: true,
                    }}
                    // error={!!errors.sub_items?.[index]?.hsn_code}
                    // helperText={errors.sub_items?.[index]?.hsn_code?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`sub_items.${index}.sell_price`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Sell Price"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    // error={!!errors.sub_items?.[index]?.sell_price}
                    // helperText={errors.sub_items?.[index]?.sell_price?.message}
                    inputProps={{
                      readOnly: true,
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Controller
                name={`sub_items.${index}.quantity`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Quantity"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      calculateSubItemValues(index); // Recalculate values
                    }}
                    // error={!!errors.sub_items?.[index]?.quantity}
                    // helperText={errors.sub_items?.[index]?.quantity?.message}
                    inputProps={{
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Controller
                name={`sub_items.${index}.discount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Discount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      calculateSubItemValues(index); // Recalculate values
                    }}
                    // error={!!errors.sub_items?.[index]?.discount}
                    // helperText={errors.sub_items?.[index]?.discount?.message}
                    inputProps={{
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={1.5}>
              <Controller
                name={`sub_items.${index}.gst_amount`}
                control={control}
                render={({ field }) => {
                  const gstPercent =
                    watch(`sub_items.${index}.gst_percent`) || 0; // Watch for GST percent value

                  return (
                    <TextField
                      {...field}
                      fullWidth
                      label={`GST Amount (${gstPercent}%)`} // Combine label with GST percent
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupee />
                          </InputAdornment>
                        ),
                      }}
                      // error={!!errors.sub_items?.[index]?.gst_amount}
                      // helperText={
                      //   errors.sub_items?.[index]?.gst_amount?.message
                      // }
                      inputProps={{
                        readOnly: true,
                        min: 0, // Ensure the value can't go below 0
                      }}
                      onInput={(e) => {
                        // Prevent negative numbers
                        if (parseInt(e.target.value) < 0) {
                          e.preventDefault();
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Controller
                name={`sub_items.${index}.amount`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Amount"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    // error={!!errors.sub_items?.[index]?.amount}
                    // helperText={errors.sub_items?.[index]?.amount?.message}
                    inputProps={{
                      readOnly: true,
                      min: 0, // Ensure the value can't go below 0
                    }}
                    onInput={(e) => {
                      // Prevent negative numbers
                      if (parseInt(e.target.value) < 0) {
                        e.preventDefault();
                      }
                    }}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={1}
              className="flex items-center gap-3"
            >
              <IconButton onClick={() => removeSubItem(index)}>
                <MdDeleteForever size={30} style={{ color: "#d0412e" }} />
              </IconButton>
              {index === subItemFields.length - 1 && (
                <IconButton
                  onClick={() =>
                    addSubItem({
                      id: "",
                      hsn_code: "",
                      quantity: "",
                      sell_price: "",
                      gst_amount: "",
                      gst_percent: "",
                      discount: "",
                      amount: "",
                    })
                  }
                  color="primary"
                >
                  <BsPlusCircleDotted size={30} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}

        {/* Shipping Details  */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Shipping Details
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.shipping.id"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Customer Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FormatListNumbered />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>

        {/* Company */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.shipping.company"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Company"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.shipping?.company}
                // helperText={errors.customer?.shipping?.company?.message}
              />
            )}
          />
        </Grid>

        {/* GST Number */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.shipping.gst"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="GST Number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Description />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.shipping?.gst}
                // helperText={errors.customer.shipping.gst?.message}
              />
            )}
          />
        </Grid>

        {/* Address Line 1 */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.shipping.address1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Address Line 1"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.shipping?.address1}
                // helperText={errors.customer?.shipping?.address1?.message}
              />
            )}
          />
        </Grid>

        {/* Address Line 2 */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.shipping.address2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Address Line 2"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.shipping?.address2}
                // helperText={errors.customer?.shipping.address2?.message}
              />
            )}
          />
        </Grid>

        {/* City */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.shipping.city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="City"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.shipping?.city}
                // helperText={errors.customer?.shipping?.city?.message}
              />
            )}
          />
        </Grid>

        {/* Postal Code */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.shipping.postcode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Postal Code"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GpsFixed />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.shipping?.postcode}
                // helperText={errors.customer?.shipping?.postcode?.message}
              />
            )}
          />
        </Grid>

        {/* Country */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.shipping.country"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Country"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Public />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.shipping?.country}
                // helperText={errors.customer?.shipping?.country?.message}
              />
            )}
          />
        </Grid>

        {/* State */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.shipping.state"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="State"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.shipping?.state}
                // helperText={errors.customer?.shipping?.state?.message}
              />
            )}
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.shipping.email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.shipping?.email}
                // helperText={errors.customer?.shipping?.email?.message}
              />
            )}
          />
        </Grid>

        {/* Phone */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="customer.shipping.phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Phone"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone />
                    </InputAdornment>
                  ),
                }}
                // error={!!errors.customer?.shipping?.phone}
                // helperText={errors.customer?.shipping?.phone?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Pricing Information
          </Typography>
        </Grid>

        {/* Service labour Charge */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="service_labour_charge"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Total Service Charges"
                  type="number"
                  placeholder="Enter Service Charges"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon /> {/* Discount icon */}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0, // Ensure the value can't go below 0
                  }}
                  onInput={(e) => {
                    // Prevent negative numbers
                    if (parseInt(e.target.value) < 0) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* discount  */}
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="discount"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Discount Amount"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRupee />
                    </InputAdornment>
                  ),
                }}
                error={!!errors?.discount}
                helperText={errors?.discount?.message}
                inputProps={{
                  min: 0, // Prevent values below 0
                }}
              />
            )}
          />
        </Grid>

        {/* igst  */}
        <Grid item xs={12} sm={6} md={3}>
          {/* IGST Field */}
          <FormControl fullWidth variant="outlined">
            <Controller
              name="igst"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="IGST"
                  type="number"
                  placeholder="Enter IGST Amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon /> {/* Percent icon */}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0, // Ensure the value can't go below 0
                  }}
                  onInput={(e) => {
                    if (parseInt(e.target.value) < 0) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* cgst  */}
        <Grid item xs={12} sm={6} md={3}>
          {/* CGST Field */}
          <FormControl fullWidth variant="outlined">
            <Controller
              name="cgst"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="CGST"
                  type="number"
                  placeholder="Enter CGST Amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon /> {/* Percent icon */}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0, // Ensure the value can't go below 0
                  }}
                  onInput={(e) => {
                    if (parseInt(e.target.value) < 0) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* sgst  */}
        <Grid item xs={12} sm={6} md={3}>
          {/* SGST Field */}
          <FormControl fullWidth variant="outlined">
            <Controller
              name="sgst"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="SGST"
                  type="number"
                  placeholder="Enter SGST Amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon /> {/* Percent icon */}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0, // Ensure the value can't go below 0
                  }}
                  onInput={(e) => {
                    if (parseInt(e.target.value) < 0) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Shipping Amount Field */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="shipping_amount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Shipping Amount"
                  type="number"
                  placeholder="Enter shipping cost"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalShippingIcon /> {/* Shipping icon */}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0, // Ensure the value can't go below 0
                  }}
                  onInput={(e) => {
                    // Prevent negative numbers
                    if (parseInt(e.target.value) < 0) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Rounded Off Amount Field */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="rounded_off_amount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Rounded Off Amount"
                  type="number"
                  placeholder="Enter rounded off amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AdjustIcon /> {/* Rounded off icon */}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0, // Ensure the value can't go below 0
                  }}
                  onInput={(e) => {
                    // Prevent negative numbers
                    if (parseInt(e.target.value) < 0) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Total Amount Field */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="total_amount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Total Amount"
                  type="number"
                  placeholder="Enter total amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon /> {/* Total amount icon */}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    min: 0, // Ensure the value can't go below 0
                  }}
                  onInput={(e) => {
                    // Prevent negative numbers
                    if (parseInt(e.target.value) < 0) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Order Note Field */}
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="order_note"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Order Note"
                  placeholder="Enter any additional notes"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NoteIcon /> {/* Order note icon */}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/* Estimated Delivery Date Field */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth variant="outlined">
            <Controller
              name="estimated_delivery_date"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Estimated Delivery Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarTodayIcon /> {/* Delivery date icon */}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "24px" }}>
        <FormButton
          type="submit"
          title={serviceId ? "Update Service" : "Create Service"}
        />
      </Grid>
    </form>
  );
};

export default CreateService;

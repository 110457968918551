import React, { useState, useEffect } from "react";
import { getReq } from "../../apis/api";
import Loader from "../../components/loader/Loading";
import RolePermissionsTable from "../access/RolePermissionsTable";

const UserPermission = ({ id }) => {
  const [rolePermissionsData, setRolePermissionsData] = useState(null); // Stores permissions for the user
  const [error, setError] = useState(""); // Error state
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch permissions when the component mounts
  useEffect(() => {
    const fetchPermissions = async () => {
      setLoading(true); // Show loader while fetching data
      try {
        const response = await getReq(`/getPermissionsForUser/${id}`); // Fetch permissions based on user ID
        console.log(response, "API response");

        if (response.status === 200) {
          console.log("Setting user permissions...");
          setRolePermissionsData(response); // Assuming the actual data is in `response.data`
          setError(""); // Clear error if successful
        } else {
          setError("No permissions available for this user.");
          setRolePermissionsData(null); // Clear previous permissions data
        }
      } catch (error) {
        setError("Failed to fetch user permissions. Please try again later.");
        setRolePermissionsData(null); // Clear previous permissions data
      } finally {
        setLoading(false); // Hide loader after data is fetched
      }
    };

    fetchPermissions();
  }, [id]);

  return (
    <div className="p-4">
      <h1 className="text-lg font-semibold mb-4">User Permissions</h1>

      {/* Show loader while fetching data */}
      {loading ? (
        <Loader />
      ) : (
        <>
          {rolePermissionsData ? (
            <RolePermissionsTable
              permissions={rolePermissionsData}
              User={true}
            />
          ) : (
            <div>{error || "No permissions found for this user."}</div>
          )}

          {error && <div className="text-red-500">{error}</div>}
        </>
      )}
    </div>
  );
};

export default UserPermission;

import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import FormButton from "../../components/Button";
import Loader from "../../components/loader/Loading";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getReq } from "../../apis/api";
import { toast } from "react-toastify";
import Productperformancetable from "../../components/DataTable/Productperformancetable";
import { CSVLink } from "react-csv"; // Import CSVLink for exporting data
import { useSelector } from "react-redux";

const Paymentdue = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const { permissions } = useOutletContext();
  const { roleName } = useSelector((state) => state.role);
  const paymentDuePerformance =
    permissions?.[roleName]?.modules?.["Reporting"]?.pages?.["Reporting"]
      ?.sections?.["Payment Due"];

  console.log("reportingpermission", paymentDuePerformance);

  const fetchProductReport = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/paymentDueReport");

      if (response.status === 200) {
        setOrders(response.data);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in Fetching Payment Due report");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProductReport();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "ID",
      selector: (row) => row.user_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "User Name",
      selector: (row) => row.user_name || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Father Name",
      selector: (row) => row.father_name,
      sortable: true,
    },
    {
      name: "Village",
      selector: (row) => row.village || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Phone",
      selector: (row) => row.phone || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Type",
      selector: (row) => row.type || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Payment Due",
      selector: (row) => row.payment_due || "N/A",
      sortable: true,
      wrap: true,
    },
  ];

  // CSV Headers
  const csvHeaders = [
    { label: "#", key: "index" },
    { label: "ID", key: "user_id" },
    { label: "User Name", key: "user_name" },
    { label: "Father Name", key: "father_name" },
    { label: "Village", key: "village" },
    { label: "Phone", key: "phone" },
    { label: "Type", key: "type" },
    { label: "Payment Due", key: "payment_due" },
  ];

  // Prepare data for CSV export
  const csvData = orders.map((order, index) => ({
    index: index + 1,
    user_id: order.user_id || "N/A",
    user_name: order.user_name || "N/A",
    father_name: order.father_name || "N/A",
    village: order.village || "N/A",
    phone: order.phone || "N/A",
    type: order.type || "N/A",
    payment_due: order.payment_due || "N/A",
  }));

  return (
    <div className="w-full h-full">
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Dashboard / Reporting / Payment-Due "} />
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename="product_performance_report.csv"
          className="text-white bg-blue-500 px-4 py-2 rounded hover:bg-blue-600"
        >
          Export
        </CSVLink>
      </div>
      <main>
        {isLoading ? (
          <Loader />
        ) : paymentDuePerformance?.read ? (
          <Productperformancetable
            columns={columns}
            data={orders}
            // onItemAdded={fetchProductReport}
          />
        ) : (
          <p className="text-gray-500 md:py-20px-vw md:text-[1.2vw] text-center font-bold">
            You do not have permission to view this data.
          </p>
        )}
      </main>
    </div>
  );
};

export default Paymentdue;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { postReq } from "../../apis/api";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

const RolePermissionsTable = ({ permissions, User }) => {
  const [rolePermissions, setRolePermissions] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [changedRows, setChangedRows] = useState([]);
  const { roleName } = useSelector((state) => state.role);
  const { permissions: userPermissions } = useOutletContext();
  console.log(userPermissions, "copyy");
  const createUsersPermissions =
    userPermissions?.[roleName]?.modules?.["Access Management"]?.pages?.[
      "User Roles"
    ]?.sections?.[roleName];
  console.log(createUsersPermissions, "cvbnm");
  // useEffect(() => {
  //   if (!permissions || Object.keys(permissions).length === 0) {
  //     setRolePermissions({});
  //   } else {
  //     const role = Object.keys(permissions)[0];
  //     setRolePermissions(permissions[role]);
  //   }
  // }, [permissions]); 

  useEffect(() => {
    if (!permissions || Object.keys(permissions).length === 0) {
      setRolePermissions({});
    } else {
      const role = Object.keys(permissions)[0]; // Only setting the first key
      setRolePermissions(permissions[role]); 
    }
  }, [permissions]);
  

  const handleCheckboxChange = (
    moduleKey,
    pageKey,
    sectionKey,
    permissionType
  ) => {
    setRolePermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions };
      const section =
        updatedPermissions.modules[moduleKey]?.pages[pageKey]?.sections[
          sectionKey
        ] || updatedPermissions.modules[moduleKey]?.sections[sectionKey];
      if (section) {
        section[permissionType] = !section[permissionType];
        setChangedRows((prevRows) => {
          const updatedRows = [...prevRows];
          const existingRowIndex = updatedRows.findIndex(
            (row) =>
              row.moduleKey === moduleKey &&
              row.pageKey === pageKey &&
              row.sectionKey === sectionKey
          );
          if (existingRowIndex > -1) {
            updatedRows[existingRowIndex] = {
              moduleKey,
              pageKey,
              sectionKey,
              section,
            };
          } else {
            updatedRows.push({ moduleKey, pageKey, sectionKey, section });
          }
          return updatedRows;
        });
      }
      return updatedPermissions;
    });
  };

  // Function to send API requests for each changed row

  const handleBatchUpdate = async () => {
    setLoading(true);
    setErrors({});
    setErrMsg("");
    if (User) {
      try {
        const promises = changedRows.map(
          ({ moduleKey, pageKey, sectionKey, section }) => {
            const payload = {
              permissions_id: section.permissions_id,
              can_create:
                section.create !== undefined ? (section.create ? 1 : 0) : "",
              can_read:
                section.read !== undefined ? (section.read ? 1 : 0) : "",
              can_update:
                section.update !== undefined ? (section.update ? 1 : 0) : "",
              can_delete:
                section.delete !== undefined ? (section.delete ? 1 : 0) : "",
            };
            return postReq(payload, "/assignPermissionToUser");
          }
        );

        // Execute all requests concurrently
        const responses = await Promise.all(promises);

        // Check if all were successful
        if (responses.every((res) => res.status === 200)) {
          toast.success("All changes updated successfully.");
        } else {
          toast.error("Some permissions were not updated successfully.");
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          const errorMsg = error.response.data.msg;
          Object.values(errorMsg).forEach((msg) => {
            toast.error(msg, { position: "top-right", autoClose: 5000 });
          });
          setErrors(errorMsg);
        } else {
          setErrMsg("Something went wrong. Please try again.");
          toast.error("Something went wrong. Please try again.", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } finally {
        setLoading(false); // Hide the loader once requests are complete
        setChangedRows([]); // Clear changed rows after successful updates
      }
    } else {
      try {
        const promises = changedRows.map(
          ({ moduleKey, pageKey, sectionKey, section }) => {
            const payload = {
              permissions_id: section.permissions_id,
              can_create:
                section.create !== undefined ? (section.create ? 1 : 0) : "",
              can_read:
                section.read !== undefined ? (section.read ? 1 : 0) : "",
              can_update:
                section.update !== undefined ? (section.update ? 1 : 0) : "",
              can_delete:
                section.delete !== undefined ? (section.delete ? 1 : 0) : "",
            };
            return postReq(payload, "/assignPermissionToRole");
          }
        );

        // Execute all requests concurrently
        const responses = await Promise.all(promises);

        // Check if all were successful
        if (responses.every((res) => res.status === 200)) {
          toast.success("All changes updated successfully.");
        } else {
          toast.error("Some permissions were not updated successfully.");
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          const errorMsg = error.response.data.msg;
          Object.values(errorMsg).forEach((msg) => {
            toast.error(msg, { position: "top-right", autoClose: 5000 });
          });
          setErrors(errorMsg);
        } else {
          setErrMsg("Something went wrong. Please try again.");
          toast.error("Something went wrong. Please try again.", {
            position: "top-right",
            autoClose: 5000,
          });
        }
      } finally {
        setLoading(false); // Hide the loader once requests are complete
        setChangedRows([]); // Clear changed rows after successful updates
      }
    }
  };

  if (Object.keys(rolePermissions).length === 0) {
    return <div>No permissions available.</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border border-gray-300 rounded-lg mx-auto divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-1 py-3 pl-[3vw] text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Module
            </th>
            <th className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Page
            </th>
            <th className="px-1 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Section
            </th>
            <th className="px-1 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Create
            </th>
            <th className="px-1 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Read
            </th>
            <th className="px-1 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Update
            </th>
            <th className="px-1 py-2 pr-[1.5vw] text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Delete
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Object.keys(rolePermissions.modules).map((moduleKey) => {
            const module = rolePermissions.modules[moduleKey];
            let moduleDisplayed = false;

            if (module.pages) {
              return Object.keys(module.pages).map((pageKey) => {
                const page = module.pages[pageKey];

                return Object.keys(page.sections || {}).map((sectionKey) => {
                  const section = page.sections[sectionKey];
                  const isFirstSection = !moduleDisplayed;

                  return (
                    <tr
                      key={sectionKey}
                      className="hover:bg-gray-50 transition-colors duration-150"
                    >
                      <td className="px-1 py-2 pl-[3vw] whitespace-nowrap text-sm text-gray-900">
                        {isFirstSection && (
                          <>
                            <strong>{moduleKey}</strong>
                            {(moduleDisplayed = true)}
                          </>
                        )}
                      </td>
                      <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900">
                        {isFirstSection ? pageKey : ""}
                      </td>
                      <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900">
                        {sectionKey}
                      </td>
                      <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900 text-center">
                        {section.create !== undefined ? (
                          <input
                            type="checkbox"
                            checked={section.create}
                            onChange={() =>
                              handleCheckboxChange(
                                moduleKey,
                                pageKey,
                                sectionKey,
                                "create"
                              )
                            }
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                        ) : (
                          <div className="w-4 h-4"></div>
                        )}
                      </td>
                      <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900 text-center">
                        {section.read !== undefined ? (
                          <input
                            type="checkbox"
                            checked={section.read}
                            onChange={() =>
                              handleCheckboxChange(
                                moduleKey,
                                pageKey,
                                sectionKey,
                                "read"
                              )
                            }
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                        ) : (
                          <div className="w-4 h-4"></div>
                        )}
                      </td>
                      <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900 text-center">
                        {section.update !== undefined ? (
                          <input
                            type="checkbox"
                            checked={section.update}
                            onChange={() =>
                              handleCheckboxChange(
                                moduleKey,
                                pageKey,
                                sectionKey,
                                "update"
                              )
                            }
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                        ) : (
                          <div className="w-4 h-4"></div>
                        )}
                      </td>
                      <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900 text-center">
                        {section.delete !== undefined ? (
                          <input
                            type="checkbox"
                            checked={section.delete}
                            onChange={() =>
                              handleCheckboxChange(
                                moduleKey,
                                pageKey,
                                sectionKey,
                                "delete"
                              )
                            }
                            className="h-4 w-4  mr-[3vw]  text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                        ) : (
                          <div className="w-4 h-4"></div>
                        )}
                      </td>
                    </tr>
                  );
                });
              });
            }

            // If there are no pages, we directly map the sections in the module
            return Object.keys(module.sections || {}).map((sectionKey) => {
              const section = module.sections[sectionKey];
              const isFirstSection = !moduleDisplayed;

              return (
                <tr
                  key={sectionKey}
                  className="hover:bg-gray-50 transition-colors duration-150"
                >
                  <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900">
                    {isFirstSection && (
                      <>
                        <strong>{moduleKey}</strong>
                        {(moduleDisplayed = true)}
                      </>
                    )}
                  </td>
                  <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900">
                    {/* Empty because there are no pages */}
                  </td>
                  <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900">
                    {sectionKey}
                  </td>
                  <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900 text-center">
                    {section.create !== undefined ? (
                      <input
                        type="checkbox"
                        checked={section.create}
                        onChange={() =>
                          handleCheckboxChange(
                            moduleKey,
                            null,
                            sectionKey,
                            "create"
                          )
                        }
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                    ) : (
                      <div className="w-4 h-4"></div>
                    )}
                  </td>
                  <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900 text-center">
                    {section.read !== undefined ? (
                      <input
                        type="checkbox"
                        checked={section.read}
                        onChange={() =>
                          handleCheckboxChange(
                            moduleKey,
                            null,
                            sectionKey,
                            "read"
                          )
                        }
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                    ) : (
                      <div className="w-4 h-4"></div>
                    )}
                  </td>
                  <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900 text-center">
                    {section.update !== undefined ? (
                      <input
                        type="checkbox"
                        checked={section.update}
                        onChange={() =>
                          handleCheckboxChange(
                            moduleKey,
                            null,
                            sectionKey,
                            "update"
                          )
                        }
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                    ) : (
                      <div className="w-4 h-4"></div>
                    )}
                  </td>
                  <td className="px-1 py-2 whitespace-nowrap text-sm text-gray-900 text-center">
                    {section.delete !== undefined ? (
                      <input
                        type="checkbox"
                        checked={section.delete}
                        onChange={() =>
                          handleCheckboxChange(
                            moduleKey,
                            null,
                            sectionKey,
                            "delete"
                          )
                        }
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                    ) : (
                      <div className="w-4 h-4"></div>
                    )}
                  </td>
                </tr>
              );
            });
          })}
        </tbody>
      </table>

      {/* Conditionally show Save Changes button if there are any changes */}
      {changedRows.length > 0 && createUsersPermissions.update && (
        <div className="flex justify-end my-4">
          <Button
            title="Save Changes"
            func={handleBatchUpdate}
            disabled={loading}
          />
        </div>
      )}
    </div>
  );
};

export default RolePermissionsTable;

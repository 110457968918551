import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import BreadCrum from "../../components/BreadCrum/BreadCrum";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import { useSelector } from "react-redux";

export const Access = () => {
  const { permissions } = useOutletContext();
  console.log(permissions, "permissions");

  const { roleName } = useSelector((state) => state.role);
  const createUsersPermissions =
    permissions?.[roleName]?.modules?.["User Management"]?.pages?.Users
      ?.sections?.["Create Users"];
  console.log(createUsersPermissions, "User Create Permissions");
  return (
    <div>
      <div>
        <div className="py-20px-vw">
          <BreadCrumbs title={"Home / Access Management"} />
        </div>
        <div className="mt-[1vw]">
          <Outlet context={{ permissions }} />
        </div>
      </div>
    </div>
  );
};

export default Access;

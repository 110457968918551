import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import FormButton from "../../components/Button";
import Loader from "../../components/loader/Loading";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getReq, postReq } from "../../apis/api";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Productperformancetable from "../../components/DataTable/Productperformancetable";
import { CSVLink } from "react-csv"; // Import CSVLink for exporting data

import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  CalendarToday,
  Category,
  FormatListNumbered,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import Estimatedreporttable from "../../components/DataTable/Estimatedreporttable";
import { useSelector } from "react-redux";

const Estimatedreport = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemstock, setItemStock] = useState("");
  const [reportdata, setReportData] = useState([]);
  const [reportsubitem, setReportSubitem] = useState([]);
  const [requirement, setRequirement] = useState(null);
  const { permissions } = useOutletContext();
  const { roleName } = useSelector((state) => state.role);
  const reportingpermission =
    permissions?.[roleName]?.modules?.["Reporting"]?.pages?.["Reporting"]
      ?.sections?.["Estimated Product Report"];

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      product_id: "",
      start_date: "",
      end_date: "",
    },
  });

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const res = await getReq("/products");
      setProducts(res.data || []);
    } catch (e) {
      console.error("Error fetching Items:", e);
      toast.error("Error in fetching Items");
    }
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    setIsLoading(true);
    try {
      const response = await postReq(data, "/estimatedReport");

      if (response.status === 200) {
        setReportData(response.data.item);
        setItemStock(response.data);
        setReportSubitem(response.data.subitem);

        toast.success("Report Fetched Successfully");
      } else {
        // Show error message directly from the response
        toast.error(
          response.message || "Something went wrong. Please try again.",
          {
            position: "top-right",
            autoClose: 5000,
          }
        );
        setReportData([]);
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error:", error);
      // Handle errors from the API call

      toast.error("something went Wrong while fetching ");
    } finally {
      setIsLoading(false);
    }
  };

  console.log("reportsData", reportdata);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Item Name",
      selector: (row) => row.name || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Weight Per Unit",
      selector: (row) => row.weightPerUnit || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Sgew Price",
      selector: (row) => row.sgew_price || "N/A",
      sortable: true,
      wrap: true,
    },
  ];

  // CSV Headers
  const csvHeaders = [
    { label: "#", key: "index" },
    { label: "ID", key: "name" },
    { label: "Name", key: "quantity" },
    { label: "Weight Per Unit", key: "weightPerUnit" },
    { label: "Sgew Price", key: "sgew_price" },
  ];

  const alldata = [...reportdata, ...reportsubitem];
  // Prepare data for CSV export
  const csvData = alldata.map((order, index) => ({
    index: index + 1,
    name: order.name,
    quantity: order.quantity || "N/A",
    weightPerUnit: order.weightPerUnit,
    sgew_price: order.sgew_price || "N/A",
    // number_of_orders: order.number_of_orders || "N/A",
  }));

  return (
    <div className="w-full h-full">
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs
          title={"Home  /  Dashboard / Reporting / Estimated-Report"}
        />
        {itemstock && (
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename="Estimated_Report.csv"
            className="text-white bg-blue-500 px-4 py-2 rounded hover:bg-blue-600"
          >
            Export
          </CSVLink>
        )}
      </div>
      <form
        className="bg-white"
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          margin: "0 auto",
          padding: "20px",
          background: "#fffff",
          borderRadius: "8px",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={2} className="mb-30px-vw">
            {/* Product Dropdown */}
            <Grid item xs={12} sm={6} md={2.4}>
              <FormControl fullWidth>
                <InputLabel id="product-label">Product</InputLabel>
                <Controller
                  name="product_id"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="product-label"
                      label="Product"
                      startAdornment={
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      }
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: 250,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select a Product
                      </MenuItem>
                      {products.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.product_name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>

            {/* Start Date Field */}
            <Grid item xs={12} sm={6} md={2.4}>
              <Controller
                name="start_date"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="Start Date"
                    inputFormat="yyyy-MM-dd"
                    onChange={(date) =>
                      field.onChange(format(date, "yyyy-MM-dd"))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarToday />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            {/* End Date Field */}
            <Grid item xs={12} sm={6} md={2.4}>
              <Controller
                name="end_date"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="End Date"
                    inputFormat="yyyy-MM-dd"
                    onChange={(date) =>
                      field.onChange(format(date, "yyyy-MM-dd"))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarToday />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={2} md={2.4}>
              <Controller
                name="requirement"
                placeholder="enter forecasting quantity"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Product requirement"
                    type="number"
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setRequirement(event.target.value);
                    }}
                    disabled={!itemstock}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatListNumbered />
                        </InputAdornment>
                      ),
                    }}
                    // error={!!errors.item_details?.[index]?.quantity}
                    // helperText={errors.item_details?.[index]?.quantity?.message}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={2.4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormButton title="Get Report" type="submit" />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </form>
      <main>
        {isLoading ? (
          <Loader />
        ) : reportdata && reportingpermission?.read ? (
          <>
            <Estimatedreporttable
              columns={columns}
              data={reportdata}
              itemstock={itemstock}
              itemtable={true}
              requirement={requirement}
              // onItemAdded={fetchProductReport}
            />
            <Estimatedreporttable
              columns={columns}
              data={reportsubitem}
              itemstock={itemstock}
              itemtable={false}
              requirement={requirement}
              // onItemAdded={fetchProductReport}
            />
          </>
        ) : (
          <p className="text-gray-500 md:py-20px-vw md:text-[1.2vw] text-center font-bold">
            You do not have permission to view this data.
          </p>
        )}
      </main>
    </div>
  );
};

export default Estimatedreport;

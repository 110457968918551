import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { getReq } from "../../apis/api";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loading";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import { PDFDownloadLink } from "@react-pdf/renderer";
import EmployeeReportPDF from "./EmployeeReportPDF"; // Component for PDF generation

const EmployeesReport = () => {
  const [employees, setEmployees] = useState([]); // All employees
  const [selectedEmployee, setSelectedEmployee] = useState(""); // Selected employee ID
  const [attendanceData, setAttendanceData] = useState([]); // Attendance data for selected employee
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // Fetch all employees
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await getReq("/fetchEmpAttendance");
        if (response.status === 200) {
          setEmployees(response.data);
        } else {
          toast.error("Failed to fetch employees.");
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
        toast.error("An error occurred while fetching employees.");
      }
    };

    fetchEmployees();
  }, []);

  // Fetch attendance data for the selected employee
  useEffect(() => {
    const fetchAttendanceData = async () => {
      if (selectedEmployee) {
        setIsLoading(true);
        try {
          const response = await getReq(
            `/fetchSingleAttendance/${selectedEmployee}`
          );
          if (response.status === 200) {
            const sortedDataDescending = response.data.sort(
              (a, b) => b.id - a.id
            );
            setAttendanceData(sortedDataDescending);
          } else {
            toast.error("Failed to fetch attendance data.");
          }
        } catch (error) {
          console.error("Error fetching attendance data:", error);
          toast.error("An error occurred while fetching attendance data.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchAttendanceData();
  }, [selectedEmployee]);

  // Table columns
  const columns = [
    {
      name: "Employee ID",
      selector: (row) => row.employee_id,
      sortable: true,
      center: true,
    },
    {
      name: "Employee Name",
      selector: (row) => row.employee_name,
      sortable: true,
      center: true,
    },
    {
      name: "Employee Type",
      selector: (row) => row.employee_type,
      sortable: true,
      center: true,
    },
    {
      name: "Start Date",
      selector: (row) => row.date_from,
      sortable: true,
      center: true,
    },
    {
      name: "End Date",
      selector: (row) => row.date_to,
      sortable: true,
      center: true,
    },
    {
      name: "Total Working Days",
      selector: (row) => row.total_working_days,
      sortable: true,
      center: true,
    },
    {
      name: "Total Overtime Hours",
      selector: (row) => row.total_overtime_hours,
      sortable: true,
      center: true,
    },
    {
      name: "Day Salary",
      selector: (row) => row.day_salary,
      sortable: true,
      center: true,
    },
    {
      name: "Overtime Salary",
      selector: (row) => row.overtime_salary,
      sortable: true,
      center: true,
    },
    {
      name: "Total Salary",
      selector: (row) => row.total_salary,
      sortable: true,
      center: true,
    },
    {
      name: "Total Current Balance",
      selector: (row) => row.total_current_balance,
      sortable: true,
      center: true,
    },
  ];

  return (
    <div>
      {/* Breadcrumb */}
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Dashboard / Employee Report"} />
      </div>

      {/* Employee Selection Dropdown */}
      <div className="mb-4">
        <label
          htmlFor="employee"
          className="block text-sm font-medium text-gray-700"
        >
          Select Employee
        </label>
        <select
          id="employee"
          value={selectedEmployee}
          onChange={(e) => setSelectedEmployee(e.target.value)}
          className="mt-1 block w-[15vw] p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">Select an employee</option>
          {employees.map((employee) => (
            <option key={employee.id} value={employee.id}>
              {employee.employee_name} (ID: {employee.employee_id})
            </option>
          ))}
        </select>
      </div>

      {/* Data Table */}
      <main>
        {isLoading ? (
          <Loader />
        ) : Array.isArray(attendanceData) && attendanceData.length > 0 ? (
          <div className="overflow-hidden !bg-white rounded-[0.42vw]">
            <DataTable
              columns={columns}
              data={attendanceData}
              pagination
              highlightOnHover
              customStyles={{
                rows: {
                  style: {
                    minHeight: "60px",
                    fontSize: "0.9vw",
                  },
                },
                headCells: {
                  style: {
                    fontWeight: 600,
                    textAlign: "center",
                    whiteSpace: "normal",
                    fontSize: "1vw",
                    color: "#10131e",
                  },
                },
              }}
            />
            {/* Export as PDF Button */}
            <div className="mt-4 flex justify-end">
              <PDFDownloadLink
                document={<EmployeeReportPDF data={attendanceData} />}
                fileName="employee_report.pdf"
              >
                {({ loading }) =>
                  loading ? "Loading document..." : "Export as PDF"
                }
              </PDFDownloadLink>
            </div>
          </div>
        ) : (
          <p className="text-center text-[1.2vw] my-[1vw] text-gray-500 font-bold">
            No data found for the selected employee.
          </p>
        )}
      </main>
    </div>
  );
};

export default EmployeesReport;

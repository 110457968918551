import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../components/common/BreadCrumbs";
import FormButton from "../../components/Button";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getReq } from "../../apis/api";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loading";
import ServiceTable from "../../components/DataTable/ServiceTable";
import { useSelector } from "react-redux";

const ServiceManagement = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const { permissions } = useOutletContext();
  const { roleName } = useSelector((state) => state.role);
  const servicePermission =
    permissions?.[roleName]?.modules?.["Sales and Service Management"]?.pages?.[
      "Warranty Management"
    ]?.sections?.["Product Service"];

  console.log("servicePermission", servicePermission);

  const fetchOrderData = async () => {
    try {
      setIsLoading(true);
      const response = await getReq("/service");

      if (response.status === 200) { 
        const sortedDataDescending = response.data.sort((a, b) => b.id - a.id);
        setOrders(sortedDataDescending);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Error in Fetching Product Categories");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
      center: true,
    },
    {
      name: "ID",
      selector: (row) => row.claim_id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Service Invoice No",
      selector: (row) => row.service_invoice_number,
      sortable: true,
      width: "100px",
    },
    {
      name: "Date",
      selector: (row) => row.date || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Company",
      selector: (row) => {
        const customer = row.customer ? JSON.parse(row.customer) : null;
        return customer?.billing?.company || "N/A";
      },
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => {
        const customer = row.customer ? JSON.parse(row.customer) : null;
        return customer?.billing?.address1 || "N/A";
      },
      sortable: true,
      wrap: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.total_amount || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Order Note",
      selector: (row) => row.order_note || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Order Summary",
      selector: (row) => {
        try {
          // Parse the JSON string into an array
          const orderSummary = JSON.parse(row.products);

          // Check if the parsed result is an array
          if (Array.isArray(orderSummary)) {
            // Extract item names and join them with commas
            return orderSummary
              .map((product) => product.product_name || "N/A")
              .join(", ");
          }
          return "N/A"; // Return "N/A" if it's not an array
        } catch (error) {
          // Handle JSON parsing errors
          console.error("Error parsing order_summary:", error);
          return "Invalid Data";
        }
      },
      sortable: true,
    },

    {
      name: "Actual Order Note",
      selector: (row) => row.actual_order_note || "N/A",
      sortable: true,
      wrap: true,
    },

    {
      name: "Estimated Delivery",
      selector: (row) => row.estimated_delivery_date || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Delivery",
      selector: (row) => row.delivery_date || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.status || "N/A",
      cell: (row) => (
        <div
          className={`px-4 py-1 rounded-[0.42vw]  ${
            row.status === "Success"
              ? "bg-[#d8fff8] text-[#01bd9b]"
              : row.status === "Cancelled"
              ? "bg-[#faebe9] text-[#D0412E]"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {row.status || "N/A"}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Invoice",
      selector: (row) => row.invoice || "N/A",
      sortable: true,
      wrap: true,
    },
  ];

  return (
    <div>
      <div className="flex justify-between items-center bg-white rounded-[0.42vw] w-full py-[1vw] md:py-[0.5vw] my-[2vw] md:my-[1vw] px-[4vw] md:px-[2vw] ">
        <BreadCrumbs title={"Home  /  Sales-Services / Services-Orders"} />
        <FormButton
          title="Create"
          func={() => navigate("/sales-service/create-service")}
        />
      </div>

      <main>
        {isLoading ? (
          <Loader />
        ) : servicePermission?.read ? (
          <ServiceTable 
            updatePemission={servicePermission?.update}
            columns={columns}
            data={orders}
            onItemAdded={fetchOrderData}
          />
        ) : (
          <p className="text-gray-500 md:py-20px-vw md:text-[1.2vw] text-center font-bold">
            You do not have permission to view this data.
          </p>
        )}
      </main>
    </div>
  );
};

export default ServiceManagement;

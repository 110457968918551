import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { HiDotsVertical, HiX } from "react-icons/hi";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import AddPaymentModal from "../Modal/paymentmodal/AddPaymentModal";

const AllPaymentTable = ({
  columns,
  data,
  customers,
  fetchPaymentData,
  paymentPermission,
}) => {
  const [filterText, setFilterText] = useState("");
  const [globalSearchText, setGlobalSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("User Name");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null); // Store the selected userId
  const navigate = useNavigate();

  const allowedFilterColumns = [
    { name: "User Name", selector: "user_name" },
    { name: "Email", selector: "email" },
    { name: "Phone Number", selector: "phone" },
    { name: "Status", selector: "status" },
  ];

  const actionColumn = {
    name: "Actions",
    cell: (row) => (
      <Menu as="div" className="relative">
        <Menu.Button>
          <HiDotsVertical className="w-6 h-6 text-gray-500 cursor-pointer" />
        </Menu.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items className="absolute top-0 right-0 w-48 bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-sm`}
                  onClick={() =>
                    navigate(`/payment/payment-history/${row.user_id}`)
                  }
                >
                  See Transaction History
                </button>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-blue-500 text-white" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-4 py-2 text-sm`}
                  onClick={() => {
                    setSelectedUserId(row.user_id); // Set the userId
                    setOpenModal(true); // Open the modal
                  }}
                >
                  Settle
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    right: true,
  };

  const enhancedColumns = [...columns, actionColumn];

  // Filter data based on selected column and filter text
  const filteredData = data.filter((item) => {
    const column = allowedFilterColumns.find(
      (col) => col.name === selectedColumn
    );
    if (!column) return true; // Show all data if no column is selected
    const value = item[column.selector]?.toString().toLowerCase() || "";
    return value.includes(filterText.toLowerCase());
  });

  // Global search filtering
  const globallyFilteredData = filteredData.filter((item) => {
    return Object.values(item)
      .join(" ")
      .toLowerCase()
      .includes(globalSearchText.toLowerCase());
  });

  const handleClear = () => {
    setFilterText("");
    setResetPaginationToggle(!resetPaginationToggle);
  };

  // Close modal handler
  const handleButtonClick = () => {
    setOpenModal(false);
    setSelectedUserId(null);
  };

  // Sub-header component
  const subHeaderComponent = (
    <div className="flex justify-between bg-white items-center my-4 gap-[1vw]">
      <div className="relative">
        <input
          className="border p-2 pr-10 bg-white rounded-lg focus:outline-none"
          type="text"
          placeholder="Global Search..."
          value={globalSearchText}
          onChange={(e) => setGlobalSearchText(e.target.value)}
        />
        {globalSearchText && (
          <HiX
            className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => setGlobalSearchText("")}
          />
        )}
      </div>
      <select
        className="border bg-white focus:outline-none p-2 rounded-lg"
        value={selectedColumn}
        onChange={(e) => {
          setSelectedColumn(e.target.value);
          setFilterText(""); // Clear filter text when column changes
        }}
      >
        {allowedFilterColumns.map((col) => (
          <option key={col.selector} value={col.name}>
            {col.name}
          </option>
        ))}
      </select>
      <input
        className="border bg-white p-2 rounded-lg"
        type="text"
        placeholder={`Filter by ${selectedColumn}`}
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
      {filterText && (
        <HiX
          className="absolute right-5 top-1/2 transform -translate-y-1/2 cursor-pointer"
          onClick={handleClear}
        />
      )}
    </div>
  );

  return (
    <div className="font-poppins">
      <div className="overflow-hidden bg-white rounded-lg">
        <DataTable
          columns={enhancedColumns}
          data={globallyFilteredData}
          pagination
          subHeader
          subHeaderComponent={subHeaderComponent}
          persistTableHead
          customStyles={{
            rows: {
              style: {
                minHeight: "60px",
                fontSize: "0.9rem",
              },
            },
            headCells: {
              style: {
                fontWeight: 600,
                textAlign: "center",
                fontSize: "1rem",
                color: "#10131e",
              },
            },
          }}
        />
      </div>
      {/* AddPaymentModal */}
      {openModal && (
        <AddPaymentModal
          open={openModal}
          handleClose={handleButtonClick}
          customers={customers}
          userId={selectedUserId}
          onItemAdded={fetchPaymentData}
        />
      )}
    </div>
  );
};

export default AllPaymentTable;
